import * as yup from 'yup'
import t from 'public/locales/en-US.json'

export const emailReceiptValidationSchema = yup.object().shape({
  active: yup.boolean(),
  recipients: yup
    .array()
    .of(
      yup
        .string()
        // eslint-disable-next-line no-template-curly-in-string
        .email('${value} is not a valid email address')
        .typeError('Invalid email format')
    )
    .min(1, t.common.genericFieldIsRequired),
  subject: yup.string().required(t.common.genericFieldIsRequired),
})
