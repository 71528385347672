import React from 'react'
import { ApiAppsCompatibility } from 'BackendApi'
import { Link } from 'react-router-dom'
import { FormikProps } from 'formik'
import { BridgeFormValues } from 'Nbee'
import { Button } from '@components/Basic/ButtonNbe'
import { appRoutes } from '@app/routes'
import { FiRefreshCcw } from 'react-icons/fi'
import { TFunction } from 'i18next'
import styled from 'styled-components'
import { trackEvent } from '@app/dataTracking'
import { makeNbeeTrackingParams } from '@app/dataTracking/utils'
import { WaitingListInfoMessage } from '@features/nbee/SimpleBridgeBuilderForm/appsCompatibility/WaitingListInfoMessage'
import { isInIframe } from '@app/utils/isInFrame'
import all from 'public/locales/en-US.json'
import { Translate } from '@components/Translate'

// we need to be sure new lines added in i18n json works
const Paragraph = styled.p`
  white-space: pre-wrap;
`

const isIframe = isInIframe()

export const switchApps = (formikProps: FormikProps<BridgeFormValues>) => {
  // storing IDs to switch
  const sourceAppIdToSwitch = formikProps.values.source.appId
  const destinationAppIdToSwitch = formikProps.values.destination.appId

  // unsetting fields, so sub-items will be cleared as well
  formikProps.setFieldValue('source.appId', undefined)
  formikProps.setFieldValue('destination.appId', undefined)

  // set new apps in reverse order
  setTimeout(() => {
    formikProps.setFieldValue('source.appId', destinationAppIdToSwitch)
    formikProps.setFieldValue('destination.appId', sourceAppIdToSwitch)
  }, 100)
}

export const makeSupportUrl = (crmName: string) =>
  `${
    process.env.REACT_APP_V1_URL
  }/support?t=BETA_REQUEST&crmName=${encodeURIComponent(crmName)}`

export const getAppCompatibilityPopupContent = (
  apiResponse: ApiAppsCompatibility,
  formikProps: FormikProps<BridgeFormValues>,
  t: TFunction,
  options: {
    isInStep1: boolean
  }
) => {
  const { source, destination, combination } = apiResponse
  const isInStep1 = !!options?.isInStep1

  const bothInWaitingListing =
    (source.sourceWaitingList || source.destinationWaitingList) &&
    (destination.destinationWaitingList || destination.sourceWaitingList)
  const appsInWaitingList = bothInWaitingListing
    ? `${source.name} and ${destination.name}`
    : source.sourceWaitingList || source.destinationWaitingList
    ? source.name
    : destination.destinationWaitingList || destination.sourceWaitingList
    ? destination.name
    : ''

  console.log(
    'bothInWaitingListing',
    bothInWaitingListing,
    'appsInWaitingList',
    appsInWaitingList
  )
  const waitingListMessage = (
    <div>
      <h5>{t('nbee.checkCompatibility.waitingListTitle')}</h5>
      <WaitingListInfoMessage
        appsInWaitingList={appsInWaitingList}
        appsCount={bothInWaitingListing ? 2 : 1}
        appId={source.sourceWaitingList ? source.id : destination.id}
        isInStep1={isInStep1}
      />
    </div>
  )

  // In case one or no compatibility
  if (combination.compatibility === 'none') {
    trackEvent({
      eventName: 'NotCompatibleApps',
      feature: 'NBEE',
      step: isInStep1 ? 'Apps' : 'BridgeChooser',
      params: {
        ...makeNbeeTrackingParams(formikProps.values),
      },
    })

    const customMessageKey =
      (all.nbee.checkCompatibility as Record<string, string>)[
        `noCompatibilityTextCustomMessage${source.id}-${destination.id}`
      ] ||
      ((all.nbee.checkCompatibility as Record<string, string>)[
        `noCompatibilityTextCustomMessage${destination.id}-${source.id}`
      ] as string | undefined)

    const customUrl =
      (all.nbee.checkCompatibility as Record<string, string>)[
        `noCompatibilityCustomUrl${source.id}-${destination.id}`
      ] ||
      ((all.nbee.checkCompatibility as Record<string, string>)[
        `noCompatibilityCustomUrl${destination.id}-${source.id}`
      ] as string | undefined)

    const notCompatibleMessage = (
      <div>
        <h5>
          {t('nbee.checkCompatibility.noneTitle', {
            sourceName: source.name,
            destinationName: destination.name,
          })}
        </h5>
        <div>{t('nbee.checkCompatibility.noneText')}</div>
      </div>
    )

    return appsInWaitingList !== '' ? (
      waitingListMessage
    ) : customMessageKey ? (
      <div>
        <Translate i18nKey={customMessageKey} />
        <a
          href={customUrl}
          target={'_blank'}
          rel={'noopener noreferrer'}
          style={{ display: 'block', marginTop: '1rem' }}
          onClick={() => {
            trackEvent({
              eventName: 'NotCompatibleAppsGuideClicked',
              feature: 'NBEE',
              step: isInStep1 ? 'Apps' : 'BridgeChooser',
              params: {
                ...makeNbeeTrackingParams(formikProps.values),
              },
            })
          }}
        >
          <Button type={'button'} $variant={'action'}>
            {t('nbee.checkCompatibility.noCompatibilityReadArticleCta')}
          </Button>
        </a>
      </div>
    ) : (
      notCompatibleMessage
    )
  }

  // In case one or both apps are in waiting list
  if (
    source.sourceWaitingList ||
    source.destinationWaitingList ||
    destination.sourceWaitingList ||
    destination.destinationWaitingList
  ) {
    return waitingListMessage
  }

  // In case one or both apps require a different plan
  if (source.upgrade || destination.upgrade) {
    const bothNeedUpgrade = Boolean(source.upgrade && destination.upgrade)
    const appTextToUpgrade = bothNeedUpgrade
      ? `${source.name} and ${destination.name}`
      : source.upgrade
      ? source.name
      : destination.name

    trackEvent({
      eventName: 'UpgradeButtonShown',
      step: 'Apps',
      feature: 'NBEE',
      params: {
        ...makeNbeeTrackingParams(formikProps.values),
      },
    })

    return (
      <div>
        <div>
          <h5>{t('nbee.checkCompatibility.updatePlanTitle')}</h5>
          <Paragraph>
            {t('nbee.checkCompatibility.updatePlanText', {
              appsToUpgrade: appTextToUpgrade,
              count: bothNeedUpgrade ? 2 : 1,
            })}
          </Paragraph>
          <Link
            to={
              appRoutes.pricing.makeUrl() +
              '?reason=app-only-available-in-pro-plan'
            }
            target={isIframe ? '_blank' : undefined}
          >
            <Button type={'button'} $variant={'action'}>
              {t('nbee.checkCompatibility.updatePlanCta')}
            </Button>
          </Link>
        </div>
      </div>
    )
  }

  if (combination.compatibility === 'switch' && appsInWaitingList === '') {
    return (
      <div>
        <h5>{t('nbee.checkCompatibility.switchTitle')}</h5>
        <Paragraph>{t('nbee.checkCompatibility.switchText')}</Paragraph>
        <Button
          type={'button'}
          $variant={'action'}
          $hasIcon
          onClick={() => {
            switchApps(formikProps)
          }}
        >
          <FiRefreshCcw size={'1em'} /> {t('nbee.checkCompatibility.switchCta')}
        </Button>
      </div>
    )
  }

  // data tracking for `CompatibleApps`
  // here combination is compatible somehow
  if (
    combination.compatibility === 'both' ||
    combination.compatibility === 'direct'
  ) {
    trackEvent({
      eventName: 'CompatibleApps',
      step: isInStep1 ? 'Apps' : 'BridgeChooser',
      feature: 'NBEE',
      params: {
        ...makeNbeeTrackingParams(formikProps.values),
        custom: {
          nbee: Boolean(combination.nbeeUri),
          obe: Boolean(combination.obeUri),
        },
      },
    })
  }

  // In case obeUri is returned it means it will be possible to proceed.
  // But if nbeeUri is missing we need to inform the user that the only way to proceed is with the old bridge experience
  // Update (May2023): We decided to seamelessly redirect to the old bridge experience by allowing the user to hit a button that simply says "Continue"
  if (combination.obeUri && !combination.nbeeUri) {
    return (
      <div
        style={{
          minHeight: '60px',
          padding: '1.5rem ',
          position: 'fixed',
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgb(255 255 255 / 60%)',
          backdropFilter: 'blur(5px)',
          zIndex: 50,
        }}
      >
        <div
          style={{
            minWidth: '420px',
            maxWidth: '1080px',
            padding: '0 2rem',
            width: '100%',
            margin: '0 auto',
            position: 'relative',
            display: 'flex',
            flexDirection: 'row-reverse',
          }}
        >
          <a
            href={combination.obeUri}
            target={isIframe ? '_blank' : undefined}
            rel='noreferrer'
            onClick={() => {
              trackEvent({
                eventName: 'ComplexBridgeClicked',
                step: 'BridgeChooser',
                feature: 'NBEE',
                params: {
                  ...makeNbeeTrackingParams(formikProps.values),
                  sourceAppId: source.id,
                  sourceAppName: source.name,
                  destinationAppId: destination.id,
                  destinationAppName: destination.name,
                },
              })
            }}
          >
            <Button type={'button'} $variant={'primary'} $minWidth>
              {'Continue'}
            </Button>
          </a>
        </div>
      </div>
    )
  }

  return null
}
