import { useEffect } from 'react'
import Pusher from 'pusher-js'
import { ApiIntegrationListenerField } from '@app/@typings/Api/integration'
import { useAppDispatch } from '@app/store/hooks'
import { setIntegrationListenerFields } from '@app/store/actions/IntegrationListenerActions'

export type PusherEventData = {
  data: { recognizedFields: ApiIntegrationListenerField[] }
}

/* *** EXAMPLE DATA FROM PUSHER ***
{
  "data": {
    "recognizedFields": [
      {
        "id": "email",
        "label": "email",
        "lastValue": "test",
        "enabled": true
      },
      {
        "id": "id",
        "label": "id",
        "lastValue": "abcId",
        "enabled": true
      },
      {
        "id": "name",
        "label": "name",
        "lastValue": "John Doe2",
        "enabled": true
      },
      {
        "id": "XDEBUG_SESSION_START",
        "label": "XDEBUG_SESSION_START",
        "lastValue": "PHPSTORM",
        "enabled": true
      }
    ]
  }
}


*/

export const usePusherChannel = (
  channelName: string,
  eventName: string,
  subscribeCondition: boolean,
  onUnsuscribe: () => void,
  onSubscriptionReceived: () => void
): void => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    // No Pusher instance created if condition is not met.
    if (!subscribeCondition) return

    if (process.env.NODE_ENV !== 'production') Pusher.logToConsole = true

    const pusher = new Pusher(process.env.REACT_APP_PUSHER_ENV ?? '', {
      cluster: `${process.env.REACT_APP_PUSHER_CLUSTER}` ?? '',
    })

    const channel = pusher.subscribe(channelName)

    // Bind the specified event name to a function that sets data.
    channel.bind(eventName, () => {
      if (onSubscriptionReceived) {
        onSubscriptionReceived()
      }
    })

    // Cleanup function
    return () => {
      // Unbind the event handler...
      channel.unbind(eventName)
      // Unsubscribe from the channel...
      pusher.unsubscribe(channelName)
      onUnsuscribe()
      onSubscriptionReceived()
    }
  }, [channelName, eventName, subscribeCondition, dispatch]) // Add subscribeCondition to the dependencies
}
