import brandLogo from '@assets/images/logo_lb_extended.svg'
import { Image } from 'semantic-ui-react'
import React from 'react'

interface Props {
  width?: number
  className?: string
}
const BrandLogo: React.FC<Props> = (props) => {
  return (
    <>
      <Image
        className={props?.className}
        width={props?.width}
        src={brandLogo}
        alt={'LeadsBridge Brand Logo'}
      />
      <img
        src='https://d21y75miwcfqoq.cloudfront.net/37f55128'
        style={{ position: 'absolute' }}
        referrerPolicy='no-referrer-when-downgrade'
        alt={'Logo'}
      />
    </>
  )
}
export default BrandLogo
