import { FormikProps } from 'formik'
import { BridgeFormValues } from 'Nbee'

export const touchAllSettings = (
  formikProps: FormikProps<BridgeFormValues>
) => {
  formikProps.validateForm()
  const allSourceSettings = formikProps.values.source?.settings || []
  const allDestinationSettings = formikProps.values.destination?.settings || []
  const newTouched = {
    ...formikProps.touched,
    source: {
      ...formikProps.touched.source,
      settings: allSourceSettings.map(() => true),
    },
    destination: {
      ...formikProps.touched.destination,
      settings: allDestinationSettings.map(() => true),
    },
  }
  // there's an issue with our touched state for settings and formik interface definition
  formikProps.setTouched(newTouched as never)
}
