import React from 'react'
import styled from 'styled-components'

interface ToolSquare {
  logoUri?: string
  name?: string
}
const ToolSquareStyled = styled.div`
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%),
    0 1px 5px 0 rgb(0 0 0 / 12%);
  width: 100%;
  border-radius: 5px;
  height: 110px;
  padding: 10px;
  text-align: center;
  cursor: default;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    max-width: 45px;
    width: fit-content;
    margin: 0 auto;
  }
  label {
    font-weight: 600;
    width: 100%;
    font-size: 0.9rem;
    padding: 5px 0;
    line-height: 1.2;
    min-height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
const ToolSquare: React.FC<ToolSquare> = ({ ...props }) => {
  return (
    <ToolSquareStyled>
      <img alt={`Logo ${props.name}`} width={'100%'} src={props.logoUri} />
      <label>{props.name}</label>
    </ToolSquareStyled>
  )
}
export default ToolSquare
