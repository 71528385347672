import React, { useEffect, useState } from 'react'
import {
  checkGoogleScriptOrCreate,
  initGoogleSignin,
} from '@components/ButtonProvider/Google/googleApiService'
import { AuthScope } from '@components/ButtonProvider'
import styled from 'styled-components'

interface GoogleButtonProps {
  onTokenRetrived: (token: string) => void
  scope: AuthScope
}

// dictionary
const scopeToGoogleContext: Record<
  AuthScope,
  'signup_with' | 'signin_with' | 'continue_with'
> = {
  SignIn: 'signin_with',
  SignUp: 'signup_with',
  NoScope: 'continue_with',
}

const GoogleButtonStyled = styled.div`
  height: 44px;
  text-align: center;
  justify-content: center;
  display: flex;
`
// This Google button uses the newer version of Google SDK, it is the one that should be used.
export const GoogleButtonNative: React.FC<GoogleButtonProps> = ({
  onTokenRetrived,
  scope,
}) => {
  const initGoogleButton = async () => {
    try {
      await checkGoogleScriptOrCreate()
      // we don't need to read response from `initGoogleSignin` since it will call `window.onGoogleTokenRetrived` internally
      // in case we need to handle something when `initGoogleSignin` is resolved we can do it here since it will returns the credentials object
      await initGoogleSignin('googleSigninButton', scopeToGoogleContext[scope])
    } catch (e) {
      console.log('error', e)
    }
  }

  useEffect(() => {
    // on mount we need to store our callback function in global window object
    // so google sdk wil be able to re-call it every time without the need of
    // re-init the flow in case of error from our API
    // (eg: user has selected a wrong google account and he needs to retry)
    const w = window as any
    w.onGoogleTokenRetrived = onTokenRetrived

    initGoogleButton()

    return () => {
      window.google?.accounts.id.cancel()
      // document.getElementById(googleElementScriptId)?.remove()
    }
  }, [])

  return (
    <GoogleButtonStyled
      id='googleSigninButton'
      data-size={'small'}
      data-width={280}
    />
  )
}
