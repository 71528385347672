import React from 'react'
import {
  OptionProps,
  SingleValueProps,
  ControlProps,
  ValueContainerProps,
  MenuProps,
  MenuListProps,
  components,
  IndicatorsContainerProps,
  MultiValueRemoveProps,
  InputProps,
} from 'react-select'
import {
  AddActionClickButton,
  AddNewSelectOptionButton,
  StyledControlIconWrapper,
  StyledOptionWithExtraLabel,
  StyledOptionImageWrapper,
  StyledOptionSecondaryLabel,
  StyledOptionText,
  StyledOptionValueWrapper,
  StyledOptionWrapper,
  VerifyContentWrapper,
  FloatingLabelStyled,
  DropDownIconsWrapperStyled,
} from './styled'
import { SelectValue, SmartSelectProps } from './index'
import { FiAward, FiWatch } from 'react-icons/fi'
import { FaCheck, FaChevronDown, FaChevronUp } from 'react-icons/fa'
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md'
import {
  IoIosCloseCircle,
  IoIosCheckmarkCircle,
  IoIosClose,
} from 'react-icons/io'
import { AiOutlinePlus, AiOutlineSearch } from 'react-icons/ai'
import { Tooltip } from '@components/Form/InputSmartSelect/Tooltip'
import { generateRandomId } from '@app/utils/randomIdGenerator'
import { defaultTheme } from '@app/styles/theme/default'
import { MultiValueComponent } from 'react-select/dist/declarations/src/animated/MultiValue'

const leftIcon =
  'https://leadsbridge.com/wp-content/themes/leadsbridge/img/icon/functions.png'

export const CustomInput = (props: InputProps<SelectValue>) => {
  const onPaste = (props.selectProps as any)
    .onPaste as SmartSelectProps['onPaste']

  return (
    <>
      <components.Input onPaste={onPaste} {...props}>
        {props.children}
      </components.Input>
    </>
  )
}

export const CustomMenu = (props: MenuProps<SelectValue>) => {
  return (
    <>
      <components.Menu {...props}>{props.children}</components.Menu>
    </>
  )
}

export const MenuList = (props: MenuListProps<SelectValue>) => {
  // dirty solution to make TS happy, but I couldn't find another way to pass props from the main Select component
  const addNewOptionButton = (props.selectProps as any)
    .addNewOptionButton as SmartSelectProps['addNewOptionButton']
  const setNullSelectValue = () =>
    props.setValue(null, 'deselect-option', {} as any)

  const addActionClick = (props.selectProps as any)
    .addActionClick as SmartSelectProps['addActionClick']

  return (
    <components.MenuList {...props}>
      {addNewOptionButton ? (
        <div>
          <AddNewSelectOptionButton
            onClick={() => {
              addNewOptionButton.onClick()
              setNullSelectValue()
            }}
          >
            <AiOutlinePlus />
            {addNewOptionButton.label}
          </AddNewSelectOptionButton>
        </div>
      ) : addActionClick ? (
        <>
          {props.children}
          <AddNewSelectOptionButton
            data-testid={'new-integration-button'}
            onClick={() => {
              addActionClick.onClick()
              setNullSelectValue()
            }}
          >
            <AiOutlinePlus
              strokeWidth={20}
              style={{
                color: 'hsl(0, 0%, 50%)',
                borderRadius: '50%',
                padding: '.1rem',
              }}
              size='1.3rem'
            />
            <p
              style={{
                color: 'rgba(0,0,0,.87)',
                fontFamily: defaultTheme.font.family,
              }}
            >
              {addActionClick.label}
            </p>
          </AddNewSelectOptionButton>
        </>
      ) : null}
      {addActionClick ? null : props.children}
    </components.MenuList>
  )
}

export const CustomOption: React.FC<OptionProps<SelectValue>> = (props) => {
  const hasLogo = props.data.logoUri
  const inputHasValue = !!props.selectProps.inputValue
  const hasExtraLabel =
    (props.selectProps as any).extraLabel &&
    props.data.label !== props.data.value

  const isVerified = props.data.isVerified
  const emailValidation = (props.selectProps as any).emailValidation

  const isFormulas = (props.selectProps as any).isFormulas

  return (
    <components.Option {...props}>
      <StyledOptionWrapper
        hasPadding
        hasHoverEffect
        isFocused={inputHasValue && props.isFocused}
        // isSelected={props.isSelected}
      >
        {props.isFocused && isFormulas ? (
          <StyledOptionImageWrapper>
            <FaCheck size={15} color={'#41a6dc'} />
          </StyledOptionImageWrapper>
        ) : isFormulas ? (
          <StyledOptionImageWrapper></StyledOptionImageWrapper>
        ) : null}

        {hasLogo && (
          <div>
            <StyledOptionImageWrapper>
              <img src={props.data.logoUri} key={props.data.value} />
            </StyledOptionImageWrapper>
          </div>
        )}
        <div
          style={{
            width: '100%',
          }}
        >
          <StyledOptionText
            $variant={props.data.secondaryLabel ? 'light' : 'default'}
            emailValidation={emailValidation}
          >
            {hasExtraLabel ? (
              <StyledOptionWithExtraLabel>
                <span>{props.data.label}</span>
                <span>{props.data.value}</span>
              </StyledOptionWithExtraLabel>
            ) : isFormulas ? (
              <h5 style={{ fontSize: '.9rem', color: '#384757' }}>
                {props.data.dropdownPrimaryLabel?.toUpperCase() || ''}
              </h5>
            ) : (
              <>{props.data.label}</>
            )}

            {props.data.icon === 'new' ? (
              <FiAward />
            ) : props.data.icon === 'waiting' ? (
              <FiWatch />
            ) : null}
          </StyledOptionText>
          {props.data.secondaryLabel ? (
            <StyledOptionSecondaryLabel>
              {props.data.secondaryLabel}
            </StyledOptionSecondaryLabel>
          ) : null}
        </div>
        {emailValidation && (
          <>
            {isVerified ? (
              <VerifyContentWrapper>
                <IoIosCheckmarkCircle color={'#2fcc71'} size={18} />
                <p>Verified</p>
              </VerifyContentWrapper>
            ) : (
              <VerifyContentWrapper>
                <IoIosCloseCircle color={'#ef4836 '} size={18} />
                <p>Unverified</p>
              </VerifyContentWrapper>
            )}
          </>
        )}
      </StyledOptionWrapper>
    </components.Option>
  )
}

export const CustomSingleValue: React.FC<SingleValueProps<SelectValue>> = ({
  children,
  ...props
}) => {
  const hasLogo = props.data.logoUri
  const elementInnerRef = (props.selectProps as any)
    .selectedSingleValueEl as React.MutableRefObject<HTMLDivElement | null>
  const isVerified = (props.selectProps as any).isVerified
  const emailValidation = (props.selectProps as any).emailValidation
  const floatingLabel = (props.selectProps as any).floatingLabel
  const fieldIsUnavailable = (props.selectProps as any).fieldIsUnavailable

  const floatingSingleValueStyles = {
    marginTop: '18px',
    color: fieldIsUnavailable && 'red',
  }

  return hasLogo ? (
    <StyledOptionWrapper>
      <StyledOptionImageWrapper>
        <img src={props.data.logoUri} />
      </StyledOptionImageWrapper>
      <StyledOptionValueWrapper ref={elementInnerRef}>
        {props.data.label}
      </StyledOptionValueWrapper>
    </StyledOptionWrapper>
  ) : (
    <components.SingleValue {...props}>
      <div
        style={
          floatingLabel
            ? floatingSingleValueStyles
            : { display: 'flex', justifyContent: 'space-between' }
        }
      >
        <div style={{ flex: 2 }} ref={elementInnerRef}>
          {children}
        </div>
        {emailValidation && (
          <>
            {isVerified ? (
              <VerifyContentWrapper>
                <IoIosCheckmarkCircle color={'#2fcc71'} size={18} />
                <p>Verified</p>
              </VerifyContentWrapper>
            ) : (
              <VerifyContentWrapper>
                <IoIosCloseCircle color={'#ef4836'} size={18} />
                <p>Unverified</p>
              </VerifyContentWrapper>
            )}
          </>
        )}
      </div>
    </components.SingleValue>
  )
}

export const CustomControl = ({
  children,
  ...props
}: ControlProps<SelectValue>) => {
  // dirty solution to make TS happy, but I couldn't find another way to pass props from the main Select component
  const isCreatable = (props.selectProps as any).isCreatable as
    | boolean
    | undefined

  const forceNoIcon = (props.selectProps as any).noIcon
  const isFormulas = (props.selectProps as any).isFormulas

  const showIcon =
    props.selectProps.isSearchable &&
    !props.hasValue &&
    !isCreatable &&
    !forceNoIcon

  const addActionClick = (props.selectProps as any)
    .addActionClick as SmartSelectProps['addActionClick']

  const floatingLabel = (props.selectProps as any).floatingLabel
  const defaultLabel = (props.selectProps as any).defaultLabel
  const currentValue = props.getValue()
  const hasValue = currentValue.length > 0 && currentValue[0].value !== ''

  return (
    <components.Control {...props}>
      {floatingLabel && (
        <FloatingLabelStyled isFloating={props.isFocused || hasValue}>
          {props.isFocused || hasValue
            ? floatingLabel.toUpperCase()
            : defaultLabel}
        </FloatingLabelStyled>
      )}
      {(showIcon || isFormulas) && (
        <StyledControlIconWrapper>
          {addActionClick ? (
            <AddActionClickButton
              onClick={() => {
                addActionClick.onClick()
              }}
            >
              <AiOutlinePlus
                strokeWidth={20}
                style={{
                  backgroundColor: '#F1F3FF',
                  borderRadius: '50%',
                  padding: '.1rem',
                }}
                size='1.3rem'
              />
            </AddActionClickButton>
          ) : isFormulas ? (
            <img style={{ width: '1rem' }} src={leftIcon} alt='' />
          ) : (
            <AiOutlineSearch size='1rem' />
          )}
        </StyledControlIconWrapper>
      )}
      {isCreatable ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            marginTop: floatingLabel ? '1rem' : 0,
          }}
        >
          {children}
        </div>
      ) : (
        <>{children}</>
      )}
    </components.Control>
  )
}

export const CustomValueContainer = ({
  children,
  ...props
}: ValueContainerProps<SelectValue>) => {
  // show tooltip in two cases:
  // - tooltipInternalLabel is the selected label when text is too long and gets trimmed
  // - tooltipExternalMessage is thte tooltipMessage passed from parent component
  const tooltipId = generateRandomId()
  const tooltipInternalLabel = (props.selectProps as any).tooltipInternalLabel
  const tooltipExternalMessage = (props.selectProps as any)
    .tooltipExternalMessage
  const innerProps =
    tooltipInternalLabel || tooltipExternalMessage
      ? {
          ...props.innerProps,
          'data-tip': '',
          'data-for': tooltipId,
        }
      : props.innerProps

  return (
    <>
      <components.ValueContainer {...props} innerProps={innerProps}>
        {children}
      </components.ValueContainer>
      {tooltipInternalLabel || tooltipExternalMessage ? (
        <Tooltip
          id={tooltipId}
          label={tooltipInternalLabel}
          tooltipMessage={tooltipExternalMessage}
        />
      ) : null}
    </>
  )
}

export const CustomIndicatorsContainer = (
  props: IndicatorsContainerProps<SelectValue>
) => {
  const emailValidation = (props.selectProps as any).emailValidation
  const isMenuOpen = props.selectProps.menuIsOpen
  const upDownIconsStyle = (props.selectProps as any).upDownIconsStyle
  const largeUpDownIconsStyle = (props.selectProps as any).largeUpDownIconsStyle

  return (
    <>
      {emailValidation ? null : (
        <components.IndicatorsContainer
          {...props}
          className={'indicators-container'}
        >
          {props.selectProps.isClearable && props.hasValue ? (
            // @ts-ignore
            <components.ClearIndicator {...props}>
              <IoIosClose
                size={25}
                onClick={() => {
                  props.clearValue()
                  props.setValue({ value: '', label: '' }, 'deselect-option', {
                    value: '',
                    label: '',
                  })
                }}
              />
            </components.ClearIndicator>
          ) : null}
          {upDownIconsStyle && (
            <>
              {isMenuOpen ? (
                <MdArrowDropUp
                  size={20}
                  color={'#3fb1f2'}
                  style={{ marginRight: '.8rem' }}
                />
              ) : (
                <MdArrowDropDown
                  size={20}
                  color={'#c3ccd5'}
                  style={{ marginRight: '.8rem' }}
                />
              )}
            </>
          )}
          {largeUpDownIconsStyle && (
            <DropDownIconsWrapperStyled isMenuOpen={isMenuOpen}>
              {isMenuOpen ? (
                <FaChevronUp
                  size={18}
                  color={'#3fb1f2'}
                  style={{ marginRight: '.8rem' }}
                />
              ) : (
                <FaChevronDown
                  size={18}
                  color={'#c3ccd5'}
                  style={{ marginRight: '.8rem' }}
                />
              )}
            </DropDownIconsWrapperStyled>
          )}
        </components.IndicatorsContainer>
      )}
    </>
  )
}

export const CustomMultiValueRemove = (
  props: MultiValueRemoveProps<SelectValue>
) => {
  const isDisabled = props.selectProps.isDisabled
  return (
    <>
      {isDisabled ? null : (
        <components.MultiValueRemove {...props}></components.MultiValueRemove>
      )}
    </>
  )
}
