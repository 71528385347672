import React, { useEffect } from 'react'
import styled from 'styled-components'
import confetti from 'canvas-confetti'

const ConfettiStyled = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
  pointer-events: none;
  touch-action: none;
  canvas {
    width: 100%;
    height: 100%;
  }
`
// DOCS https://www.kirilv.com/canvas-confetti/
// https://github.com/catdad/canvas-confetti#readme
const Confetti: React.FC = () => {
  useEffect(() => {
    const myCanvas = document.createElement('canvas')
    const confettiDiv = document.getElementById('confetti')
    confettiDiv!.appendChild(myCanvas)

    const myConfetti = confetti.create(myCanvas, {
      resize: true,
      useWorker: true,
    })
    myConfetti({
      particleCount: 320,
      spread: 140,
      origin: { y: 0.6 },
      // any other options from the global
      // confetti function
    })
  }, [])

  return <ConfettiStyled id='confetti'></ConfettiStyled>
}

export default Confetti
