import React from 'react'
import { FieldToggleStatus } from '@features/nbee/EmailReceipt/FieldToggleStatus'
import styled from 'styled-components'
import { appRoutes } from '@app/routes'
import { useTranslation } from 'react-i18next'
import { DropdownIconWrapper } from '@components/Dropdown/styled'
import { useHistory } from 'react-router-dom'
import { StarterLabel } from '@features/nbee/AddFilterButton/styled'
import { FaLock } from 'react-icons/fa'
import { minimumPlanName } from '@features/nbee/FieldsMappingForm'
import { useUpdateBridgeToPricing } from '@features/nbee/utils'

const Wrapper = styled.div`
  // pointer-events: none;
  display: flex;
  gap: 1rem;
  cursor: pointer;
`

const UpdateMessage = styled.div`
  padding: 0.5rem 0;
  text-align: right;
  font-size: 0.9rem;
  color: ${({ theme }) => theme.input.disabled.color};
`

interface Props {
  requiredLbPlanId?: number
  moduleReceiptId?: string
  bridgeId?: string | number | undefined
}

export const FeatureNotAvailable: React.VFC<Props> = ({
  requiredLbPlanId,
  moduleReceiptId,
  bridgeId,
}) => {
  const updateBridgeToPricing = useUpdateBridgeToPricing(
    bridgeId,
    moduleReceiptId,
    requiredLbPlanId
  )

  return (
    <div>
      <Wrapper onClick={() => updateBridgeToPricing()}>
        <FieldToggleStatus forcedDisabled />
      </Wrapper>
    </div>
  )
}
