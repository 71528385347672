import * as yup from 'yup'
import { IntegrationFormField } from 'Nbee'
import t from 'public/locales/en-US.json'

export const makeAuthCredentialValidationSchema = (isStepName: boolean) => {
  const mappedItemSchema = yup.lazy((field: IntegrationFormField) => {
    const yupFieldArray =
      field.format === 'email'
        ? yup
            .array()
            .of(
              yup
                .string()
                .email('Invalid email format')
                .typeError('Invalid email format')
            )
        : yup.array().of(yup.string())
    const yupFieldSchema =
      field.type === 'email'
        ? yup
            .string()
            .email('Invalid email format')
            .typeError('Invalid email format')
        : field.type === 'toggle'
        ? yup.boolean().typeError('Invalid format')
        : field.type === 'tags' || field.type === 'validate_email_multi'
        ? yupFieldArray
        : yup.string().typeError('Invalid format')

    // not required, early return
    if (!field.isRequired) {
      return yup.object().shape({
        value: yupFieldSchema,
      })
    }

    const a = yup.array().of(yup.string())
    const isEmptyArray = Array.isArray(field.value) && !field.value.length
    if (isEmptyArray || !field.value) {
      return yup.object().shape({
        value:
          field.type === 'tags'
            ? yupFieldArray.min(1, t.common.genericFieldIsRequired)
            : yupFieldSchema.required(t.common.genericFieldIsRequired),
      })
    }

    return yup.object().shape({
      value: yupFieldSchema,
    })
  }) as never // https://github.com/jquense/yup/issues/1283

  return yup.object().shape({
    name: isStepName
      ? yup.string().required(t.common.genericFieldIsRequired)
      : yup.string(),
    credentials: yup.array().of(mappedItemSchema),
  })
}
