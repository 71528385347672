import styled from 'styled-components'

export const ModalWrapper = styled.div`
  padding: 3rem 2rem;
`
export const ModalHeader = styled.div`
  font-weight: 600;
  margin-bottom: 1.2rem;
  font-size: 1.2rem;
`
export const ModalBody = styled.div`
  font-size: 0.9rem;
  margin-bottom: 2rem;

  svg {
    width: 18rem;
    display: block;
    margin: 2.2rem auto;
  }
`
export const ModalFooter = styled.div`
  width: 50%;
  margin: 0 auto;
`
