import * as yup from 'yup'

type ChangePasswordTypes = {
  oldPassword: string
  newPassword: string
  confirmPassword: string
}

export const validationSchema = yup.object().shape({
  oldPassword: yup.string().required('Required'),
  newPassword: yup
    .string()
    .min(8, 'At least 8 char')
    .matches(/.*[a-z].*/, 'At least one lowercase')
    .matches(/.*[A-Z].*/, 'At least one uppercase')
    .matches(/.*[0-9].*/, 'At least one number digit')
    .matches(/.*[^A-Za-z0-9].*/, 'At least one special character'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], "Passwords don't match")
    .required('Required'), // NOTE: If changed, we need to change it also in CustomInputError.tsx
})

export const initialFormValues: ChangePasswordTypes = {
  oldPassword: '',
  newPassword: '',
  confirmPassword: '',
}
