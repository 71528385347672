import React, { forwardRef } from 'react'
import { Header, Icon, List } from 'semantic-ui-react'
import styled from 'styled-components'
import Typography from '@components/Basic/Typography'

const PasswordHelperStyled = styled.div<{ visible: boolean }>`
  /* This renders the buttons above... Edit me! */
  position: absolute;
  background: white;
  box-sizing: border-box;
  border-radius: 5px;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%),
    0 1px 5px 0 rgb(0 0 0 / 12%);
  @media screen and (max-width: 720px) {
    box-shadow: 0 -7px 15px 0px rgb(0 0 0 / 10%),
      0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
  }
  z-index: 99;
  @media screen and (min-width: 721px) {
    left: calc(100% + 50px);
    top: 0;
  }
  @media screen and (max-width: 720px) {
    left: 0;
    right: 100%;
    top: 80px;
    width: 100%;
  }
  // Animation show/hide ------------
  //display: ${(props) => (props.visible ? 'block' : 'none')};
  opacity: ${(props) => (props.visible ? '1' : '0')};
  width: ${(props) => (props.visible ? 'fit-content' : '0')};
  min-width: ${(props) => (props.visible ? '390px' : '0')};
  max-height: ${(props) => (props.visible ? 'auto' : '0')};
  overflow: ${(props) => (props.visible ? 'visible' : 'hidden')};
  transform: ${(props) =>
    props.visible ? 'translateX(0)' : 'translateX(-5px)'};
  transition: opacity 0.16s ease-out, transform 0.16s ease-out;
  &:after {
    @media screen and (max-width: 720px) {
      content: ' ';
      position: absolute;
      top: 0;
      left: 35px;
      margin-top: -20px;
      border-width: 10px;
      border-style: solid;
      border-color: transparent transparent white transparent;
    }
    @media screen and (min-width: 721px) {
      content: ' ';
      position: absolute;
      top: 35px; /* At the bottom of the tooltip */
      left: 0;
      margin-left: -20px;
      border-width: 10px;
      border-style: solid;
      border-color: transparent white transparent transparent;
    }
  }
  ul li {
    padding: 5px 10px;
    transition: all 0.16s ease-out;
  }
  .check {
    color: forestgreen;
  }
  .close {
    color: red;
  }
  ul li.checked span {
    //color: #2ecc71;
    opacity: 0.3;
    text-decoration: line-through;
    transition: all 0.16s ease-out;
  }
  .title {
    margin-top: 1rem !important;
    margin-bottom: 5px;
  }
`
const PasswordHelper = forwardRef(
  (
    props: {
      displayPwHelper: boolean
      validLen: boolean
      validUpperCase: boolean
      validLowercase: boolean
      validDigit: boolean
      validSpecial: boolean
      visible: boolean
    },
    ref
  ) => {
    const dotSVG = (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='10'
        height='10'
        viewBox='0 0 24 24'
      >
        <path d='M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12z' />
      </svg>
    )
    // alert(props.visible);
    return (
      <PasswordHelperStyled visible={props.visible}>
        <Typography className={'title mx-1 text-bold'}>
          <Icon name={'shield alternate'} />
          {'Password security rules'.toUpperCase()}
        </Typography>
        <Typography className={'mx-1 text-secondary'}>
          Your password must follow these rules:
        </Typography>
        <ul className={'list list-odd unlisted'}>
          <li className={props.validLen ? 'checked' : ''}>
            <Typography className={'small'}>
              <Icon name={props.validLen ? 'check' : 'close'} />{' '}
              <span>Must be between 8 and 30 characters.</span>
            </Typography>
          </li>
          <li className={props.validUpperCase ? 'checked' : ''}>
            <Typography className={'small'}>
              <Icon name={props.validUpperCase ? 'check' : 'close'} />{' '}
              <span>
                Must contain at least one uppercase, or capital, letter (ex: A,B
                etc.)
              </span>
            </Typography>
          </li>
          <li className={props.validLowercase ? 'checked' : ''}>
            <Typography className={'small'}>
              <Icon name={props.validLowercase ? 'check' : 'close'} />{' '}
              <span>Must contain at least one lowercase letter.</span>
            </Typography>
          </li>
          <li className={props.validDigit ? 'checked' : ''}>
            <Typography className={'small'}>
              <Icon name={props.validDigit ? 'check' : 'close'} />{' '}
              <span>
                Must contain at least one number digit (ex: 0,1,2,3 etc)
              </span>
            </Typography>
          </li>
          <li className={props.validSpecial ? 'checked' : ''}>
            <Typography className={'small'}>
              <Icon name={props.validSpecial ? 'check' : 'close'} />{' '}
              <span>
                Must contain at least one special character <br />
                (ex: $,#,@,!,$,^,&,*,(,),-)
              </span>
            </Typography>
          </li>
        </ul>
      </PasswordHelperStyled>
    )
  }
)
export default PasswordHelper
