import { ApiFormulasSchemaResponseData } from 'BackendApi'
import { MappedFieldMapping, MappedField } from 'Nbee'
import { SelectValueMulti } from '@components/Form/MultiCreatableCustom'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'

export const regenerateMappingIds = (
  newMapping: MappedFieldMapping[],
  fieldIndex: number
): MappedFieldMapping[] => {
  return newMapping.map((mapping, index) => {
    return {
      ...mapping,
      id: `${fieldIndex}-${
        mapping.sourceFieldId || mapping.formula?.id || mapping.text
      }-${index}`,
    }
  })
}

// function to convert options objects
// from SelectValueMulti
// into fields mapping object
// fot the Formik context
export const convertToMapping = (
  options: SelectValueMulti[],
  mappedField: MappedField
): MappedFieldMapping[] => {
  return options.map((option) => {
    const isFormula = option.fieldType === 'formula'
    const isCustom = option.fieldType === 'custom'
    const isText = option.fieldType === 'text'
    const isSource = option.fieldType === 'source'
    return {
      id: option.selectId,
      sourceFieldId: isSource || isCustom ? option.value?.toString() : null,
      fieldType: option.fieldType,
      text: isText ? option.value : null,
      formula: isFormula
        ? {
            id: option.value,
            // Directly find and use existing formula params if available, or set to an empty array
            params:
              mappedField?.mapping.find((m) => m.id === option.selectId)
                ?.formula?.params || [],
          }
        : null,
    }
  }) as MappedFieldMapping[]
}

export const createOptions = (
  sourceFields: SelectValueMulti[],
  picklistOptions: SelectValueMulti[],
  formulas: ApiFormulasSchemaResponseData[],
  activeTab: string
): SelectValueMulti[] => {
  const { t } = useTranslation()
  if (activeTab === 'fields') {
    return sourceFields
  } else if (activeTab === 'custom') {
    return picklistOptions
  } else if (activeTab === 'formulas') {
    return formulas?.map(
      (formula) =>
        ({
          label: `${formula.category} \u00BB ${t(
            `nbee.formulas.${formula.id}.Name` as any
          )}`,
          formulaDescription: `${t(
            `nbee.formulas.${formula.id}.Description` as any
          )}`,
          value: formula.id,
          formulaCategory: formula.category,
          fieldType: 'formula',
        } as SelectValueMulti)
    )
  } else {
    return []
  }
}

const findFieldLabel = (
  fieldId: string | null,
  field: MappedFieldMapping,
  sourceFields?: SelectValueMulti[]
): string | null => {
  return sourceFields?.find((f) => f.value === fieldId)?.label || field.text
}
export const createDefaultValues = (
  mapping: MappedFieldMapping[],
  sourceFields: SelectValueMulti[],
  t: TFunction
): SelectValueMulti[] => {
  return mapping.map((item) => {
    const isFormula = item.fieldType === 'formula'
    const isText = item.fieldType === 'text'
    const isSource = item.fieldType === 'source'
    const isCustom = item.fieldType === 'custom'

    if (isFormula && item.formula?.params?.length) {
      const firstParam = findFieldLabel(
        Array.isArray(item.formula.params[0]?.values)
          ? item.formula.params[0].values?.join('')
          : item.formula.params[0].values?.toString() || '',
        item,
        sourceFields
      )
      const formulaName = t(`nbee.formulas.${item.formula.id}.Name` as string)
      return {
        label: `${formulaName.toUpperCase()}${
          firstParam ? ': ' + firstParam : ''
        }`,
        value: item.formula.id,
        fieldType: item.fieldType,
        selectId: item.id,
      }
    }

    return {
      label: (isSource || isFormula
        ? findFieldLabel(item.sourceFieldId, item, sourceFields)
        : isText && item.text === ' '
        ? '<white-space>'
        : item.text
        ? item.text
        : isCustom
        ? item.sourceFieldId
        : '') as string,
      value: item.sourceFieldId || item.text || '',
      fieldType: item.fieldType,
      selectId: item.id,
    }
  })
}
