import React, { useEffect } from 'react'
import { AuthScope } from '@components/ButtonProvider'
import {
  createFbOnLoginFunction,
  initFbScriptOrCreate,
} from '@components/ButtonProvider/Facebook/facebookApiService'
import styled from 'styled-components'

interface FacebookButtonLegacyProps {
  dataSize?: 'large' | 'small'
  dataWidth?: number
  useContinueAs?: boolean
  onTokenRetrived: (token: string) => void
  scope: AuthScope
  onSdkLoadError: () => void
  customFbAppScopes?: string
}

const FacebookNativeButtonWrapper = styled.div`
  min-height: 40px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30px;
    height: 30px;
    margin-top: -15px;
    margin-left: -15px;
    border-radius: 50%;
    border: 4px solid #1976f2;
    border-top-color: #ccc;
    animation: fb-loading-spinner 1s ease-in-out infinite;
  }
  @keyframes fb-loading-spinner {
    to {
      transform: rotate(360deg);
    }
  }
`

export const FacebookButtonNative: React.FC<FacebookButtonLegacyProps> = ({
  dataSize = 'large',
  dataWidth,
  useContinueAs = true,
  onTokenRetrived,
  scope,
  onSdkLoadError,
  customFbAppScopes,
}) => {
  useEffect(() => {
    initFbScriptOrCreate()
      .then(() => {
        createFbOnLoginFunction('onFbLogin', onTokenRetrived)
      })
      .catch((error) => {
        console.log(error)
        onSdkLoadError()
      })
  }, [])

  return (
    <FacebookNativeButtonWrapper>
      <div
        className='fb-login-button'
        data-scope={customFbAppScopes || 'public_profile,email'}
        data-width={dataWidth}
        data-size={dataSize}
        data-button-type={scope === 'NoScope' ? 'continue_with' : 'login_with'}
        data-layout='default'
        data-auto-logout-link='false'
        data-use-continue-as={String(useContinueAs)}
        data-onlogin='onFbLogin'
      />
    </FacebookNativeButtonWrapper>
  )
}
