import React from 'react'
import { InputToggle } from '@components/Form/InputToggle'
import { useField } from 'formik'
import { IntegrationFormField } from 'Nbee'
import {
  InputFeedback,
  InputFeedbackStatus,
} from '@components/Basic/InputFeedback'

interface Props {
  index: number
  isLoading?: boolean
}

export const CredentialFieldToggle: React.FC<Props> = ({
  index,
  isLoading,
}) => {
  const [{ value: field, onBlur }, { touched, error }, { setValue }] =
    useField<IntegrationFormField>(`credentials.${index}`)
  const [_, __, { setValue: setTriggerSettingsUpdate }] = useField<number>(
    `ui.triggerSettingsUpdate`
  )

  const isChecked = Boolean(field.value) || false

  // error could a string or an object with value: string
  const parsedError = error ? (error as any).value || error : null
  const status: InputFeedbackStatus | undefined =
    touched && error
      ? {
          error: parsedError,
        }
      : undefined

  const handleFreshContentRequest = () => {
    if (field.hasChild) {
      setTimeout(() => {
        // just to be sure formik state is updated
        setTriggerSettingsUpdate(new Date().getTime())
      }, 100)
    }
  }

  return (
    <div>
      <InputToggle
        onChange={() => {
          setValue({
            ...field,
            value: !isChecked,
          })
          handleFreshContentRequest()
        }}
        $isChecked={isChecked}
        $labelRight={field.label}
        $variant={'primary'}
        onBlur={onBlur}
        disabled={field.hasChild && isLoading}
      />
      {status ? <InputFeedback $status={status} /> : null}
    </div>
  )
}
