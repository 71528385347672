import React from 'react'
import { Button } from '@components/Basic/ButtonNbe'
import styled from 'styled-components'
import lbLogo from '@assets/images/logo_lb_extended.svg'
import errorImg from '@assets/images/generic-error.svg'

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
`
const LogoWrapper = styled.div`
  position: absolute;
  top: 2rem;
  right: 2rem;
`

const Container = styled.div`
  text-align: center;
  width: 650px;

  h1 {
    margin-bottom: 6rem;
    font-size: 1.8rem;
    font-weight: 600;
  }
`
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 5rem auto 0;
  width: 60%;
`

export const ErrorPage: React.FC = () => {
  return (
    <Wrapper>
      <LogoWrapper>
        <img src={lbLogo} width={140} alt={'LeadsBridge'} />
      </LogoWrapper>
      <Container>
        <h1>Oops, an unknown error has occurred..</h1>
        <img src={errorImg} width={250} alt='Error' />
        <ButtonWrapper>
          <a
            target={'_blank'}
            href={`${process.env.REACT_APP_V1_URL}/support`}
            rel='noreferrer'
          >
            <Button $variant={'action'}>Contact support</Button>
          </a>
          <a
            target={'_blank'}
            href={`${process.env.REACT_APP_V1_URL}`}
            rel='noreferrer'
          >
            <Button $variant={'primary'}>Go to dashboard</Button>
          </a>
        </ButtonWrapper>
      </Container>
    </Wrapper>
  )
}
