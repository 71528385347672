import React from 'react'
import { PageEditor } from '@app/layouts/PageEditor'

import styled from 'styled-components'
import superHeroImg from '@assets/images/superhero.svg'
import { Button } from '@components/Basic/ButtonNbe'
import { useTranslation } from 'react-i18next'
import { isInIframe } from '@app/utils/isInFrame'

export const InfoStepWrapper = styled.div`
  background-color: #fff;
  box-shadow: 2px 2px 9px 2px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 3rem 2.5rem;
  width: 750px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  h1 {
    font-size: 1.3rem;
    color: #273649;
  }

  p {
    font-size: 1rem;
    width: 90%;
    margin: 0 auto;
    color: #273649;
  }

  img {
    width: 320px;
    margin: 1.9rem auto;
  }

  button {
    margin: 3rem auto 0;
    width: 20rem;
  }
`

export const InfoPage: React.FC = () => {
  const { t } = useTranslation()

  return (
    <PageEditor>
      <InfoStepWrapper>
        <h1>{t('nbee.bridgeBuilder.infoPageTitle')}</h1>
        <p>{t('nbee.bridgeBuilder.infoPageSubtitle')}</p>
        <img src={superHeroImg} alt={'super hero'} />
        <p>{t('nbee.bridgeBuilder.infoPageDescription')}</p>
        <a
          rel='noreferrer'
          href={`${process.env.REACT_APP_V1_URL}/bridges`}
          target={isInIframe() ? '_blank' : undefined}
        >
          <Button $size={'large'} $variant={'primary'}>
            {t('nbee.bridgeBuilder.continueToLeadsbridge')}
          </Button>
        </a>
      </InfoStepWrapper>
    </PageEditor>
  )
}
