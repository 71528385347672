import { useField } from 'formik'
import { AppConfigurationType } from 'Nbee'

export const useTriggerSettingsUpdate = (type: AppConfigurationType) => {
  const [field, _meta, helpers] = useField<number>(
    `ui.${type}.triggerSettingsUpdate`
  )

  const refetchSettings = () => {
    helpers.setValue(new Date().getTime())
  }

  return {
    lastUpdate: field.value,
    refetchSettings,
  }
}
