/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createIntegration = /* GraphQL */ `
  mutation CreateIntegration(
    $input: CreateIntegrationInput!
    $condition: ModelIntegrationConditionInput
  ) {
    createIntegration(input: $input, condition: $condition) {
      id
      platformId
      name
      icon
      coordinates
      isBeta
      isVisible
      createdAt
      updatedAt
    }
  }
`;
export const updateIntegration = /* GraphQL */ `
  mutation UpdateIntegration(
    $input: UpdateIntegrationInput!
    $condition: ModelIntegrationConditionInput
  ) {
    updateIntegration(input: $input, condition: $condition) {
      id
      platformId
      name
      icon
      coordinates
      isBeta
      isVisible
      createdAt
      updatedAt
    }
  }
`;
export const deleteIntegration = /* GraphQL */ `
  mutation DeleteIntegration(
    $input: DeleteIntegrationInput!
    $condition: ModelIntegrationConditionInput
  ) {
    deleteIntegration(input: $input, condition: $condition) {
      id
      platformId
      name
      icon
      coordinates
      isBeta
      isVisible
      createdAt
      updatedAt
    }
  }
`;
export const createFbAppStoreStatus = /* GraphQL */ `
  mutation CreateFbAppStoreStatus(
    $input: CreateFbAppStoreStatusInput!
    $condition: ModelFbAppStoreStatusConditionInput
  ) {
    createFbAppStoreStatus(input: $input, condition: $condition) {
      id
      status
      owner
      crmId
      createdAt
      updatedAt
    }
  }
`;
export const updateFbAppStoreStatus = /* GraphQL */ `
  mutation UpdateFbAppStoreStatus(
    $input: UpdateFbAppStoreStatusInput!
    $condition: ModelFbAppStoreStatusConditionInput
  ) {
    updateFbAppStoreStatus(input: $input, condition: $condition) {
      id
      status
      owner
      crmId
      createdAt
      updatedAt
    }
  }
`;
export const deleteFbAppStoreStatus = /* GraphQL */ `
  mutation DeleteFbAppStoreStatus(
    $input: DeleteFbAppStoreStatusInput!
    $condition: ModelFbAppStoreStatusConditionInput
  ) {
    deleteFbAppStoreStatus(input: $input, condition: $condition) {
      id
      status
      owner
      crmId
      createdAt
      updatedAt
    }
  }
`;
