import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Translate } from '@components/Translate'

interface Props {
  fieldLabels: string[]
}

const Wrapper = styled.div``

const ErrorMessage = styled.div`
  color: #db3c3c;
  font-weight: 600;
`

const SupportMessage = styled.div``

export const PopupContentMappingError: React.VFC<Props> = ({ fieldLabels }) => {
  const { t } = useTranslation()

  const fieldLabelsTrimmed =
    fieldLabels.length > 3
      ? fieldLabels.slice(0, 3).concat([' and more'])
      : fieldLabels

  return (
    <Wrapper>
      <ErrorMessage>{t('nbee.fieldsMapping.testError.message')}</ErrorMessage>
      <ErrorMessage>
        <Translate
          i18nKey={'nbee.fieldsMapping.testError.listOfFields'}
          count={fieldLabelsTrimmed.length}
          values={{
            fields: fieldLabelsTrimmed.join(', '),
          }}
        />
      </ErrorMessage>
      <SupportMessage>
        <Translate i18nKey={'nbee.fieldsMapping.testError.supportText'} />
      </SupportMessage>
    </Wrapper>
  )
}
