import React, { forwardRef } from 'react'
import { AuthState } from '@aws-amplify/ui-components'
import { Col, Container, Hidden, Row } from 'react-grid-system'
import { SignInForm } from './SignInForm.styled'
import BrandLogo from '@components/Basic/BrandLogo'
import { Divider, Form } from 'semantic-ui-react'
import Typography from '@components/Basic/Typography'
import SignInUtilities from '../functions/SignInUtilities'
import ClaimLogin from '../slots/ClaimLogin'
import ResourceItem from '@features/auth/components/ResourceItem'
import { useSelector } from 'react-redux'
import { RootState } from '@app/index'
import { AuthBackgroundStyled } from './AuthContainer.styled'
import { Loader } from '@components/Basic/Loader'

export type AuthContainerLinks = {
  label: string
  data?: any
  callback?: any
  message?: AuthState
}[]

const AuthContainer = forwardRef(
  (
    props: {
      header: any
      form: any
      isFormik?: boolean
      loading?: boolean
      formStatus?: { error?: boolean; success?: boolean }
      slot?: any
      links?: AuthContainerLinks
      onSubmit?: () => void
    },
    ref
  ) => {
    const loginLayout: string | null = useSelector(
      (state: RootState) => state.application.loginLayout
    )

    const rightSide = () => {
      switch (loginLayout) {
        case 'partner':
          return (
            <Hidden xs md sm>
              {/* <Hidden  > */}
              <Col xs={12} sm={12} md={6} lg={6} className={'my-2'}>
                <ClaimLogin />
              </Col>
            </Hidden>
          )
        case 'generic':
          return (
            <>
              <Hidden xs sm md>
                {/* <Hidden  > */}
                <Col
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  className={'my-2 resource-wrapper'}
                >
                  {SignInUtilities.resourceItems().map((resource, index) => {
                    return (
                      <ResourceItem
                        divisor={index < 2}
                        key={index}
                        head={resource.head}
                        title={resource.title}
                        text={resource.text}
                        buttonLabel={resource.buttonLabel}
                        buttonLink={resource.buttonLink}
                        type={resource.type}
                      />
                    )
                  })}
                </Col>
              </Hidden>
            </>
          )
        case 'bridge-by-url':
          return <></>
      }
    }
    const backgroundUrl =
      loginLayout === 'bridge-by-url'
        ? 'https://leadsbridge.kinsta.cloud/wp-content/themes/leadsbridge-2021/assets/images/bridge-svg.php?fillcolor=rgba(65,166,220,0.2)'
        : undefined

    return (
      <AuthBackgroundStyled backgroundUrl={backgroundUrl}>
        <Container style={{ maxWidth: 1150, width: '100%' }}>
          <Row
            align='center'
            justify={
              loginLayout === 'bridge-by-url' || window.innerWidth <= 800
                ? 'center'
                : 'between'
            }
            className={loginLayout === '__facebook__' ? 'flex-row-reverse' : ''}
            nogutter={false}
          >
            <Col
              xs={12}
              sm={12}
              md={8}
              lg={loginLayout === 'bridge-by-url' ? 6 : 5}
            >
              <SignInForm>
                <Row nogutter={true} className={'sign-in-form__wrapper'}>
                  {props.loading ? (
                    <Loader $active />
                  ) : (
                    <>
                      <div className={'sign-in-form__head'}>
                        <BrandLogo width={150} />
                        <Divider />
                      </div>
                      <div className={'sign-in-form__body'}>
                        <div
                          className={
                            'text-center ' + (props.isFormik ? 'my-1' : 'my-2')
                          }
                        >
                          {props.header}
                          {props.slot}
                          {props.isFormik ? (
                            props.form
                          ) : (
                            <Form
                              style={{ textAlign: 'left', marginTop: 0 }}
                              success={props.formStatus?.success}
                              error={props.formStatus?.error}
                              onSubmit={props.onSubmit}
                            >
                              {props.form}
                            </Form>
                          )}
                        </div>
                      </div>
                      <div className={'sign-in-form__footer'}>
                        {loginLayout === 'generic' &&
                          props.links &&
                          props.links.length > 0 && (
                            <Row
                              nogutter
                              className={'py-1'}
                              style={{ justifyContent: 'space-evenly' }}
                            >
                              {props.links?.map(
                                (
                                  link: {
                                    label: string
                                    data?: any
                                    callback?: any
                                    message?: AuthState
                                  },
                                  index
                                ) => {
                                  return (
                                    <Col key={index} className={'text-center'}>
                                      <Typography
                                        className={'small'}
                                        onClick={() => {
                                          link.callback()
                                        }}
                                      >
                                        {link.label}
                                      </Typography>
                                    </Col>
                                  )
                                }
                              )}
                            </Row>
                          )}
                      </div>
                    </>
                  )}
                </Row>
              </SignInForm>
            </Col>
            {rightSide()}
          </Row>
        </Container>
      </AuthBackgroundStyled>
    )
  }
)
AuthContainer.displayName = 'AuthContainer'
export default AuthContainer
