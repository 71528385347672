import React from 'react'
import { useField } from 'formik'
import { InputSmartCreatableSelect } from '@components/Form/InputSmartSelect/Creatable'
import { EmailReceiptStyled } from '@features/nbee/EmailReceipt/styled'
import { InputFeedback } from '@components/Basic/InputFeedback'
import { useTranslation } from 'react-i18next'
import { useGetUserInfo } from '@app/api/getUserInfo'

interface Props {
  /* name: string */
  disabled?: boolean
  error?: string | null
}

const { FieldGroup, FieldRow } = EmailReceiptStyled

export const FieldRecipients: React.VFC<Props> = ({ disabled, error }) => {
  const { t } = useTranslation()
  const [field, meta, helpers] = useField<string[]>(
    'settings.emailReceipt.recipients'
  )

  return (
    <FieldGroup>
      <FieldRow>
        <label>{t('nbee.emailReceipt.labelReceivers')}</label>
        <InputSmartCreatableSelect
          isDisabled={disabled}
          placeholder={t('nbee.emailReceipt.placeholderReceivers')}
          isClearable={false}
          defaultValue={field.value}
          onSelect={(values) => {
            helpers.setValue(values)
          }}
          onBlur={() => {
            helpers.setTouched(true)
          }}
        />
      </FieldRow>

      <FieldRow>
        <div />
        {error ? <InputFeedback $status={{ error }} /> : null}
      </FieldRow>
    </FieldGroup>
  )
}
