/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateIntegration = /* GraphQL */ `
  subscription OnCreateIntegration {
    onCreateIntegration {
      id
      platformId
      name
      icon
      coordinates
      isBeta
      isVisible
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateIntegration = /* GraphQL */ `
  subscription OnUpdateIntegration {
    onUpdateIntegration {
      id
      platformId
      name
      icon
      coordinates
      isBeta
      isVisible
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteIntegration = /* GraphQL */ `
  subscription OnDeleteIntegration {
    onDeleteIntegration {
      id
      platformId
      name
      icon
      coordinates
      isBeta
      isVisible
      createdAt
      updatedAt
    }
  }
`;
export const onCreateFbAppStoreStatus = /* GraphQL */ `
  subscription OnCreateFbAppStoreStatus($owner: String) {
    onCreateFbAppStoreStatus(owner: $owner) {
      id
      status
      owner
      crmId
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateFbAppStoreStatus = /* GraphQL */ `
  subscription OnUpdateFbAppStoreStatus($owner: String) {
    onUpdateFbAppStoreStatus(owner: $owner) {
      id
      status
      owner
      crmId
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteFbAppStoreStatus = /* GraphQL */ `
  subscription OnDeleteFbAppStoreStatus($owner: String) {
    onDeleteFbAppStoreStatus(owner: $owner) {
      id
      status
      owner
      crmId
      createdAt
      updatedAt
    }
  }
`;
