import React from 'react'
import { IntegrationFormField } from 'Nbee'
import { useField } from 'formik'
import { InputFeedbackStatus } from '@components/Basic/InputFeedback'
import { InputSmartCreatableSelect } from '@components/Form/InputSmartSelect/Creatable'

interface Props {
  index: number
  isLoading?: boolean
}

export const CredentialFieldSmartCreatableSelect: React.FC<Props> = ({
  index,
  isLoading,
}) => {
  const [{ value: field, onBlur }, { touched, error }, { setValue }] =
    useField<IntegrationFormField>(`credentials.${index}`)
  const [_, __, { setValue: setTriggerSettingsUpdate }] = useField<number>(
    `ui.triggerSettingsUpdate`
  )

  const defaultValue = field.value as unknown as string | string[]

  // error could a string or an object with value: string
  const parsedError = error ? (error as any).value || error : null
  const status: InputFeedbackStatus | undefined =
    touched && error
      ? {
          error: parsedError,
        }
      : undefined

  const handleFreshContentRequest = () => {
    if (field.hasChild) {
      setTriggerSettingsUpdate(new Date().getTime())
    }
  }

  return (
    <div>
      <InputSmartCreatableSelect
        isClearable={false}
        defaultValue={
          // we must be sure default is an array or string
          Array.isArray(defaultValue) ? defaultValue : [defaultValue]
        }
        $status={status}
        placeholder={'type to add...'}
        onSelect={(newValues) => {
          setValue({
            ...field,
            value: newValues,
          })
        }}
        onBlur={(e) => {
          handleFreshContentRequest()
          onBlur(e)
        }}
        isDisabled={field.hasChild && isLoading}
      />
    </div>
  )
}
