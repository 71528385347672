import { MappedField } from 'Nbee'
import { isFieldMapped } from '@features/nbee/FieldsMappingForm/utils'

export const sortByFieldLabel = (a: MappedField, b: MappedField) => {
  const aLabel = a.destinationFieldLabel || ''
  const bLabel = b.destinationFieldLabel || ''
  return aLabel.localeCompare(bLabel)
}

export const getSortedFields = (
  filteredFields: MappedField[]
): MappedField[] => {
  const fields = [...filteredFields]

  const requiredNotMappedFields = fields
    .filter((f) => f.isRequired && !isFieldMapped(f))
    .sort(sortByFieldLabel)

  const requiredMappedFields = fields
    .filter((f) => f.isRequired && isFieldMapped(f))
    .sort(sortByFieldLabel)

  const notRequiredNotMappedFields = fields
    .filter((f) => !f.isRequired && !isFieldMapped(f))
    .sort(sortByFieldLabel)

  const notRequiredMappedFields = fields
    .filter((f) => !f.isRequired && isFieldMapped(f))
    .sort(sortByFieldLabel)

  return [
    ...requiredNotMappedFields,
    ...requiredMappedFields,
    ...notRequiredNotMappedFields,
    ...notRequiredMappedFields,
  ]
}
