import styled, { css } from 'styled-components'
import { FakeInputProps } from './index'

export const FakeInputStyled = styled.div<FakeInputProps>`
  padding: 0.5rem 0.7em;
  min-height: 43px;
  width: 100%;
  background: rgba(228, 234, 239, 0.75);
  border-radius: 4px;
  position: relative;
  display: flex;
  align-items: center;

  ${({ required }) =>
    required &&
    css`
      &::before {
        content: '*';
        position: absolute;
        top: -6px;
        right: -8px;
        font-size: 2rem;
        color: #137fc0;
      }
    `}

  p {
    font-size: 0.9rem;
    color: #404040;
    opacity: 75%;
    margin: 0;
  }
`
