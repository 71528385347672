import React from 'react'
import {
  InputSmartSelect,
  SelectValue,
} from '@components/Form/InputSmartSelect'
import { InputField } from '@components/Form/InputField'
import { FiltersIcons } from '@components/Basic/FiltersIcons'
import { FilterRowStyled } from '../styled'
import { BridgeFilterCondition } from 'Nbee'
import { InputSmartCreatableSelect } from '@components/Form/InputSmartSelect/Creatable'
// import { useTranslation } from 'react-i18next'

interface ModalProps {
  index: number
  filterRule: BridgeFilterCondition
  fieldSelectDefaultValue: SelectValue | SelectValue[] | undefined
  fieldSelectInitialValues: SelectValue[]
  onFieldChange: (idx: number, { label, value }: SelectValue) => void
  operatorSelectDefaultValue: SelectValue | SelectValue[] | undefined
  operatorSelectInitialValues: SelectValue[]
  onOperatorChange: (idx: number, operator: string) => void
  inputValueType: string | undefined
  onValueChange: (idx: number, value: string | string[] | []) => void
  onCopyFilterRule: (idx: number) => void
  onAddFilterRule: (idx: number) => void
  onRemoveFilterRule: (idx: number) => void
  isRemoveFilterRuleDisabled: boolean
  isDefaultValueNotInSourceAnymore: boolean
  isBridgeDisabled: boolean
}

const SmartSelectProps = {
  hasCustomSearch: false,
  upDownIconsStyle: true,
  placeholder: '',
}

export const FilterRuleRow: React.FC<ModalProps> = ({
  index,
  filterRule,
  fieldSelectDefaultValue,
  fieldSelectInitialValues,
  onFieldChange,
  operatorSelectDefaultValue,
  operatorSelectInitialValues,
  onOperatorChange,
  inputValueType,
  onValueChange,
  onCopyFilterRule,
  onAddFilterRule,
  onRemoveFilterRule,
  isRemoveFilterRuleDisabled,
  isDefaultValueNotInSourceAnymore,
  isBridgeDisabled,
}) => {
  const { values, operator, field } = filterRule
  // const { t } = useTranslation()
  return (
    <FilterRowStyled>
      <InputSmartSelect
        data-testid='field-dropdown'
        initialValues={fieldSelectInitialValues}
        defaultValue={fieldSelectDefaultValue}
        onSelect={(selectedOption) =>
          onFieldChange(index, selectedOption as SelectValue)
        }
        defaultLabel={'Select Field'}
        floatingLabel={'Field'}
        fieldIsUnavailable={isDefaultValueNotInSourceAnymore}
        isClearable={false}
        isDisabled={isBridgeDisabled}
        // tooltipMessage={
        //   (isDefaultValueNotInSourceAnymore &&
        //     t('nbee.filters.tooltipFieldNotFound')) ||
        //   undefined
        // }
        {...SmartSelectProps}
      />
      <InputSmartSelect
        data-testid='condition-dropdown'
        initialValues={operatorSelectInitialValues}
        onSelect={(selectedOption) => {
          onOperatorChange(
            index,
            !(selectedOption instanceof Array) ? `${selectedOption.value}` : ''
          )
        }}
        defaultValue={operatorSelectDefaultValue}
        defaultLabel={'Select Condition'}
        floatingLabel={'Condition'}
        fieldIsUnavailable={isDefaultValueNotInSourceAnymore}
        isDisabled={isBridgeDisabled || (operator === '' && field.id === '-1')}
        {...SmartSelectProps}
        isClearable={false}
      />

      {/* Possible inputValueType are: 'text', 'disabled' and 'tags' */}
      {inputValueType === 'tags' ? (
        <InputSmartCreatableSelect
          name={'filter-value'}
          placeholder={''}
          floatingLabel={'Values'}
          defaultLabel={'Insert values'}
          defaultValue={
            // we must be sure default is an array or string
            values instanceof Array ? values : []
          }
          onSelect={(newValues) => onValueChange(index, newValues)}
          isClearable={false}
          showDropdownIndicator={false}
          isDisabled={isBridgeDisabled}
        />
      ) : (
        <InputField
          name={'filter-value'}
          type={'text'}
          defaultLabel={'Insert value'}
          floatingLabel={'Value'}
          value={filterRule.values}
          onChange={(e) => onValueChange(index, e.target.value)}
          fieldIsUnavailable={isDefaultValueNotInSourceAnymore}
          disabled={
            isBridgeDisabled || operator === '' || inputValueType === 'disabled'
          }
        />
      )}

      <FiltersIcons
        handleCopy={() => onCopyFilterRule(index)}
        handleAdd={() => onAddFilterRule(index)}
        handleDelete={() => onRemoveFilterRule(index)}
        // isCopyDisabled={hasEmptyFilterRules}
        // isAddDisabled={hasEmptyFilterRules}
        isDeleteDisabled={isRemoveFilterRuleDisabled}
        isBridgeDisabled={isBridgeDisabled}
      />
    </FilterRowStyled>
  )
}
