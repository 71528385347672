export const googleElementScriptId = 'google-gsi'

export const createScript = () => {
  return new Promise((resolve, reject) => {
    // load the Google SDK
    const script = document.createElement('script')
    script.src = 'https://accounts.google.com/gsi/client'
    script.async = true
    script.id = googleElementScriptId
    script.onload = () => {
      const google = window.google
      if (!google) {
        reject(new Error('google sdk failed to load'))
        return
      }
      resolve(null)
    }
    document.body.appendChild(script)
  })
}

export const initGoogleSignin = (
  buttonId: string,
  scope: 'signup_with' | 'signin_with' | 'continue_with'
): Promise<CredentialResponse> =>
  new Promise((resolve, reject) => {
    const google = window.google
    if (!google) {
      reject(new Error('google sdk failed to load'))
      return
    }
    google.accounts.id.initialize({
      client_id: process.env.REACT_GOOGLE_API_CLIENT_ID || '',
      callback: (credentialResponse) => {
        // our callback is stored in global window so it can be called any time
        const w = window as any
        if (w.onGoogleTokenRetrived) {
          w.onGoogleTokenRetrived(credentialResponse.credential)
        }
        // in case onGoogleTokenRetrived is not in the global window we can always proceed with an async flow
        // since this function (initGoogleSignin) will always resolve the credential reponse obj
        resolve(credentialResponse)
      },
    })

    google.accounts.id.renderButton(document.getElementById(buttonId), {
      type: 'standard',
      theme: 'outline',
      size: 'large',
      text: scope,
      locale: 'en-US',
      width: scope === 'signin_with' ? '350px' : '380px',
    })
  })

export const checkGoogleScriptOrCreate = async () => {
  const google = window.google

  if (!google) {
    await createScript()
  }

  return Promise.resolve(null)
}
