import React from 'react'
import { InputToggle } from '@components/Form/InputToggle'
import { useFormikContext } from 'formik'
import { AppConfigurationType, BridgeFormValues } from 'Nbee'
import { ApiAppSetting } from 'BackendApi'
import { getSettingFieldError } from '@features/nbee/SimpleBridgeBuilderForm/fields/IntegrationSettings/utils'
import { InputFeedback } from '@components/Basic/InputFeedback'
import { LabelWithDocTooltip } from '@components/Form/LabelWithDocTooltip'
import { useTriggerSettingsUpdate } from '@features/nbee/SimpleBridgeBuilderForm/fields/IntegrationSettings/useTriggerSettingsUpdate'
import { trackEvent } from '@app/dataTracking'

interface Props {
  fieldSchema: ApiAppSetting
  type: AppConfigurationType
  isLoading: boolean
  index: number
}

export const SettingFieldToggle: React.FC<Props> = ({
  isLoading,
  type,
  fieldSchema,
  index,
}) => {
  const {
    values: formValues,
    setFieldValue,
    setFieldTouched,
    touched,
    errors,
  } = useFormikContext<BridgeFormValues>()

  const { refetchSettings } = useTriggerSettingsUpdate(type)

  const settingFieldName = `${type}.settings`
  const currentSettingsValues = formValues[type].settings || []

  const fieldId = fieldSchema.id
  const fieldLabel = fieldSchema.required
    ? `${fieldSchema.label} (*)`
    : fieldSchema.label

  const currentFieldValue = currentSettingsValues.find((v) => v.key === fieldId)
  const isChecked = Boolean(currentFieldValue?.value) || false

  const { isTouched, fieldStatus } = getSettingFieldError({
    type,
    touched,
    errors,
    index,
  })

  return (
    <div>
      {fieldSchema.docUrl || fieldSchema.tooltip ? (
        <LabelWithDocTooltip
          label={fieldSchema.label}
          isRequired={fieldSchema.required}
          tooltip={fieldSchema.tooltip}
          docUrl={fieldSchema.docUrl}
        />
      ) : null}
      <InputToggle
        $isDisabled={formValues.ui?.isBridgeEnabled}
        onChange={() => {
          if (isLoading) {
            return
          }

          const newValue = {
            key: fieldId,
            value: !isChecked,
          }

          trackEvent({
            eventName: 'IntegrationSettingSelected',
            step: 'Apps',
            feature: 'NBEE',
            params: {
              custom: {
                fieldId: newValue.key,
                value: newValue.value,
              },
            },
          })

          const newSettings = currentFieldValue
            ? currentSettingsValues.map((setting) =>
                setting.key === fieldId ? newValue : setting
              )
            : [...currentSettingsValues, newValue]

          setFieldValue(settingFieldName, newSettings)

          // we trigger the retrival of new setting only if is a setting with a child
          if (fieldSchema.hasChild) {
            refetchSettings()
          }
        }}
        $isChecked={isChecked}
        $labelRight={fieldLabel}
        $variant={'primary'}
        onBlur={() => {
          setFieldTouched(`${settingFieldName}.${index}`, true)
        }}
      />
      {isTouched && fieldStatus ? (
        <InputFeedback $status={fieldStatus} />
      ) : null}
    </div>
  )
}
