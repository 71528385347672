import React, { useState } from 'react'
import { useField, useFormikContext } from 'formik'
import { BridgeFormValues, MappedField, MappedFieldMapping } from 'Nbee'
import { FakeInput } from '@components/Basic/FakeInput'
import {
  InputFeedback,
  InputFeedbackStatus,
} from '@components/Basic/InputFeedback'
import { StateIcon, ValidationStates } from '@components/Basic/StateIcon'
import {
  MultiCreatableCustom,
  SelectValueMulti,
} from '@app/components/Form/MultiCreatableCustom'
import { FormulaModal } from '@features/nbee/FieldsMappingForm/FormulaModal'
import {
  BinIconWrapper,
  DeleteIcon,
  DestinationFieldLabel,
  DestinationFieldsWrapper,
  ErrorWrapper,
  RowFeedback,
  RowStyled,
  RowWrapper,
  SourceFieldsWrapper,
  StateIconErrorWrapper,
} from './styled'
import { LabelWithDocTooltip } from '@components/Form/LabelWithDocTooltip'
import { RowProps, flattenErrors } from '.'
import { regenerateMappingIds } from '@components/Form/MultiCreatableCustom/utils'

export const FieldMappingRow: React.FC<RowProps> = ({
  selectOptions,
  index,
  formulaUserModule,
  formulaSchema,
  showWelcomeMessage,
  sourceLogoUri,
  onRedirectToPricing,
}) => {
  const { values } = useFormikContext<BridgeFormValues>()

  const fieldName = `fieldsMapping.${index}`
  const [field, meta, helpers] = useField<MappedField>(fieldName)
  const fieldValue = field.value

  const [isModalOpen, toggleIsModalOpen] = useState<boolean>(false)
  const [selectedMapping, setSelectedMapping] = useState<MappedFieldMapping>()

  const userHasFormulaEnabled = formulaUserModule?.enabled

  const handleMappingChange = (newMapping: MappedFieldMapping[]) => {
    // on mapping change we need to regenerate the unique ids for each mapping item
    const mappingWithNewIds = regenerateMappingIds(newMapping, index)
    helpers.setValue({
      ...fieldValue,
      mapping: mappingWithNewIds,
    })
  }

  const handleOpenModal = () => toggleIsModalOpen(true)
  const handleCloseModal = () => {
    setSelectedMapping(undefined) // reset selectedMapping
    toggleIsModalOpen(false)
  }

  const picklistOptions: SelectValueMulti[] = (fieldValue.picklist || []).map(
    (item) => {
      return {
        value: `${item.id}`, // we want this to be a string
        label: item.text,
        fieldType: 'custom',
      }
    }
  )

  const sourceFieldOptions = selectOptions.filter(
    (option) => option.fieldType === 'source'
  )

  const hasValue =
    fieldValue.destinationText ||
    fieldValue.mapping.some(
      (item) =>
        item.text ||
        item.sourceFieldId ||
        (item.formula?.id && item.formula?.params[0]?.values)
    )
  const hasError = meta.error as Partial<MappedField> | undefined // we are using a custom yup schema

  const errorStatusMessage = hasError && flattenErrors(hasError).join(', ')

  const errorStatus: InputFeedbackStatus | undefined = hasError
    ? {
        error: errorStatusMessage,
      }
    : undefined

  const validationIconState: ValidationStates = hasError
    ? 'error'
    : hasValue
    ? 'success'
    : 'default'

  return (
    <RowWrapper showWelcomeMessage={showWelcomeMessage}>
      {showWelcomeMessage && (
        <LabelWithDocTooltip label={fieldValue?.destinationFieldLabel || ''} />
      )}

      <RowStyled showWelcomeMessage={showWelcomeMessage}>
        {/* Bin icon */}
        {!showWelcomeMessage && (
          <BinIconWrapper>
            {!values.ui?.isBridgeEnabled && fieldValue.mapping.length > 0 ? (
              <DeleteIcon
                onClick={() => {
                  helpers.setValue({
                    ...fieldValue,
                    mappingType: 'manual',
                    mapping: [],
                  })
                }}
                visible={
                  fieldValue?.mapping?.some(
                    (item) =>
                      item.sourceFieldId || item.formula?.id || item.text
                  )
                    ? 'visible'
                    : 'hidden'
                }
              />
            ) : null}
          </BinIconWrapper>
        )}

        {/* Source fields */}
        <SourceFieldsWrapper fullWidth={showWelcomeMessage}>
          <MultiCreatableCustom
            isDisabled={values.ui?.isBridgeEnabled}
            onMappingChange={handleMappingChange}
            sourceOptions={selectOptions}
            picklistOptions={picklistOptions}
            mappedField={fieldValue}
            formulaSchema={formulaSchema}
            userHasFormulaEnabled={userHasFormulaEnabled}
            onFormulaSelect={(mappingWithFormula) => {
              setSelectedMapping(mappingWithFormula)
              handleOpenModal()
            }}
            fieldIndex={index}
            sourceLogoUri={sourceLogoUri}
            onRedirectToPricing={onRedirectToPricing}
          />
        </SourceFieldsWrapper>
        {/* Destination fields */}
        <DestinationFieldsWrapper>
          {!showWelcomeMessage ? (
            <>
              <StateIconErrorWrapper>
                <StateIcon state={validationIconState} />

                <ErrorWrapper>
                  {errorStatus && (
                    <RowFeedback showWelcomeMessage={showWelcomeMessage}>
                      <InputFeedback $status={errorStatus} />
                    </RowFeedback>
                  )}
                </ErrorWrapper>
              </StateIconErrorWrapper>
              <DestinationFieldLabel>
                <FakeInput
                  required={fieldValue?.isRequired}
                  text={fieldValue?.destinationFieldLabel}
                />
              </DestinationFieldLabel>
            </>
          ) : null}
        </DestinationFieldsWrapper>

        {/* Formula Modal */}

        <FormulaModal
          isOpen={isModalOpen}
          onCloseModal={handleCloseModal}
          index={index}
          sourceFieldOptions={sourceFieldOptions}
          selectedMapping={selectedMapping}
        />
      </RowStyled>
    </RowWrapper>
  )
}
