import React from 'react'
import { useField } from 'formik'
import { IntegrationFormField } from 'Nbee'
import {
  InputFeedback,
  InputFeedbackStatus,
} from '@components/Basic/InputFeedback'
import { InputDomain } from '@components/Form/InputDomain'

interface Props {
  index: number
  isLoading?: boolean
}

export const CredentialFieldDomain: React.FC<Props> = ({
  index,
  isLoading,
}) => {
  const [{ value: field, onBlur }, { touched, error }, { setValue }] =
    useField<IntegrationFormField>(`credentials.${index}`)

  const [_, __, { setValue: setTriggerSettingsUpdate }] = useField<number>(
    `ui.triggerSettingsUpdate`
  )

  // safe to cast since if we load this componnet value must be a string
  const currentValue = field.value as string

  const parts = (field.format || 'https://xxxx.domain.com').split('xxx')
  // error could a string or an object with value: string
  const parsedError = error ? (error as any).value || error : null
  const status: InputFeedbackStatus | undefined =
    touched && error
      ? {
          error: parsedError,
        }
      : undefined

  const handleFreshContentRequest = () => {
    if (field.hasChild) {
      setTriggerSettingsUpdate(new Date().getTime())
    }
  }

  return (
    <div>
      <InputDomain
        id={field.id}
        $protocolLabel={parts[0] || 'https://'}
        $domainLabel={parts[1] || '.domain.com'}
        value={currentValue}
        onChange={({ target: { value } }) => {
          setValue({
            ...field,
            value,
          })
        }}
        onBlur={(e) => {
          handleFreshContentRequest()
          onBlur(e)
        }}
        disabled={field.hasChild && isLoading}
      />
      {status ? <InputFeedback $status={status} /> : null}
    </div>
  )
}
