import * as yup from 'yup'
import { MappedField, MappedFieldFormulaParam } from 'Nbee'

export const makeFieldMappingValidationSchema = () => {
  const welcomeMessageSchema = yup.object({
    subject: yup.string().required('Subject is required'),
    body: yup.string().required('Body is required'),
  })

  const mappedItemSchema = yup.lazy((value: MappedField) => {
    if (value.isRequired && value.mapping.length === 0) {
      return yup.object({
        sourceFieldId: yup.string().required('Required'),
      })
    }

    return yup.object()
  }) as never // https://github.com/jquense/yup/issues/1283

  return yup.object().shape({
    name: yup.string().required(),
    fieldsMapping: yup.array().of(mappedItemSchema),
    templateBasedFieldsMapping: yup.lazy((values) =>
      values ? welcomeMessageSchema : yup.mixed().notRequired()
    ),
  })
}
