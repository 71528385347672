import React from 'react'
import SignIn from './slots/SignIn'
import LostPassword from './slots/LostPassword'
import { Route, Switch } from 'react-router-dom'
import { appRoutes } from '@app/routes'
import ChangePassword from '@features/auth/slots/ChangePassword/ChangePassword'

export const AuthRouter: React.FC = () => {
  return (
    <div className={'auth-slot-container'}>
      <div className={'login-slot'}>
        <Switch>
          <Route
            path={appRoutes.changePassword.makUrl()}
            component={ChangePassword}
          />
          <Route
            path={appRoutes.forgotPassword.makeUrl()}
            component={LostPassword}
          />
          <Route component={SignIn} />
        </Switch>
      </div>
    </div>
  )
}
