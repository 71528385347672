import { BridgeFormValues, MappedField, BridgePicklistValues } from 'Nbee'
import { ApiBridgeFieldsListResponse } from 'BackendApi'
import { useEffect } from 'react'
import { sendAlertMessage } from '@app/store/actions/ApplicationConfigurationActions'
import { useAppDispatch } from '@app/store/hooks'
import { useTranslation } from 'react-i18next'
import { encodeToBase64 } from '@app/utils/base64'

export interface FieldMappingMatched {
  initialValues: BridgeFormValues | null
  notFoundFields: {
    destination: string[]
    source: string[]
  }
}

// This function returns a list of all destination fields to map filled with already mapped (existing in case of edit)
// or auto mapped values. This list is used as `initialValues` for the fields mapping form.
// It also checks if some previously mapped fields do not exists anymore in source or destination
// and fill an array of missing field ids (notFoundFields.destination and notFoundFields.source)
// The missing values are removed from the initialValues, this means that our initialValues should never includes not existing values
export const makeFieldsMappingInitialValuesAndListNotMatchingFields = (
  bridgeFormValues?: BridgeFormValues,
  allBridgeFields?: ApiBridgeFieldsListResponse
): FieldMappingMatched => {
  const alreadyMappedFields = bridgeFormValues?.fieldsMapping || []
  const autoMappedFields = allBridgeFields?.data?.autoMapping || []
  const allFieldsToMap = allBridgeFields?.data?.destination || []

  const mappedDestinationFieldsNotAvailable = alreadyMappedFields
    .filter(
      (value) =>
        !allFieldsToMap.find((field) => field.id === value.destinationFieldId)
    )
    .map((el) => el.destinationFieldId || '')

  const mappedSourceFieldsNotAvailable = alreadyMappedFields
    .filter((value) =>
      value.mapping.some(
        (mapping) =>
          mapping.fieldType === 'source' &&
          !allBridgeFields?.data?.source.find(
            (field) => field.id === mapping.sourceFieldId
          )
      )
    )
    .flatMap((el) =>
      el.mapping.map((m) => m.sourceFieldId || '').filter((id) => id)
    )

  const filledFieldsMappingValues: MappedField[] = allFieldsToMap
    .map((field) => {
      const existingValue = alreadyMappedFields.find(
        (o) => o.destinationFieldId === field.id
      )
      const autoMapped = autoMappedFields.find(
        (o) => o.destinationFieldId === field.id
      )

      const extraDataForUi = {
        destinationFieldLabel: field.label,
        isRequired: field.required || false,
        testLeadValue: '',
        picklist:
          (field.values
            ?.map((v) => ({ text: v.text.toString(), id: v.id.toString() }))
            ?.sort((a, b) =>
              a.text.localeCompare(b.text)
            ) as BridgePicklistValues[]) || [],
      }

      if (existingValue) {
        return { ...existingValue, ...extraDataForUi }
      }

      if (autoMapped) {
        return { ...autoMapped, mappingType: 'auto', ...extraDataForUi }
      }

      return {
        destinationFieldId: field.id,
        mappingType: 'manual',
        destinationText: '',
        ...extraDataForUi,
        mapping: [
          {
            fieldType: field.values?.length ? 'custom' : 'source',
            sourceFieldId: '',
            text: '',
            formula: null,
          },
        ],
      }
    })
    .sort((a, b) => {
      const indexA = alreadyMappedFields.findIndex(
        (mappedField) => mappedField.destinationFieldId === a.destinationFieldId
      )
      const indexB = alreadyMappedFields.findIndex(
        (mappedField) => mappedField.destinationFieldId === b.destinationFieldId
      )

      if (indexA === -1 && indexB === -1) {
        return 0 // Both are new fields, maintain their relative order
      }
      if (indexA === -1) {
        return 1 // `a` is a new field, place it after `b`
      }
      if (indexB === -1) {
        return -1 // `b` is a new field, place it after `a`
      }
      return indexA - indexB // Maintain the order of alreadyMappedFields
    }) as MappedField[]

  const clearedFieldMapping = filledFieldsMappingValues.map((mappedField) => ({
    ...mappedField,
    mapping: mappedField.mapping.filter(
      (m) => !mappedSourceFieldsNotAvailable.includes(m.sourceFieldId || '')
    ),
  }))

  return {
    initialValues: bridgeFormValues
      ? { ...bridgeFormValues, fieldsMapping: clearedFieldMapping }
      : null,
    notFoundFields: {
      destination: mappedDestinationFieldsNotAvailable,
      source: mappedSourceFieldsNotAvailable,
    },
  }
}
// This hook is used to dispatch an alert message when `notFoundFields` has data
export const useNotifyUserOfMissingMappedFields = (
  fieldsMappingMatched: FieldMappingMatched | null,
  bridgeId: number | string
) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  // we make one single array, at this stage we show one generic message that includes both source and destination missing field
  // In future we can work with UX to generate a better message and notify if missing fields are relative to source or destination (or both)
  const notFoundPreviouslyMappedFieldsDestination =
    fieldsMappingMatched?.notFoundFields.destination || []
  const notFoundPreviouslyMappedFieldsSource =
    fieldsMappingMatched?.notFoundFields.source || []
  const notFoundPreviouslyMappedFields = [
    ...notFoundPreviouslyMappedFieldsDestination,
    ...notFoundPreviouslyMappedFieldsSource,
  ]

  // this is a workaround: the alert pops-up too often because every time a field refresh is called (inside the form)
  // a new notFoundPreviouslyMappedFields is generated, and since this also sets initialValues can't be memoized.
  // So we store user action when alert is closed
  const encodedFields = encodeToBase64(notFoundPreviouslyMappedFields)
  const persistentKey = `missingFieldsNotification:${bridgeId}`
  const persistentEncodedFields = localStorage.getItem(persistentKey)
  const alreadyNotified = persistentEncodedFields === encodedFields

  useEffect(() => {
    if (
      notFoundPreviouslyMappedFields &&
      notFoundPreviouslyMappedFields.length &&
      !alreadyNotified
    ) {
      dispatch(
        sendAlertMessage({
          isDismissable: true,
          message: t('nbee.fieldsMapping.missingMappedFields', {
            listOfNotFoundIds: notFoundPreviouslyMappedFields.join(', '),
          }),
          onClose: () => {
            localStorage.setItem(persistentKey, encodedFields)
          },
        })
      )
    }
  }, [notFoundPreviouslyMappedFields])
}
