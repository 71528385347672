import { ApiSettingValue } from 'BackendApi'
import { useEffect } from 'react'
import { makeIntegrationFormItemFromApiSchemaField } from '@features/nbee/IntegrationCredentialsContent/AppAuthCredentialForm/utils'
import { useFormikContext } from 'formik'
import { IntegrationFormValues } from 'Nbee'
import { useRetrieveAppCredentials } from '@app/api/postRetrieveAppCredentials'

// every time a field who has child as true is updated it will trigger also a change
// in formik state `values.ui.triggerSettingsUpdate` that will trigger a new post calll
// to retrieve new credential settings
export const useRefetchCredentialSchema = (appId: number) => {
  const { values, setValues } = useFormikContext<IntegrationFormValues>()
  const {
    data: appAuthSchemaResponse,
    mutate: retrieveAuthSchema,
    isLoading: isLoadingAppAuthSchema,
    error: errorApiAppAuthSchema,
  } = useRetrieveAppCredentials()

  const authSchemaApiRequest: ApiSettingValue[] = values.credentials
    // .filter((c) => c.value !== '')
    .map(({ id, value }) => ({
      key: id,
      value,
    }))

  // every time we detect a change on `ui.triggerSettingsUpdate` we retrieve new schema
  const triggerFieldUpdates = values.ui?.triggerSettingsUpdate
  useEffect(() => {
    // we execute this also on mount so we get the updated values with default set
    retrieveAuthSchema({
      appId,
      settings: authSchemaApiRequest,
      refresh: values.ui?.refresh || [],
    })
  }, [triggerFieldUpdates])

  // every time a new schema is returned from server we update formik (and UI) state
  useEffect(() => {
    const newFieldsFromApi = appAuthSchemaResponse?.data?.credentials?.fields
    if (newFieldsFromApi) {
      //
      // we adapt new fields from api to form values and fill them with existing content
      const newFormCredentialsValues = newFieldsFromApi.map((field) => {
        // We found the actual value of the field so we can "attach" it to the new fetched schema
        const existingFieldFromFormValues = authSchemaApiRequest.find(
          ({ key }) => key === field.id
        )
        // It's also possible that an existing value is not yet in the formik state, bacause it's part of
        // an already saved integration but not yet in schema, since it was child of another field.
        // This is way we kept data of the existing integration (from api) in formik value under `ui.existingIntegrationCredentials`
        // So se can retrive it once we have the updated schema with the relative child
        const existingFieldFromApiValues =
          values.ui?.existingIntegrationCredentials?.find(
            ({ key }) => key === field.id
          )

        // Now finally we can make field obj that will be store in formik state
        return makeIntegrationFormItemFromApiSchemaField({
          field,
          existingField:
            existingFieldFromFormValues || existingFieldFromApiValues,
        })
      })

      setValues(
        {
          ...values,
          credentials: newFormCredentialsValues,
          ui: {
            ...values.ui,
            refresh: [], // we always reset the refresh array to be sure we don't call for cache invalidation when not requested
          },
        },
        true
      )
    }
  }, [appAuthSchemaResponse])

  return {
    isLoadingAppAuthSchema,
    errorApiAppAuthSchema,
  }
}
