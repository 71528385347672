import * as yup from 'yup'
import {
  yupPhoneNumber,
  yupRequiredBoolean,
} from '@app/utils/validators/yupCustomValidators'

export type FormValues = typeof initialFormValues

export const initialFormValues = {
  email: '',
  phone: '',
  industry: '',
  privacyConsent: false,
}

export const validationSchema = yup.object().shape({
  email: yup.string().email().required(),
  phone: yupPhoneNumber(),
  privacyConsent: yupRequiredBoolean('Consent is mandatory'),
})
