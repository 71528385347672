import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
`

export const DataTable = styled.div`
  border: 1px solid #d8d8d8;
  border-bottom: none;
  border-right: none;
  border-radius: 4px;
`

export const FieldsRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr 3fr;
  border-bottom: 1px solid #d8d8d8;
`

export const InputBox = styled.div`
  display: flex;
  justify-content: center;
  padding: 0.6rem 0.1rem;
  border-right: 1px solid #d8d8d8;

  span {
    left: 5px !important;
  }
`

export const ValueBox = styled.div`
  position: relative; /* make sure pseudo-elements are relative to this box */
  padding: 0.6rem 0.75rem;
  border-right: 1px solid #d8d8d8;
  word-wrap: break-word;
  overflow-wrap: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
  -webkit-hyphenate-character: '';
  hyphenate-character: '';
  word-break: break-all;
`
