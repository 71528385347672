// inject FB sdk script in the page and resolve the promise once done
import { sleep } from '@app/utils/helpers'

export const createScript = () =>
  new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.src = 'https://connect.facebook.net/en_US/sdk.js'
    script.async = true
    script.onload = () => {
      resolve(null)
    }
    script.onerror = () => {
      reject(
        new Error(
          'Call to https://connect.facebook.net/en_US/sdk.js has been blocked'
        )
      )
    }
    document.body.appendChild(script)
  })

// init FB with our appID and default settings
// if FB SDK is not found promise will be rejected
export const facebookInit: () => Promise<null> = () =>
  new Promise((resolve, reject) => {
    const fb = window.FB
    if (!fb || !fb.login) {
      // we check the existance of fb.login since ad-blockers allow FB init but remove some methods like `login`
      reject(new Error('Failed FB SDK init'))
      return
    }
    fb.init({
      appId: '939660826071336',
      cookie: true,
      xfbml: true,
      version: 'v2.11',
    })
    resolve(null)
  })

// create a function in global window object to be called by facebook as callback
// once button is rendered
export const createFbOnLoginFunction = (
  fnName: string,
  onTokenRetrived: (token: string) => void
) => {
  const w = window as any
  w[fnName] = () => {
    FB.getLoginStatus((response) => {
      if (response?.authResponse?.accessToken) {
        onTokenRetrived(response.authResponse.accessToken)
      }
    })
  }
}

// This is to be used to check if facebook script exists, if so it will be initializated
export const initFbScriptOrCreate = async () => {
  const fb = window.FB
  if (!fb) {
    await createScript()
  }
  await sleep(300)
  return await facebookInit()
}
