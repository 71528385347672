export const calculateLineHeight = (
  element: HTMLInputElement | HTMLTextAreaElement
): number => {
  const computedStyle = window.getComputedStyle(element)
  const lineHeightStr = computedStyle.lineHeight
  if (lineHeightStr && lineHeightStr !== 'normal') {
    return parseInt(lineHeightStr, 10)
  }
  const testDiv = document.createElement('div')
  testDiv.style.position = 'absolute'
  testDiv.style.visibility = 'hidden'
  testDiv.style.width = `${element.clientWidth}px`
  testDiv.innerHTML = '<br>'
  document.body.appendChild(testDiv)
  const lineHeight = testDiv.offsetHeight
  document.body.removeChild(testDiv)

  return lineHeight
}

export const calculateDropdownPosition = (
  element: HTMLInputElement | HTMLTextAreaElement,
  value: string,
  dropdownHeight: number,
  indexOfLastOpenBracket: number
): { x: number; y: number } => {
  const rect = element.getBoundingClientRect()
  const lineHeight = calculateLineHeight(element)
  const textUpToCursor = value.substring(0, indexOfLastOpenBracket + 1)
  const lastNewLineIndex = value.lastIndexOf('\n', indexOfLastOpenBracket)

  const textAfterLastNewLine =
    lastNewLineIndex !== -1
      ? value.substring(lastNewLineIndex + 1, indexOfLastOpenBracket + 1)
      : textUpToCursor

  const textLines = textUpToCursor.split('\n')
  const dropdownY =
    rect.top +
    (textLines.length - 1) * lineHeight -
    dropdownHeight -
    (element.scrollHeight - element.clientHeight)

  const ghostSpan = document.createElement('span')
  ghostSpan.style.visibility = 'hidden'
  ghostSpan.style.position = 'absolute'
  ghostSpan.style.whiteSpace = 'pre'
  ghostSpan.textContent = textAfterLastNewLine
  document.body.appendChild(ghostSpan)

  const computedStyle = window.getComputedStyle(element)
  ghostSpan.style.font = computedStyle.font
  ghostSpan.style.letterSpacing = computedStyle.letterSpacing
  ghostSpan.style.paddingLeft = computedStyle.paddingLeft
  ghostSpan.style.paddingRight = computedStyle.paddingRight

  const ghostRect = ghostSpan.getBoundingClientRect()
  const dropdownX = rect.left + ghostRect.width - element.scrollLeft

  document.body.removeChild(ghostSpan)

  return { x: dropdownX, y: dropdownY }
}
