import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '@app/store/hooks'
import { RootState } from '@app/index'
import { matchPath, useHistory, useLocation } from 'react-router-dom'
import SignInUtilities from '../functions/SignInUtilities'
import AuthContainer from '@features/auth/components/AuthContainer'
import {
  sendToast,
  setLoginLayout,
} from '@app/store/actions/ApplicationConfigurationActions'
import queryString from 'query-string'
import { appRoutes, bridgeByUrlPartnersPaths } from '@app/routes'
import { SignInBridgeByUrl } from '@features/auth/bridgeByUrl/SignInBridgeByUrl'
import { BbuRouteParams } from 'LeadsBridgeApp'
import { BbuPartnersName } from '@app/enums/bridgeByUrl'

import { Button } from '@components/Basic/Button'
import { InputField } from '@components/Form/InputField'
import Typography from '@components/Basic/Typography'
import { Row, Col } from 'react-grid-system'
import { Divider, Icon } from 'semantic-ui-react'
import { ButtonProvider } from '@components/ButtonProvider'
import { Loader } from '@components/Basic/Loader'
import { useTranslation } from 'react-i18next'
import { ButtonLoginContainer } from '../components/AuthContainer.styled'
import { useGetUserInfo } from '@app/api/getUserInfo'

const SignIn: React.FC = () => {
  const history = useHistory()
  const location = useLocation()
  const { t } = useTranslation()

  const [shouldFetchUserData, setShouldFetchUserData] = useState(false)
  const { data: userData, isLoading: isLoadingUser } =
    useGetUserInfo(shouldFetchUserData)

  const [doingLogin, setDoingLogin] = useState<boolean>(false)
  const [loggedIn, setLoggedIn] = useState<boolean>(false)
  const [doingSingin, setDoingSignin] = useState<boolean>(false)

  // const dispatch: Dispatch<AppDispatch> = useDispatch();
  const dispatch = useAppDispatch()
  const [email, setEmail] = useState<string>('')
  const [emailError, setEmailError] = useState<string>('')
  const [emailValided, setEmailValided] = useState<boolean>(false)

  const [pw, setPw] = useState<string>('')
  const [pwIsVisible, setPwIsVisible] = useState<boolean>(false)
  const [pwError, setPwError] = useState<string>('')
  const [formError, setFormError] = useState<string>('')

  const signInUtilities = new SignInUtilities(
    setEmailError,
    setPwError,
    setDoingLogin,
    setLoggedIn,
    dispatch
  )
  const loginLayout: string | null = useSelector(
    (state: RootState) => state.application.loginLayout
  )

  const bbuMatchUrl = matchPath<BbuRouteParams>(location.pathname, {
    path: bridgeByUrlPartnersPaths,
    exact: true,
  })

  const bbuPartnerName = bbuMatchUrl?.params.partnerName
  const isBbuValidPartnerName =
    bbuPartnerName && Object.values(BbuPartnersName).includes(bbuPartnerName)
  const downgradeStatus = userData?.user.downgradeStatus

  useEffect(() => {
    ;/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.+[A-Z]{2,12}$/i.test(email)
      ? setEmailValided(true)
      : setEmailValided(false)
  }, [email])

  useEffect(() => {
    if (loggedIn) {
      setShouldFetchUserData(true)
    }
  }, [loggedIn])

  useEffect(() => {
    if (!isLoadingUser && userData && downgradeStatus !== 'no') {
      window.location.href = `/pricing?reason=${downgradeStatus}`
    }
  }, [loggedIn])

  // Identify what type of login layout we need to display depending on different URL or query string
  useEffect(() => {
    // react router get param
    // leadsbrdige.com/?url
    // const isInBridgeByUrl: boolean = window.location.pathname.includes('bc/fb/')

    // example ?type=partner - Deprecated
    const isInPartnerSignIn: boolean =
      queryString.parse(window.location.search).type === 'partner'
    if (isInPartnerSignIn) {
      dispatch(setLoginLayout('partner'))
      return
    }

    // example: /bc/fb/facebook-lead-ads/google-sheets
    // example: /bc/tt/souce/dest
    if (bbuMatchUrl && isBbuValidPartnerName) {
      dispatch(setLoginLayout('bridge-by-url'))
      return
    } else if (bbuMatchUrl && !isBbuValidPartnerName) {
      history.push(appRoutes.signin.makeUrl())
    }

    // generic login layout
    dispatch(setLoginLayout('generic'))
  }, [])

  const handleValidateEmail = () => {
    if (!emailValided) {
      setEmailError('Please enter an email address.')
    }
    return null
  }

  const forgotPassword = () => {
    history.push(appRoutes.forgotPassword.makeUrl())
  }

  const saml = () => {
    history.push(appRoutes.saml.makeUrl())
  }

  const pricing = () => {
    window.location.href = 'https://leadsbridge.com/pricing'
  }

  const links = [
    { callback: forgotPassword, label: t('auth.signin.lostPassword') },
    { callback: saml, label: t('auth.signin.withSaml') },
    { callback: pricing, label: t('auth.signin.newAccount') },
  ]

  const showGenericError = (message: string) => {
    setDoingSignin(false)
    dispatch(
      sendToast({
        title: 'Error',
        messages: [message],
        color: 'negative',
      })
    )
  }

  const formBody = () => {
    return (
      <>
        <InputField
          onFocus={() => setEmailError('')}
          placeholder='Your email'
          value={email}
          autoComplete={'email'}
          name={'email'}
          type='email'
          $hasMargin
          $status={{ error: emailError }}
          label={t('auth.signin.email')}
          onChange={(e) => {
            setEmail(e.target.value)
            setEmailError('')
          }}
          data-testid={'signin-email'}
        />
        <InputField
          $fluid
          $hasMargin
          slotRight={
            pw ? (
              <Icon
                name={pwIsVisible ? 'eye slash' : 'eye'}
                link
                onClick={() => setPwIsVisible((prevState) => !prevState)}
              />
            ) : (
              <></>
            )
          }
          value={pw}
          type={pwIsVisible ? 'text' : 'password'}
          name='password'
          id='password'
          label={t('auth.signin.password')}
          $status={{ error: pwError }}
          autoComplete={'password'}
          // data-lp ignore="true"
          onChange={(e) => {
            setPw(e.target.value)
            handleValidateEmail()
          }}
          placeholder='Your password'
          data-testid={'signin-password'}
        />

        {formError !== '' && <p className={'input-error'}>{formError}</p>}
        {loginLayout === 'bridge-by-url' ? (
          <Button
            type={'button'}
            $variant={'link'}
            onClick={() => {
              history.push(appRoutes.forgotPassword.makeUrl())
            }}
          >
            {t('auth.signin.lostPassword')}
          </Button>
        ) : (
          <></>
        )}

        <ButtonLoginContainer>
          <Button
            $fluid
            type={'submit'}
            $variant={'secondary'}
            $loading={doingLogin}
            disabled={doingLogin || loggedIn || !emailValided || !pw}
            onClick={() => signInUtilities.handleSignIn(email, pw)}
            data-testid={'signin-submit'}
          >
            {loggedIn ? 'Success' : t('auth.signin.ctaLoginViaEmail')}
          </Button>
        </ButtonLoginContainer>
      </>
    )
  }

  const formContainer = () => {
    return (
      <>
        <Row align='center' className={'my-1 form-divider'}>
          <Col>
            <Divider />
          </Col>
          <Col width={'fit-content'} className={'text-secondary text-center'}>
            {loginLayout === 'generic'
              ? 'OR'
              : t('auth.signin.alreadyHaveAnAccount')}
          </Col>
          <Col>
            <Divider />
          </Col>
        </Row>
        {formBody()}
      </>
    )
  }

  // Bridge By URL
  if (bbuPartnerName && loginLayout === 'bridge-by-url') {
    return (
      <SignInBridgeByUrl
        partnerName={bbuPartnerName}
        formComponent={formBody()}
        links={links}
      />
    )
  }

  // All other loginLayouts
  return (
    <AuthContainer
      header={
        <Typography className={'custom-class-theme'} tagName='h2'>
          Welcome back
        </Typography>
      }
      slot={
        <div style={{ position: 'relative' }}>
          {doingSingin && <Loader $active $dimmer />}
          <ButtonProvider
            provider={'Google'}
            scope={'SignIn'}
            onBeforeAuth={() => {
              setDoingSignin(true)
            }}
            onAuthError={showGenericError}
          />
          <div className={'mt-1'}>
            <ButtonProvider
              dataWidth={350}
              provider={'Facebook'}
              scope={'SignIn'}
              onBeforeAuth={() => {
                setDoingSignin(true)
              }}
              onAuthError={showGenericError}
            />
          </div>
        </div>
      }
      form={formContainer()}
      links={links}
    />
  )
}

export default SignIn
