import React from 'react'
import { Button } from '@components/Basic/ButtonNbe'
import { Button as ButtonBrand } from '@components/Basic/Button'
import { useTranslation } from 'react-i18next'

interface Props {
  isLoading?: boolean
  isDisabled: boolean
  appName: string
  isStepName: boolean
  action?: 'update' | 'save-new'
  onSubmitRequest?: () => void
}

// This is just a wrapper around our 2 buttons
// in case app name contains either `facebook` or `google`, we show the branded button
// submit action and behaviours are the same
// this only happens on first step, where action is "authorize"
export const SubmitButton: React.VFC<Props> = ({
  isLoading,
  isDisabled,
  appName,
  isStepName,
  action,
  onSubmitRequest,
}) => {
  const { t } = useTranslation()
  const isFacebook = appName.toLowerCase().includes('facebook')
  const isGoogle = appName.toLowerCase().includes('google')

  // in case we are in the first step, we need to show a branded version of the button for google or facebook
  // in future we might want to handle other branded button variants, in case we'll add new partners
  const showBrandedOAuthButton = (isGoogle || isFacebook) && !isStepName
  const brandedButtonStyle = isGoogle
    ? 'google'
    : isFacebook
    ? 'facebook'
    : 'primary'

  return showBrandedOAuthButton ? (
    <ButtonBrand
      type={'submit'}
      disabled={isDisabled}
      $loading={isLoading}
      $variant={brandedButtonStyle}
      $fluid
    >
      {t('common.authorize')}
    </ButtonBrand>
  ) : (
    <Button
      type={'submit'}
      $variant={action === 'save-new' ? 'secondary' : 'primary'}
      $size={'standard'}
      disabled={isDisabled}
      $loading={isLoading}
      onClick={onSubmitRequest}
    >
      {action === 'update'
        ? t('integrationAuthPopup.integrationUpdate')
        : action === 'save-new'
        ? t('integrationAuthPopup.integrationSaveAsNew')
        : isStepName
        ? t('common.continue')
        : t('common.authorize')}
    </Button>
  )
}
