/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getIntegration = /* GraphQL */ `
  query GetIntegration($id: ID!) {
    getIntegration(id: $id) {
      id
      platformId
      name
      icon
      coordinates
      isBeta
      isVisible
      createdAt
      updatedAt
    }
  }
`;
export const listIntegrations = /* GraphQL */ `
  query ListIntegrations(
    $filter: ModelIntegrationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIntegrations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        platformId
        name
        icon
        coordinates
        isBeta
        isVisible
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFbAppStoreStatus = /* GraphQL */ `
  query GetFbAppStoreStatus($id: ID!) {
    getFbAppStoreStatus(id: $id) {
      id
      status
      owner
      crmId
      createdAt
      updatedAt
    }
  }
`;
export const listFbAppStoreStatuss = /* GraphQL */ `
  query ListFbAppStoreStatuss(
    $filter: ModelFbAppStoreStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFbAppStoreStatuss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        status
        owner
        crmId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
