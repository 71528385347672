import * as yup from 'yup'
import all from 'public/locales/en-US.json'
import { yupRequiredBoolean } from '@app/utils/validators/yupCustomValidators'

type SignupFormValues = {
  firstName: string
  lastName: string
  email: string
  privacyConsent: boolean
  industry: string
  couponCode?: string
}

export const validationSchema = yup.object().shape({
  firstName: yup.string().required(all.common.genericFieldIsRequired),
  lastName: yup.string().required(all.common.genericFieldIsRequired),
  email: yup.string().email().required(all.common.genericFieldIsRequired),
  privacyConsent: yupRequiredBoolean(all.common.genericFieldIsRequired),
})

export const initialFormValues: SignupFormValues = {
  firstName: '',
  lastName: '',
  email: '',
  privacyConsent: false,
  industry: '',
}

// SIGN UP APPSUMO TYPES

type SignupFormValuesAppsumo = {
  firstName: string
  lastName: string
  email: string
  industry: string
  couponCode: string
  privacyConsent: boolean
}

export const validationSchemaAppsumo = validationSchema.shape({
  couponCode: yup.string().required(all.common.genericFieldIsRequired),
  privacyConsent: yupRequiredBoolean(all.common.genericFieldIsRequired),
})

export const initialFormValuesAppsumo: SignupFormValuesAppsumo = {
  firstName: '',
  lastName: '',
  email: '',
  industry: '',
  couponCode: '',
  privacyConsent: false,
}
