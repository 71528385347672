import React, { ReactNode } from 'react'
import { ContentWrapperStyled, HeaderTextStyled, SubTextStyled } from './styled'

interface ContentProps {
  section?: ReactNode
  headerText?: string
  subText?: string
}

export const Content: React.FC<ContentProps> = ({
  section,
  headerText,
  subText,
}) => {
  return (
    <ContentWrapperStyled>
      {section}
      <HeaderTextStyled>{headerText}</HeaderTextStyled>
      <SubTextStyled>{subText}</SubTextStyled>
    </ContentWrapperStyled>
  )
}
