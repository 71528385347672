import {
  AlertProgressBarStyled,
  AlertProgressBarWrapper,
} from '@components/Alert/styled'
import React from 'react'

interface AlertProgressBarProps {
  timer: number
}
export const AlertProgressBar: React.FC<AlertProgressBarProps> = ({
  timer,
}) => {
  return (
    <AlertProgressBarStyled>
      <AlertProgressBarWrapper progress={timer} />
    </AlertProgressBarStyled>
  )
}
