import styled, { createGlobalStyle, css } from 'styled-components'
import { mediaIframeOnly } from '@app/styles/iframeUtils'

interface BackgroundProps {
  backgroundUrl?: string
}
export const AuthBackgroundStyled = styled.div<BackgroundProps>`
  background-image: ${(props) => `url("${props.backgroundUrl}")`};
  width: 100%;
  background-size: 90%;
  background-repeat: no-repeat;
  background-position: center center;
  @media screen and (max-width: 801px) {
    background-image: none;
  }

  ${mediaIframeOnly(css`
    display: flex;
    align-items: center;
    min-height: 100vh;
  `)}

  &::after {
    background-color: #23278a;
    mix-blend-mode: lighten;
  }
`

export const ButtonLoginContainer = styled.div`
  margin-top: 2rem;
  @media screen and (max-height: 695px) {
    margin-top: 15px !important;
    margin-bottom: 5px !important;
  }
`
export const GlobalLoginStyle = createGlobalStyle`
    div[hidden] {
      display: none!important;
    }
  
    .auth-container{
      margin: 0 !important;
      width: 100% !important;
    }
     
    amplify-authenticator,
    .auth-slot-container {
      --container-display: block !important;
      .auth-container{
        display: block !important;
      }
      
      .login-slot,
      [slot="sign-in"],
      [slot="forgot-password"],
      [slot="require-new-password"],
      [slot="confirm-sign-in"] {
        background: linear-gradient(128deg, #0095C7 32.52%, #0083AF 109.23%);
        min-height: calc(100vh);
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      
      @media screen and (max-width: 900px) and (min-width: 800px) {
        .resource-wrapper{
          min-width: auto;
          max-width: 44% !important;
        }
      }
      /* Avoid scroll bar */
      @media screen and (max-height: 695px) {
        .sign-in-form__wrapper{
          min-height: 400px !important;
        }
        .sign-in-form__body {
          & div[type]{
            margin-top: 5px !important;
            margin-bottom: 5px !important;
          }
        }
        .sign-in-form__head .divider{
          margin: 0 !important;
        }
        .sign-in-form__head img{
          width: 100px;
        }
        #form-login-button{
          margin: 0 !important;
        }
        .sign-in-form__footer{
          margin-top: 0 !important;
          & > div{
            padding: 0;
          }
        }
        .form-divider{
          margin: 0 !important;
        }
      }
      @media screen and (max-width: 800px) {
        .resource-wrapper{
          min-width: 460px;
        }
        .login-slot,
        [slot="sign-in"],
        [slot="forgot-password"] {
          padding: 0;
          min-height: auto;
          background: white;
        }
      }
    }
`
