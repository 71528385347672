import styled from 'styled-components'

const borderStyle = '1px solid #979797'

export const InputDomainStyled = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  border-radius: 4px;
  border: ${borderStyle};

  & input {
    padding: 0 0.5rem;
    text-align: left;
    border: none;
    min-width: 1px; /* to make entire input on one line */
  }

  & label {
    background: #f4f6f8;
    border-radius: 4px;
    padding: 0.5rem;
    border: ${borderStyle};

    &:first-child {
      margin: -1px 0 -1px -1px;
    }

    &:nth-child(3) {
      margin: -1px -1px -1px 0;
    }
  }
`
