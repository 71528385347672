import styled from 'styled-components'

export const StateIconStyled = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;

  span {
    flex: 1;
    border-bottom: 1px dashed #979797;
  }
`
