import React from 'react'
import { EmailReceiptStyled } from '@features/nbee/EmailReceipt/styled'
import { InputFeedback } from '@components/Basic/InputFeedback'
import { useField } from 'formik'
import { InputField } from '@components/Form/InputField'
import { useTranslation } from 'react-i18next'

interface Props {
  disabled?: boolean
  error?: string | null
}

const { FieldGroup, FieldRow } = EmailReceiptStyled

export const FieldSubject: React.VFC<Props> = ({ disabled, error }) => {
  const { t } = useTranslation()
  const [field, meta, helpers] = useField<string>(
    'settings.emailReceipt.subject'
  )

  const handleBlur = () => {
    helpers.setTouched(true)
  }

  return (
    <FieldGroup>
      <FieldRow>
        <label>{t('nbee.emailReceipt.labelSubject')}</label>
        <InputField
          {...field}
          type={'text'}
          placeholder={t('nbee.emailReceipt.placeholderSubject')}
          disabled={disabled}
          onBlur={handleBlur}
        />
      </FieldRow>

      <FieldRow>
        <div />
        {error ? <InputFeedback $status={{ error }} /> : null}
      </FieldRow>
    </FieldGroup>
  )
}
