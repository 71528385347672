export type SkeletonAllowedElement =
  | 'button'
  | 'label'
  | 'select'
  | 'smart-select'
  | 'input'
  | 'toggle'

export type SkeletonSizes = {
  width: string
  height: string
  marginBottom: string
  marginTop: string
}

export const skeletonElementSizes: Record<
  SkeletonAllowedElement,
  SkeletonSizes
> = {
  button: {
    width: '140px',
    height: '40px',
    marginBottom: '0',
    marginTop: '0',
  },
  label: {
    width: '200px',
    height: '22px',
    marginBottom: '5px',
    marginTop: '0',
  },
  select: {
    width: '100%',
    height: '40px',
    marginBottom: '0',
    marginTop: '0',
  },
  'smart-select': {
    width: '100%',
    height: '43px',
    marginBottom: '0',
    marginTop: '0',
  },
  input: {
    width: '100%',
    height: '43px',
    marginBottom: '1rem',
    marginTop: '8px',
  },
  toggle: {
    width: '120px',
    height: '30px',
    marginBottom: '0',
    marginTop: '0',
  },
}
