import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div<{ percentage: number }>`
  background: #c6e4f4;
  height: 10px;
  width: 100%;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  overflow: hidden;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: ${({ percentage }) => percentage}%;
    background-color: ${({ theme }) => theme.color.primary};
    transition: width 0.4s ease-in-out;
  }
`

interface Props {
  percentage: number
}

export const ProgressBar: React.VFC<Props> = ({ percentage }) => {
  return <Wrapper percentage={percentage} />
}
