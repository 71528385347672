import React from 'react'
import { getCustomErrorCognitoMessage } from '../../../utils/cognitoErrors'
import { Auth } from 'aws-amplify'
import { Hub } from '@aws-amplify/core'
import {
  AUTH_STATE_CHANGE_EVENT,
  AuthState,
  UI_AUTH_CHANNEL,
} from '@aws-amplify/ui-components'
import AuthService from '../../../services/AuthService'
import { sendToast } from '../../../store/actions/ApplicationConfigurationActions'
import axios from 'axios'
import { CognitoErrorResponse } from 'LeadsBridgeApp'
import t from 'public/locales/en-US.json'

export default class SignInUtilities {
  setEmailError: any
  setPwError: any
  setDoingLogin: any
  setLoggedIn: any
  dispatch: any

  constructor(
    setEmailError: any,
    setPwError: any,
    setDoingLogin: any,
    setLoggedIn: any,
    dispatch: any
  ) {
    this.setEmailError = setEmailError
    this.setPwError = setPwError
    this.setDoingLogin = setDoingLogin
    this.setLoggedIn = setLoggedIn
    this.dispatch = dispatch
  }

  handleSignIn = (email: string, pw: string, temp_password?: string) => {
    const password = temp_password || pw
    // console.log('password:', password);
    const username = email
    if (email === '') {
      this.setEmailError(t.auth.signin.error.emailError)
      return
    }
    if (password === '') {
      this.setPwError(t.auth.signin.error.passwordError)
      return
    }

    this.setDoingLogin(true)

    Auth.signIn({
      username,
      password,
      validationData: [],
    })
      .then((user) => {
        // console.log('user challange name', user.challengeName);
        switch (user.challengeName) {
          case 'SMS_MFA':
          case 'SOFTWARE_TOKEN_MFA':
            Hub.dispatch(UI_AUTH_CHANNEL, {
              event: AUTH_STATE_CHANGE_EVENT,
              message: AuthState.ConfirmSignIn,
              data: user,
            })
            break
          case 'NEW_PASSWORD_REQUIRED':
            Hub.dispatch(UI_AUTH_CHANNEL, {
              event: AUTH_STATE_CHANGE_EVENT,
              message: AuthState.ResetPassword,
              data: user,
            })
            break
          case 'MFA_SETUP':
            // TODO totp is optional in our system
            // This happens when the MFA method is TOTP
            // The user needs to setup the TOTP before using it
            // More info please check the Enabling MFA part
            // Auth.setupTOTP(user);
            break
          default:
            this.dispatch(
              sendToast({
                title: 'Success',
                message: 'Login successful. Welcome back!',
                color: 'positive',
              })
            )
            this.setLoggedIn(true)
            AuthService.onLogin(false, this.dispatch)
        }
      })
      .catch(async (err: CognitoErrorResponse) => {
        // console.log('code', err.code);
        switch (err.code) {
          case 'UserNotConfirmedException':
            Hub.dispatch(UI_AUTH_CHANNEL, {
              event: AUTH_STATE_CHANGE_EVENT,
              message: AuthState.ConfirmSignUp,
              data: null,
            })
            break
          case 'PasswordResetRequiredException':
            this.dispatch(
              sendToast({
                title: 'Error',
                messages: [getCustomErrorCognitoMessage(err)],
                color: 'negative',
              })
            )
            Hub.dispatch(UI_AUTH_CHANNEL, {
              event: AUTH_STATE_CHANGE_EVENT,
              message: AuthState.ResetPassword,
              data: null,
            })
            break
          case 'NotAuthorizedException':
            this.setPwError(getCustomErrorCognitoMessage(err))
            this.setEmailError('')
            // dispatch(sendToast({title: 'Error', messages: [err.message], color: 'negative'}));
            break
          case 'UserNotFoundException':
            // console.log('Calling handleLegacyUser');
            await axios
              .post(
                process.env.REACT_APP_V1_API + '/auth/v2/handleLegacyUser',
                {
                  username: username,
                  password: password,
                }
              )
              .then(async (res) => {
                // console.log('Legacy User verified');
                this.handleSignIn(email, pw)
              })
              .catch((err2) => {
                if (err2.response && err2.response.status === 403) {
                  this.handleSignIn(
                    email,
                    pw,
                    err2.response.data.temporary_password
                  )
                } else {
                  this.setPwError(getCustomErrorCognitoMessage(err))
                  this.setEmailError('')
                }
              })
            // dispatch(sendToast({title: 'Error', messages: [err.message], color: 'negative'}));
            break
          default:
            this.dispatch(
              sendToast({
                title: 'Error',
                messages: [err.message],
                color: 'negative',
              })
            )
        }
      })
      .finally(() => {
        this.setDoingLogin(false)
      })
  }

  static resourceItems = (): any[] => {
    return [
      {
        head: 'RESOURCES / WEBINAR',
        title:
          'Discussing Lead Optimization with Meta: How to bring in higher quality leads with your lead generation campaigns',
        text: '',
        buttonLabel: 'Sign up now',
        buttonLink:
          'https://lp.leadsbridge.com/discussing-lead-optimization-with-meta',
      },
      {
        head: 'RESOURCES / GUIDES',
        title:
          'Google Lead Form Ads: The Complete, Always-Updated Guide to Google Lead Generation',
        text: 'Every business owner already knows that putting a good lead generation strategy in place helps to target high-quality prospects that are more likely to convert into customers. Like Facebook Lead Ads and LinkedIn Lead Gen Forms, Google has also developed its own native lead generation ads, namely Google Lead Form Extensions and YouTube TrueView for Action Form Ads. ',
        buttonLabel: 'Read more',
        buttonLink: 'https://leadsbridge.com/blog/guides/google-lead-form-ads/',
      },
      {
        type: 'blog',
        head: 'RESOURCES / GUIDES',
        title: 'How to setup Facebook Conversions API – Benefits and more',
        text: 'Advertising is all about timing. You need to reach out at the perfect time, on the ideal platform, and to the right people. It’s an art to say the least, and one that not a whole lot of people have mastered. This is where Facebook Conversions API comes in handy.',
        buttonLabel: 'Read more',
        buttonLink:
          'https://leadsbridge.com/blog/guides/how-to-setup-facebook-conversions-api/',
      },
      // {
      //   type: 'blog',
      //   head: 'RESOURCES / GUIDES',
      //   title:
      //     'LinkedIn Matched Audiences: improve the efficiency of your retargeting campaigns',
      //   text: 'LinkedIn Matched Audiences is a tool that allows businesses to build audiences from email lists, website traffic, and high-value LinkedIn accounts. With it, you can upload a list of companies or contacts you may like to target, retarget people, integrate contacts from a third-party system, and create a lookalike audience.',
      //   buttonLabel: 'Read more',
      //   buttonLink:
      //     'https://leadsbridge.com/blog/guides/linkedin-matched-audiences/',
      // },
    ]
  }
}
