import React from 'react'
import { Button } from '@components/Basic/Button'
import ReactTooltip from 'react-tooltip'
import { Link, matchPath } from 'react-router-dom'
import { appRoutes, bridgeByUrlPartnersPaths } from '@app/routes'
import { BbuRouteParams } from 'LeadsBridgeApp'
import { BbuPartnersName } from '@app/enums/bridgeByUrl'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { AuthScope } from '@components/ButtonProvider'

const Message = styled.div`
  color: ${({ theme }) => theme.color.error};
  font-size: 0.9rem;
`

interface Props {
  scope: AuthScope
}

export const ButtonErrorUi: React.VFC<Props> = ({ scope }) => {
  const { t } = useTranslation()
  const bbuMatchUrl = matchPath<BbuRouteParams>(location.pathname, {
    path: bridgeByUrlPartnersPaths,
    exact: true,
  })

  const isFbBbu =
    bbuMatchUrl?.params?.partnerName === BbuPartnersName.facebook ||
    bbuMatchUrl?.params?.partnerName === BbuPartnersName.facebookConversionLeads

  const signupBbuRoute = bbuMatchUrl?.params
    ? appRoutes.signupBbu.makeUrl({
        source: bbuMatchUrl.params.source || '',
        destination: bbuMatchUrl.params.destination || '',
      })
    : undefined

  // In case of error and we are in signup page, we just hide everything without showing any message.
  // This because we use the signup bbu page as fallback in case user won't disable the ad-blocker
  // and in the bbu signup page we don't want to be redundant
  if (scope === 'SignUp') {
    return null
  }

  return (
    <>
      {/* In case of Facebook BBU we give the user the possibility to navigate to signup bbu and register a new account with email (no social)  */}
      {isFbBbu && signupBbuRoute ? (
        <div>
          <Button $fluid $variant={'facebook'} disabled>
            {t('auth.signin.ctaWithPartner', { partnerName: 'Facebook' })}
          </Button>
          <Message>
            <Trans
              i18nKey={'auth.fbButtonLoadErrorBbu'}
              components={{
                a: <Link to={signupBbuRoute} />,
              }}
            />
          </Message>
        </div>
      ) : (
        <div data-tip data-for={'fb-sdk-error'}>
          <Button $fluid $variant={'facebook'} disabled>
            {t('auth.signin.ctaWithPartner', { partnerName: 'Facebook' })}
          </Button>
          <ReactTooltip id={'fb-sdk-error'}>
            {t('auth.fbButtonLoadError')}
          </ReactTooltip>
        </div>
      )}
    </>
  )
}
