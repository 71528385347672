import React, { useEffect } from 'react'
import {
  InputSmartSelect,
  SelectValue,
} from '@components/Form/InputSmartSelect'
import { IntegrationFormField, IntegrationFormValues } from 'Nbee'
import { useField, useFormikContext } from 'formik'
import {
  InputFeedback,
  InputFeedbackStatus,
} from '@components/Basic/InputFeedback'
import { Loader } from '@components/Basic/Loader'
import { BiRefresh } from 'react-icons/bi'
import { TinyTextualButton } from '@components/Basic/TinyTextualButton'
import { useTranslation } from 'react-i18next'

interface Props {
  index: number
  isLoading?: boolean
  apiHasError: boolean
}

export const CredentialFieldSmartSelect: React.FC<Props> = ({
  index,
  isLoading,
  apiHasError,
}) => {
  const { t } = useTranslation()
  const [{ value: field, onBlur }, { touched, error }, { setValue }] =
    useField<IntegrationFormField>(`credentials.${index}`)
  const { values, validateForm, setFieldValue } =
    useFormikContext<IntegrationFormValues>()

  const selectOptions: SelectValue[] = (field.selectValues || []).map(
    (option) => ({
      label: typeof option === 'string' ? option : option.text,
      value: typeof option === 'string' ? option : option.id,
    })
  )
  const currentFieldValue =
    field.value || field.value === 0
      ? selectOptions.find((option) => option.value === field.value)
      : undefined

  // When the field is required and it has only one option available,
  // it pre-select that option and show the field in a read-only mode (disabled).
  const isSingleValue = field.selectValues?.length === 1

  const handleFreshContentRequest = () => {
    setTimeout(() => {
      // just to be sure formik state is updated
      setFieldValue(`ui.triggerSettingsUpdate`, new Date().getTime())
    }, 100)
  }

  useEffect(() => {
    if (!field.isRequired) {
      return
    }
    // if we have a value there's nothing to set
    if (currentFieldValue && currentFieldValue.value) {
      return
    }

    if (isSingleValue) {
      // ok to cast as any, since we checked the existance with isSingleValue
      const singleDefaultValue = (field.selectValues as any)[0].id
      setValue({
        ...field,
        value: singleDefaultValue,
      })
      if (field.hasChild) {
        handleFreshContentRequest()
      }

      setTimeout(() => {
        // we need to re-validate the form since pre-selected values might not be registered
        validateForm()
      }, 200)
    }
  }, [isSingleValue])

  // if value is not present enymore in the list we need to remove it from formik state
  // example we had a dropdown child that returns different options depending on parent choice
  useEffect(() => {
    const availableOptionKeys = selectOptions.map((o) => o.value)
    const currentValue = currentFieldValue?.value as string
    if (currentValue && !availableOptionKeys.includes(currentValue)) {
      setValue({
        ...field,
        value: '',
      })
    }
  }, [selectOptions])

  // error could a string or an object with value: string
  const parsedError = error ? (error as any).value || error : null
  const fieldValidationStatus: InputFeedbackStatus | undefined =
    touched && error
      ? {
          error: parsedError,
        }
      : undefined

  const isRefreshingFromApi = Boolean(
    values.ui?.refresh && values.ui.refresh.includes(field.id)
  )

  return (
    <div style={{ position: 'relative' }}>
      {isRefreshingFromApi && !apiHasError && <Loader $active $dimmer />}
      <InputSmartSelect
        isClearable={false}
        initialValues={selectOptions}
        defaultValue={currentFieldValue}
        key={currentFieldValue?.value}
        isDisabled={isSingleValue || (field.hasChild && isLoading)}
        tooltipMessage={
          isSingleValue && !isLoading
            ? t('common.selectDisabledWithSingleOption')
            : undefined
        }
        onSelect={(selectedValue) => {
          const newValue = selectedValue as SelectValue | null
          setValue({
            ...field,
            value: newValue?.value || '',
          })
          if (field.hasChild) {
            handleFreshContentRequest()
          }
        }}
        onBlur={onBlur}
      />

      {/* Following block shows a grid with validation error message and refresh icon */}
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr auto',
          gap: '1rem',
        }}
      >
        {fieldValidationStatus ? (
          <InputFeedback $status={fieldValidationStatus} />
        ) : (
          <div />
        )}
        {field.refreshable ? (
          <div style={{ textAlign: 'right' }}>
            <TinyTextualButton
              onClick={() => {
                // we prepare the request to get fresh content (not cached) from api for current field id
                setFieldValue('ui.refresh', [field.id])
                // we send the api request
                handleFreshContentRequest()
              }}
              disabled={isLoading}
            >
              <BiRefresh /> {t('nbee.bridgeBuilder.refreshDropdownListCta')}
            </TinyTextualButton>
          </div>
        ) : null}
      </div>
    </div>
  )
}
