import React, { forwardRef } from 'react'
import {
  DropDownItemStyled,
  DropDownStyled,
} from '@features/nbee/WelcomeMessageForm/styled'
import { VariableObjType } from '@features/nbee/WelcomeMessageForm'

interface DropdownProps {
  items: VariableObjType[]
  onSelect: (item: VariableObjType) => void
  isVisible: boolean
  position: { x: number; y: number }
  highlightedIndex: number
  setHighlightedIndex: (index: number) => void
}

export const Dropdown = forwardRef<HTMLDivElement, DropdownProps>(
  (
    {
      items,
      onSelect,
      isVisible,
      position,
      highlightedIndex,
      setHighlightedIndex,
    },
    ref
  ) => {
    const handleItemClick = (item: VariableObjType) => {
      onSelect(item)
    }

    const handleMouseEnter = (index: number) => {
      setHighlightedIndex(index)
    }

    const handleMouseLeave = () => {
      setHighlightedIndex(0)
    }

    return (
      <DropDownStyled
        ref={ref}
        isVisible={isVisible}
        position={position}
        tabIndex={0}
      >
        {items.map((item, index) => (
          <DropDownItemStyled
            key={item.id}
            onClick={() => handleItemClick(item)}
            isHighlighted={
              highlightedIndex === index || (!highlightedIndex && index === 0)
            }
            index={index}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}
          >
            <span style={{ fontWeight: 600 }}>[{item.id}]</span>
            <span style={{ color: 'grey', textAlign: 'right' }}>
              {item.label}
            </span>
          </DropDownItemStyled>
        ))}
      </DropDownStyled>
    )
  }
)
