import { useState, useEffect } from 'react'
import * as yup from 'yup'
import { emailReceiptValidationSchema } from '@features/nbee/EmailReceipt/validation'
import { BridgeFormValues, EmailReceiptSettings } from 'Nbee'

interface EmailReceiptValidationErrors {
  subject: string | null
  recipients: string | null
}

export const useEmailReceiptValidation = (values: BridgeFormValues) => {
  const [errors, setErrors] = useState<EmailReceiptValidationErrors>({
    subject: null,
    recipients: null,
  })

  useEffect(() => {
    const isActive = values.settings?.emailReceipt?.active

    if (isActive === false) {
      setErrors({
        subject: null,
        recipients: null,
      })
      return
    }

    const validateField = async (
      field: keyof EmailReceiptSettings,
      value: string | string[]
    ) => {
      try {
        await emailReceiptValidationSchema.validateAt(field, { [field]: value })
        setErrors((prev) => ({ ...prev, [field]: null }))
      } catch (error) {
        if (error instanceof yup.ValidationError) {
          setErrors((prev) => ({
            ...prev,
            [field]: (error as yup.ValidationError).message,
          }))
        }
      }
    }

    const emailReceipt = values.settings?.emailReceipt
    if (emailReceipt) {
      validateField('subject', emailReceipt.subject || '')
      validateField('recipients', emailReceipt.recipients || [])
    }
  }, [values.settings?.emailReceipt])

  return errors
}
