import {
  AppConfigurationType,
  BridgeFormValues,
  PartialConfiguredApp,
  EmailReceiptSettings,
} from 'Nbee'
import * as yup from 'yup'
import t from 'public/locales/en-US.json'
import { emailValidator } from '@app/utils/validators/RegExp'
import { emailReceiptValidationSchema } from '@features/nbee/EmailReceipt/validation'

type ConfiguredAppErrors = Record<keyof PartialConfiguredApp, string>
type BridgeFormValuesErrors = Record<keyof BridgeFormValues, string> & {
  ui?: {
    source?: {
      requiredSettingFields?: Record<string, string>[]
    }
    destination?: {
      requiredSettingFields?: Record<string, string>[]
    }
  }
  emailReceiptErrors?: Record<string, string>
}

type EmailReceiptErrors = {
  recipients?: string
  subject?: string
}

const validateAppConfiguration = (configuredApp: PartialConfiguredApp) => {
  const errors: Partial<ConfiguredAppErrors> = {}
  if (!configuredApp.appId) {
    errors.appId = t.nbee.bridgeBuilder.validation.appSelector
  }

  if (!configuredApp.integrationId) {
    errors.integrationId = t.nbee.bridgeBuilder.validation.integrationSelector
  }

  return errors
}

const validateAppSettings = (
  values: BridgeFormValues,
  type: AppConfigurationType
) => {
  // build object structure as same as form values, starting from ui.type.required

  const errors = {
    [type]: {
      settings: [] as string[],
    },
  }

  // get array of all settings
  const allSettingsFields =
    (values.ui &&
      values.ui[type] &&
      values.ui[type]?.allRetrivedSettingFields) ||
    []
  // cycle through it to see if required onces have correspondant key in form values
  allSettingsFields.forEach((setting, index) => {
    // reset validation
    errors[type].settings[index] = ''

    // getting the value fo the field
    const formSettings = values[type]?.settings || []
    const fieldSettingValue = formSettings.find(
      (item) => item.key === setting.id
    )?.value

    // validate type also for not required settings
    if (
      setting.type === 'email' &&
      fieldSettingValue &&
      !emailValidator(fieldSettingValue as string)
    ) {
      errors[type].settings[index] = 'Invalid email format'
    }

    // validate type of array of emails
    if (setting.type === 'tags' && setting.format === 'email') {
      const allArrayItemsAreValidEmail = (
        (fieldSettingValue as string[]) || []
      ).every((email) => emailValidator(email))
      errors[type].settings[index] = !allArrayItemsAreValidEmail
        ? 'Invalid email format'
        : ''
    }

    // if setting is not required we are ok to proceed
    if (!setting.required) {
      return
    }

    // here we need to control if `fieldSettingValue` is falsy (which includes false for toggle) or is an empty array
    const isEmptyArray =
      Array.isArray(fieldSettingValue) && fieldSettingValue.length === 0
    if (!fieldSettingValue || isEmptyArray) {
      errors[type].settings[index] = `${setting.label} is required`
    } else {
      errors[type].settings[index] = ''
    }
  })

  // check if we have some errors, otherwise return an empty object
  // this because we cannot filter-out empty items or we break array length and
  // error index will not match the same index of setting fields
  const hasSomeErrors = errors[type].settings.some((s) => !!s)
  return hasSomeErrors ? errors : {}
}

export const validateNbeeForm = (values: BridgeFormValues) => {
  const errors: Partial<BridgeFormValuesErrors> = {}

  if (!values.name) {
    errors.name = t.nbee.bridgeBuilder.validation.name
  }

  const sourceErrors = validateAppConfiguration(values.source)
  const destinationErrors = validateAppConfiguration(values.destination)
  // validating required app settings
  const sourceSettingErrors = validateAppSettings(values, 'source')
  const destinationSettingErrors = validateAppSettings(values, 'destination')

  const computedErrors = {
    ...errors,
    source: Object.keys(sourceErrors).length ? sourceErrors : undefined,
    destination: Object.keys(destinationErrors).length
      ? destinationErrors
      : undefined,
    ...sourceSettingErrors,
    ...destinationSettingErrors,
  }

  if (!computedErrors.source) {
    delete computedErrors.source
  }
  if (!computedErrors.destination) {
    delete computedErrors.destination
  }

  return computedErrors
}
