import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

interface Props {
  errors: string[]
}

const UlErrorStyled = styled.ul`
  text-align: left;
  font-size: 0.9rem;
  list-style: none;
  padding: 0;
  margin-top: 0.5rem;
`

const LiErrorStyled = styled.li<{ isChecked?: boolean }>`
  color: ${({ isChecked }) => (isChecked ? '#0c783a' : '#cc2029')};
  display: flex;
  line-height: 1.3;

  &:before {
    content: ${({ isChecked }) => (isChecked ? "'✓'" : "'𐄂'")};
    display: inline-block;
    line-height: 1.1;
    padding-left: 0.1rem;
    width: 1.2rem;
  }
`

export const CustomInputError: React.FC<Props> = ({ errors }) => {
  const [errorsCopy, setErrorsCopy] = useState<string[]>()

  useEffect(() => {
    setErrorsCopy(errors)
  }, [])

  return (
    <div>
      <UlErrorStyled>
        {errorsCopy?.map((error, idx) => {
          const isChecked = !errors.includes(error)
          // displays only the errors in the array that do NOT include the 'Required' string.
          // If we did not do this, we would see Required in the list of errors, repeated multiple times.
          // if in the future we will change the 'Required' message in formConfig.ts, we will need to change it here as well.
          const isRequired = error.includes('Required')
          if (!isRequired) {
            return (
              <LiErrorStyled isChecked={isChecked} key={idx}>
                {error}
              </LiErrorStyled>
            )
          } else {
            return null
          }
        })}
      </UlErrorStyled>
    </div>
  )
}
