import React from 'react'
import { useField } from 'formik'
import { Checkbox, CheckboxProps } from '@components/Form/Checkbox'

export const FormikInputCheckbox: React.FC<CheckboxProps> = ({
  name,
  ...rest
}) => {
  const [field, meta] = useField({
    name,
  })

  return (
    <Checkbox
      {...rest}
      {...field}
      $status={{
        ...rest.$status,
        error: (meta.touched && meta.error) || undefined,
      }}
    />
  )
}
