import React from 'react'
import { InputField } from '@components/Form/InputField'
import { useField } from 'formik'
import styled from 'styled-components'

export const IntegrationName = styled.div`
  display: inline-grid;
  align-items: center;
  grid-template-columns: 1fr auto;
  gap: 0.2rem;
  padding: 0.67857143em 1em; /* to emulate same input sizing */
  > * {
    padding: 0.3rem 0.5rem;
  }

  svg {
    cursor: pointer;
  }
`

interface Props {
  appName: string
}

export const IntegrationNameInput: React.FC<Props> = ({ appName }) => {
  const [{ value, onBlur, onChange, name }, { touched, error }] =
    useField<string>(`name`)

  return (
    <div>
      <InputField
        name={name}
        type={'text'}
        value={value}
        $hasMargin
        onChange={onChange}
        onBlur={onBlur}
        placeholder={appName ? `ex: ${appName}` : undefined}
        $status={touched && error ? { error } : undefined}
      />
    </div>
  )
}
