import React, { useEffect, useState } from 'react'
import { Button } from '@components/Basic/Button'
import { AuthScope } from '@components/ButtonProvider'
import { useTranslation } from 'react-i18next'

// ATTENTION!
// This google button uses Google legacy SDK, which is going to be deprecated by Q1 2023: DO NOT USE!
// https://leadsbridge.atlassian.net/wiki/spaces/DV/pages/1228242962/Q4+2022+-+Migrate+the+frontend+Signup+flow+to+Google+Identity+Services

interface GoogleButtonProps {
  onTokenRetrived: (token: string) => void
  scope: AuthScope
  onGoogleLegacySdkInitError: () => void
  onGoogleLegacySdkInitSuccess: () => void
}

export const GoogleButtonCustom: React.VFC<GoogleButtonProps> = ({
  onTokenRetrived,
  scope,
  onGoogleLegacySdkInitError,
  onGoogleLegacySdkInitSuccess,
}) => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true)

  const injectGoogleScript = () => {
    if (window.gapi) {
      // google script exists
      setIsDisabled(false)
      return
    }

    const script = document.createElement('script')
    script.src = 'https://apis.google.com/js/platform.js'
    script.async = true
    script.onload = () => {
      window.gapi?.load('auth2', () => {
        gapi.auth2
          .init({
            client_id: process.env.REACT_GOOGLE_API_CLIENT_ID,
            scope: 'profile email openid',
          })
          .then(
            () => {
              setIsDisabled(false)
              onGoogleLegacySdkInitSuccess()
            },
            () => {
              onGoogleLegacySdkInitError()
            }
          )
      })
    }
    script.id = 'google-legacy-script'
    document.body.appendChild(script)
  }

  const onAuthRequest = () => {
    setIsLoading(true)
    const googleauthInstance = gapi.auth2.getAuthInstance()
    googleauthInstance
      .signIn()
      .then((googleUser) => {
        // eslint-disable-next-line camelcase
        const { id_token } = googleUser.getAuthResponse()
        // const profile = googleUser.getBasicProfile()
        onTokenRetrived(id_token)
        setIsLoading(false)
      })
      .catch(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    injectGoogleScript()

    // cleanup function to remove script on component unmount
    // return () => {
    //   const googleScript = document.getElementById('google-legacy-script')
    //   if (googleScript) {
    //     googleScript.remove()
    //   }
    // }
  }, [])

  const buttonLabel: Record<AuthScope, string> = {
    SignUp: t('auth.signup.ctaWithPartner', { partnerName: 'Google' }),
    SignIn: t('auth.signin.ctaWithPartner', { partnerName: 'Google' }),
    NoScope: t('auth.signin.ctaWithPartner', { partnerName: 'Google' }),
  }

  return (
    <Button
      $variant={'google'}
      onClick={onAuthRequest}
      $loading={isLoading}
      disabled={isDisabled}
      $fluid
    >
      {buttonLabel[scope]}
    </Button>
  )
}
