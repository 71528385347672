import { CognitoUserInterface } from '@aws-amplify/ui-components'

// original `cognitoUser` object is too deep to be directly sent to Redux
// so let's create a simplified version of it
export const parseAuthUser = (
  cognitoUser: Partial<CognitoUserInterface>
): CognitoUserInterface => ({
  Session: cognitoUser?.Session,
  authenticationFlowType: cognitoUser?.authenticationFlowType,
  client: {
    endpoint: cognitoUser?.client?.endpoint,
    userAgent: cognitoUser?.client?.userAgent,
  },
  keyPrefix: cognitoUser?.keyPrefix,
  username: cognitoUser?.username,
  userConfirmed: cognitoUser?.userConfirmed,
  challengeName: cognitoUser?.challengeName || '',
  challengeParam: cognitoUser?.challengeParam || {},
  attributes: cognitoUser?.attributes
    ? {
        ...cognitoUser.attributes,
      }
    : {},
})
