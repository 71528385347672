import React from 'react'
import Typography from '@components/Basic/Typography'
import styled from 'styled-components'
import { Icon } from 'semantic-ui-react'
import { Col, Row } from 'react-grid-system'

export const LinkDivisor = styled.div`
  color: rgb(255 255 255 / 12%);
  @media screen and (max-width: 992px) {
    display: none;
  }
`
export const NavStyled = styled(Row)`
  @media screen and (min-width: 992px) {
    display: flex;
    justify-content: center;
  }

  padding: 0;
`
export const LinkStyled = styled.a`
  color: white;
  cursor: pointer;
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
  opacity: 0.7;
  font-size: small;
  &:hover {
    color: white;
    opacity: 1;
    text-decoration: underline;
  }
`
export const Divisor = styled.div`
  width: 100%;
  border-bottom: 2px solid rgb(255 255 255 / 12%);
  margin: 20px 0;
`
export const BadgeStyled = styled(Col)`
  display: flex;
  flex-wrap: nowrap;
  img {
    max-width: 100%;
    height: auto;
  }
`
export const FooterStyled = styled.div`
  text-align: right;
  justify-content: flex-end;
  align-items: center;
  img {
    height: 45px;
    margin-left: 10px;
  }
`

export const ImageStyled = styled.img`
  max-width: 400px;
`

export const ClaimLoginStyled = styled.div`
  h2,
  p {
    color: white;
  }
`
const ClaimLogin: React.FC = () => {
  return (
    <ClaimLoginStyled className={'text-center '}>
      <Typography tagName={'h2'}>
        Let Facebook, Google and LinkedIn communicate with your CRM
      </Typography>
      <Typography>
        We offer you over 380 integrations and tailor-made solutions, depending
        on your needs. Our experts are ready to make it happen.
      </Typography>
      <ImageStyled
        src={
          'https://leadsbridge.s3-us-west-2.amazonaws.com/assets/img/logos/leadsbridge-tools-sync.png'
        }
        width={'100%'}
      />
      <Divisor />
      <FooterStyled>
        <Row align={'center'} justify={'end'}>
          <Col sm={12} md={5} lg={6}>
            <NavStyled nogutter={true}>
              <LinkStyled
                href={'https://leadsbridge.com/tos/'}
                target={'_blank'}
              >
                Terms of Service
              </LinkStyled>
              <LinkDivisor>•</LinkDivisor>
              <LinkStyled
                href={'https://leadsbridge.com/contact-us/'}
                target={'_blank'}
              >
                Contact us
              </LinkStyled>
            </NavStyled>
          </Col>
          <BadgeStyled sm={12} md={7} lg={6}>
            <img
              src={
                'https://leadsbridge.com/wp-content/themes/leadsbridge/img/fb-official-partner.jpg'
              }
            />
            <img
              src={
                'https://www.gstatic.com/partners/badge/images/PremierBadge.png'
              }
            />
          </BadgeStyled>
        </Row>
      </FooterStyled>
    </ClaimLoginStyled>
  )
}

export default ClaimLogin
