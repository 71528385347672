import React, {
  ClipboardEventHandler,
  FocusEventHandler,
  useState,
} from 'react'
import CreatableSelect from 'react-select/creatable'

import {
  CustomControl,
  CustomInput,
  CustomMenu,
  CustomMultiValueRemove,
  CustomOption,
  CustomSingleValue,
  MenuList,
} from './ComponentOverrides'
import { customStyles } from './styled'
import {
  InputFeedback,
  InputFeedbackStatus,
} from '@components/Basic/InputFeedback'

export interface SmartSelectProps {
  initialValues?: string[]
  defaultValue?: string[]
  placeholder?: string
  isClearable?: boolean
  isLoading?: boolean
  isDisabled?: boolean
  onSelect?: (values: string[]) => void
  $status?: InputFeedbackStatus
  onBlur?: FocusEventHandler<HTMLInputElement>
  name?: string
  showDropdownIndicator?: boolean
  defaultLabel?: string
  floatingLabel?: string
  onPaste?: ClipboardEventHandler<HTMLInputElement>
}

const createOptions = (values?: string[]) =>
  (values || []).map((v) => ({
    label: v,
    value: v,
  }))

export const InputSmartCreatableSelect: React.FC<SmartSelectProps> = ({
  initialValues,
  isClearable = true,
  onSelect,
  placeholder,
  defaultValue,
  isLoading,
  isDisabled,
  $status,
  onBlur,
  name,
  showDropdownIndicator,
  defaultLabel,
  floatingLabel,
  onPaste,
}) => {
  // use this object to pass extra props and read them inside custom components (overrides)
  const extraProps = {
    isCreatable: true,
    defaultLabel,
    floatingLabel,
    onPaste,
  }

  const noDropdownIndicator = !showDropdownIndicator
    ? { DropdownIndicator: () => null, IndicatorSeparator: () => null }
    : {}

  return (
    <div>
      <CreatableSelect
        options={createOptions(initialValues)}
        defaultValue={createOptions(defaultValue)}
        components={{
          Option: CustomOption,
          SingleValue: CustomSingleValue,
          Control: CustomControl,
          Menu: CustomMenu,
          MenuList: MenuList,
          MultiValueRemove: CustomMultiValueRemove,
          Input: CustomInput,
          ...noDropdownIndicator,
        }}
        isMulti
        noOptionsMessage={() => 'Type to add a new value'}
        styles={customStyles}
        isClearable={isClearable}
        placeholder={isLoading ? 'Loading...' : placeholder}
        isDisabled={isLoading || isDisabled}
        onBlur={(e) => {
          if (onBlur) {
            onBlur(e)
          }
        }}
        onChange={(selectedValue) => {
          if (onSelect) {
            const typedValues = selectedValue as {
              label: string
              value: string
            }[]
            const newValues = (typedValues || []).map((v) => v.label)
            onSelect(newValues)
          }
        }}
        name={name}
        {...extraProps}
      />
      {$status && <InputFeedback $status={$status} />}
    </div>
  )
}
