import { useLocation } from 'react-router-dom'
import * as queryString from 'query-string'
import { appRoutes } from '@routes/index'

type RedirectToQs = {
  redirectTo?: string
}

// When user lands on our app he's not yet logged until AmplifyAuthenticator is initialiased and set a valid user session
// So everytime user lands on our app he will be taken to /signin
// if it has landed from a specific url (only accessible behind login), we need to append that url as ?redirectTo query string param
// and redirect it again once /signin session has been established
export const useFirstLandRedirect = () => {
  const location = useLocation()

  // this is the query string that will be attached to /signin path
  // eg: if user arrives directy from app.leadsbridge.com/dashboard/?foo=bar, we need to build signin url storing the /dashboard part
  //     in this case ->  /signin?redirectTo=/dashboard%3Ffoo%3Dbar
  const encodedSearch = location.search
    ? encodeURIComponent(location.search)
    : undefined
  const encodedHash = location.hash
    ? encodeURIComponent(location.hash)
    : undefined
  const redirectUrlQuery =
    location.pathname && location.pathname !== '/'
      ? `?redirectTo=${location.pathname}${encodedSearch || ''}${
          encodedHash || ''
        }`
      : ''
  const initialSigninUrl = appRoutes.signin.makeUrl() + redirectUrlQuery

  // parsing query string
  // if user is already logged in, he will reach the `initialSigninUrl` above, so we can parse it and do the proper redirect
  const parsedQueryString = queryString.parse(location.search) as RedirectToQs
  const redirectsToWhenLogged = parsedQueryString.redirectTo || '/'

  return {
    initialSigninUrl,
    redirectsToWhenLogged,
  }
}
