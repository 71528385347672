import { BridgeFormValues, PartialConfiguredApp } from 'Nbee'
import all from 'public/locales/en-US.json'

type ConfiguredAppErrors = Record<keyof PartialConfiguredApp, string>
type BridgeFormValuesErrors = Record<keyof BridgeFormValues, string>

const validateAppConfiguration = (configuredApp: PartialConfiguredApp) => {
  const errors: Partial<ConfiguredAppErrors> = {}
  if (!configuredApp.appId) {
    errors.appId = all.nbee.bridgeBuilder.validation.appSelector
  }

  return errors
}

export const validateBridgeChooserForm = (values: BridgeFormValues) => {
  const errors: Partial<BridgeFormValuesErrors> = {}

  const sourceErrors = validateAppConfiguration(values.source)
  const destinationErrors = validateAppConfiguration(values.destination)

  const computedErrors = {
    ...errors,
    source: Object.keys(sourceErrors).length ? sourceErrors : undefined,
    destination: Object.keys(destinationErrors).length
      ? destinationErrors
      : undefined,
  }

  if (!computedErrors.source) {
    delete computedErrors.source
  }
  if (!computedErrors.destination) {
    delete computedErrors.destination
  }

  return computedErrors
}
