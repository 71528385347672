import React, { useEffect, useState } from 'react'
import CognitoApiService, {
  CognitoProvider,
} from '../../../services/CognitoApiService'
import AuthContainer from '@features/auth/components/AuthContainer'
import Typography from '@components/Basic/Typography'
import { Col, Row } from 'react-grid-system'
import { Link } from 'react-router-dom'
import { appRoutes } from '@routes/index'
import { setLoginLayout } from '@app/store/actions/ApplicationConfigurationActions'
import { useAppDispatch } from '@app/store/hooks'
import { InputField } from '@components/Form/InputField'
import { Button } from '@components/Basic/Button'
import { useTranslation } from 'react-i18next'
const Saml: React.FC = () => {
  const [email, setEmail] = useState<string>('')
  const [isValidEmail, setIsValidEmail] = useState<boolean>(true)
  const [isFormTouched, setIsFormTouched] = useState<boolean>(false)
  const [verifyInProgress, setVerifyInProgress] = useState<boolean>(false)
  const [identityProviders, setIdentityProviders] = useState<CognitoProvider[]>(
    []
  )
  const [apiError, setApiError] = useState<string>()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    dispatch(setLoginLayout('generic'))
  }, [])

  // loading cognito identity providers on component mounts
  useEffect(() => {
    CognitoApiService.listIdentityProviders()
      .then((res) => {
        setIdentityProviders(res.Providers)
      })
      .catch(() => {
        setApiError('Cannot find any identity provider')
      })
  }, [])

  // handle email validation
  useEffect(() => {
    setApiError('')

    if (!email) {
      setIsValidEmail(false)
      return
    }
    setIsValidEmail(true)
    /*
            if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
                setIsValidEmail(true);
            } else {
                setIsValidEmail(false);
            }
             */
  }, [email])

  const handleSubmit = async () => {
    setApiError('')
    setIsFormTouched(true)
    const userEmailDomain = email.split('@').reverse()[0]
    setVerifyInProgress(true)

    // find the identity provider who matches my email domain
    const validProvider = identityProviders.find(
      (provider) => provider.ProviderName === userEmailDomain
    )
    const providerName = validProvider?.ProviderName

    await new Promise((resolve) => {
      setTimeout(resolve, 1680)
    })

    if (!providerName) {
      setApiError('Your email does not match any authorized identity provider')
      setVerifyInProgress(false)
      return
    }

    // redirecting to valid SAML provider screen

    CognitoApiService.describeIdentityProvider(providerName)
      .then((identityProvider) => {
        const redirectUrl = process.env.REACT_APP_SAML_REDIRECT_URL
        sessionStorage.setItem('saml-email', email)
        sessionStorage.setItem('saml-provider', providerName)
        window.location.assign(
          `${process.env.REACT_APP_COGNITO_URL}.amazoncognito.com/oauth2/authorize?identity_provider=${providerName}&redirect_uri=${redirectUrl}&response_type=CODE&client_id=${process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID}&scope=aws.cognito.signin.user.admin email openid profile`
        )
      })
      .catch(() => {
        setApiError('Cannot communicate with SAML provider')
        setVerifyInProgress(false)
      })
  }

  const inputHasError = !isValidEmail || Boolean(apiError)
  const inputErrorMessage =
    inputHasError && isFormTouched
      ? apiError || 'Email format is not valid'
      : undefined

  return (
    <div className={'auth-slot-container'}>
      <div className={'login-slot'}>
        <AuthContainer
          header={
            <>
              <Typography className={'custom-class-theme'} tagName='h2'>
                Login with your domain
              </Typography>
              <Typography className={'text-secondary'}>
                You will be redirected to your domain login screen
              </Typography>
            </>
          }
          slot={<></>}
          onSubmit={handleSubmit}
          form={
            <>
              <InputField
                // error={(!isValidEmail || Boolean(apiError)) && isFormTouched}
                $status={{ error: inputErrorMessage }}
                name={'email'}
                label={t('auth.signin.samlDomain')}
                $fluid
                $hasMargin
                placeholder={t('auth.signin.samlDomainPlaceholder')}
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value)
                }}
                type='text'
                id={'email-input'}
              />

              <Button
                $fluid
                $variant={'primary'}
                $loading={verifyInProgress}
                disabled={
                  verifyInProgress ||
                  !isValidEmail ||
                  Boolean(apiError) ||
                  !email
                }
              >
                Sign in with company account
              </Button>

              <Row>
                <Col className={'text-center my-1'}>
                  <Link
                    to={appRoutes.signin.makeUrl()}
                    className={'text-link text-black'}
                  >
                    Go back to login page
                  </Link>
                </Col>
              </Row>
            </>
          }
          links={[]}
        />
      </div>
    </div>
  )
}
export default Saml
