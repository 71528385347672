import React, { useEffect, useState } from 'react'
import { Button } from '@components/Basic/Button'
import { AuthScope } from '@components/ButtonProvider'
import { useTranslation } from 'react-i18next'
import { initFbScriptOrCreate } from '@components/ButtonProvider/Facebook/facebookApiService'

interface FacebookButtonCustomProps {
  scope: AuthScope
  onTokenRetrived: (token: string) => void
  onSdkLoadError: () => void
}

export const FacebookButtonCustom: React.FC<FacebookButtonCustomProps> = ({
  scope,
  onTokenRetrived,
  onSdkLoadError,
}) => {
  const { t } = useTranslation()
  const [doingLoginFb, setDoingLoginFb] = useState<boolean>(true)

  const handleFbSignin = () => {
    const fb = window.FB
    if (!fb) {
      console.error('No FB sdk found')
      return null
    }
    setDoingLoginFb(true)
    fb.login(
      (response) => {
        const accessToken = response.authResponse?.accessToken
        console.log(accessToken)
        if (accessToken) {
          onTokenRetrived(accessToken)
        }
        setDoingLoginFb(false)
      },
      {
        // the authorized scopes
        scope: 'public_profile,email',
      }
    )
  }

  useEffect(() => {
    initFbScriptOrCreate()
      .then(() => setDoingLoginFb(false))
      .catch((error) => {
        console.log(error)
        onSdkLoadError()
      })
  }, [])

  const buttonLabel: Record<AuthScope, string> = {
    SignUp: t('auth.signup.ctaWithPartner', { partnerName: 'Facebook' }),
    SignIn: t('auth.signin.ctaWithPartner', { partnerName: 'Facebook' }),
    NoScope: t('auth.signin.ctaWithPartner', { partnerName: 'Facebook' }),
  }

  return (
    <Button
      $fluid
      $variant={'facebook'}
      onClick={handleFbSignin}
      $loading={doingLoginFb}
      disabled={doingLoginFb}
    >
      {buttonLabel[scope]}
    </Button>
  )
}
