import React, { useEffect, useState } from 'react'
import { Auth } from 'aws-amplify'
import {
  AUTH_STATE_CHANGE_EVENT,
  AuthState,
  CognitoUserInterface,
  UI_AUTH_CHANNEL,
} from '@aws-amplify/ui-components'
import { Hub, HubCapsule } from '@aws-amplify/core'
import { sendToast } from '../../../../store/actions/ApplicationConfigurationActions'
import { Formik, Form } from 'formik'
import AuthContainer from '@features/auth/components/AuthContainer'
import { Button } from '@components/Basic/ButtonNbe'
import {
  initialFormValues,
  validationSchema,
} from '@features/auth/slots/ChangePassword/formConfig'
import {
  HeaderStyled,
  ButtonGoBackStyled,
  FooterStyled,
  InputFieldStyled,
  InputWrapperStyled,
} from './styled'
import { CustomInputError } from './CustomInputError'
import { ImEye, ImEyeBlocked } from 'react-icons/im'
import { useAppDispatch } from '@app/store/hooks'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { GlobalLoginStyle } from '@features/auth/components/AuthContainer.styled'
import { appRoutes } from '@app/routes'

const ChangePassword: React.FC = () => {
  const [user, setUser] = React.useState<CognitoUserInterface | undefined>()
  const [newPasswordErrors, setNewPasswordErrors] = useState([])
  const [showPwRules, setShowPwRules] = useState(false)
  const [pwIsVisible, setPwIsVisible] = useState({
    oldPassword: false,
    newPassword: false,
    confirmPassword: false,
  })
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const history = useHistory()

  const [doingReset, setDoingReset] = useState<boolean>(false)

  const HandleShowPassword = (name: string) => {
    setPwIsVisible((prevState) => {
      // @ts-ignore
      return { ...pwIsVisible, [name]: !prevState[name] }
    })
  }

  useEffect(() => {
    let subscribed = true
    Hub.listen(UI_AUTH_CHANNEL, (data: HubCapsule) => {
      if (subscribed && data.payload && data.payload.data) {
        if (data.payload.data && data.payload.data.username) {
          setUser(data.payload.data)
        }
      }
    })
    return () => {
      subscribed = false
    }
  }, [])

  useEffect(() => {
    let subscribed = true
    Auth.currentAuthenticatedUser()
      .then((res) => {
        if (subscribed && res && res.username) {
          setUser(res)
        }
      })
      .catch((err) => {
        // console.log('err', err);
      })
    return () => {
      subscribed = false
    }
  }, [])

  const handleReset = (oldPassword: string, newPassword: string) => {
    if (!user) {
      Hub.dispatch(UI_AUTH_CHANNEL, {
        event: AUTH_STATE_CHANGE_EVENT,
        message: AuthState.SignIn,
        data: null,
      })
    }

    setDoingReset(true)

    Auth.changePassword(user, oldPassword, newPassword)
      .then(() => {
        dispatch(
          sendToast({
            title: 'Success',
            messages: ['Password successfully updated!'],
            color: 'positive',
          })
        )
        Auth.signOut({ global: true })
          .then(() => {
            history.push(appRoutes.logout.makeUrl({ redirectTo: '' }))
          })
          .catch((err) => {
            console.log(err)
          })
      })
      .catch(() => {
        dispatch(
          sendToast({
            title: 'Error',
            message: t('auth.changePassword.passwordError'),
            color: 'negative',
          })
        )
      })
      .finally(() => {
        setDoingReset(false)
      })
  }

  const fullValidatorForSchema = (schema: any) => (values: any) =>
    schema
      .validate(values, {
        abortEarly: false,
        strict: false,
      })
      .then(() => setNewPasswordErrors([]))
      .catch((error: any) => {
        setNewPasswordErrors(error.errors)
        setShowPwRules(true)
      })

  const EyeIcon = (name: string) => {
    // @ts-ignore
    return !pwIsVisible[name] ? (
      <ImEyeBlocked
        cursor={'pointer'}
        onClick={() => HandleShowPassword(name)}
      />
    ) : (
      <ImEye cursor={'pointer'} onClick={() => HandleShowPassword(name)} />
    )
  }

  return (
    <>
      <GlobalLoginStyle />
      <AuthContainer
        isFormik
        header={<HeaderStyled>{t('auth.changePassword.title')}</HeaderStyled>}
        form={
          <Formik
            data-testid={'change-password-form'}
            initialValues={initialFormValues}
            validationSchema={validationSchema}
            validate={fullValidatorForSchema(validationSchema)}
            validateOnMount
            onSubmit={(values) => {
              if (values.oldPassword === values.newPassword) {
                dispatch(
                  sendToast({
                    title: 'Error',
                    message: t('auth.changePassword.equalPassword'),
                    color: 'negative',
                  })
                )
              } else {
                handleReset(values.oldPassword, values.newPassword)
              }
            }}
          >
            {({ isValid, values, getFieldProps, errors }) => {
              const passWordsMatch =
                isValid && values.newPassword === values.confirmPassword
              return (
                <Form>
                  <InputWrapperStyled>
                    <InputFieldStyled
                      name={'oldPassword'}
                      type={pwIsVisible.oldPassword ? 'text' : 'password'}
                      label={'Old password'}
                      $hasMargin
                      slotRight={
                        values.oldPassword
                          ? EyeIcon(getFieldProps('oldPassword').name)
                          : null
                      }
                    />
                  </InputWrapperStyled>
                  <InputWrapperStyled>
                    <InputFieldStyled
                      name={'newPassword'}
                      type={pwIsVisible.newPassword ? 'text' : 'password'}
                      label={'New password'}
                      $isMatch={passWordsMatch}
                      $hasMargin
                      slotRight={
                        values.newPassword
                          ? EyeIcon(getFieldProps('newPassword').name)
                          : null
                      }
                    />
                    {errors.newPassword ? (errors.newPassword = '') : null}
                    {newPasswordErrors.length || showPwRules ? (
                      <CustomInputError errors={newPasswordErrors} />
                    ) : null}
                  </InputWrapperStyled>
                  <InputWrapperStyled>
                    <InputFieldStyled
                      name={'confirmPassword'}
                      type={pwIsVisible.confirmPassword ? 'text' : 'password'}
                      label={'Confirm password'}
                      $isMatch={passWordsMatch}
                      $hasMargin
                      slotRight={
                        values.confirmPassword
                          ? EyeIcon(getFieldProps('confirmPassword').name)
                          : null
                      }
                    />
                  </InputWrapperStyled>
                  <Button
                    type={'submit'}
                    style={{ marginTop: '.2rem' }}
                    $variant={'primary'}
                    $loading={doingReset}
                    disabled={!isValid}
                    $fluid
                  >
                    {t('auth.changePassword.ctaSubmit')}
                  </Button>
                  <FooterStyled>
                    <a href={`${process.env.REACT_APP_V1_URL}/app/profile`}>
                      <ButtonGoBackStyled
                        type={'button'}
                        $variant={'link-secondary'}
                      >
                        {t('common.goBack')}
                      </ButtonGoBackStyled>
                    </a>
                  </FooterStyled>
                </Form>
              )
            }}
          </Formik>
        }
      />
    </>
  )
}

export default ChangePassword
