import React, { Dispatch, useEffect, useRef, useState } from 'react'
import { Auth } from 'aws-amplify'
import { Message, Icon } from 'semantic-ui-react'
import PasswordHelper from '../components/PasswordHelper'
import AuthContainer from '@features/auth/components/AuthContainer'
import Typography from '@components/Basic/Typography'
import { getCustomErrorCognitoMessage } from '../../../utils/cognitoErrors'
import { Col, Row } from 'react-grid-system'
import { Button } from '@components/Basic/Button'
import { sendToast } from '../../../store/actions/ApplicationConfigurationActions'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { appRoutes } from '@app/routes'
import { InputField } from '@components/Form/InputField'
import { Trans, useTranslation } from 'react-i18next'

interface FormStatus {
  type: string
  message: string
}

const LostPassword: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch()
  const { t } = useTranslation()
  const [formError, setFormError] = useState<string>('')
  const [formStatus, setFormStatus] = useState<FormStatus | null>()
  const [formEmailStatus, setFormEmailStatus] = useState<FormStatus | null>()
  const [formCodeStatus, setFormCodeStatus] = useState<FormStatus | null>()
  const [doingReset, setDoingReset] = useState<boolean>(false)

  const [email, setEmail] = useState<string>('')
  const [emailValidated, setEmailValidated] = useState<boolean>(false)
  const [emailSent, setEmailSent] = useState<boolean>(false)
  const [resetCode, setResetCode] = useState<boolean>(false)

  const [form2Error, setForm2Error] = useState<string>('')
  const [verificationCode, setVerificationCode] = useState<string>('')
  const [verificationCodeError, setVerificationCodeError] =
    useState<boolean>(false)
  const [newPassword, setNewPassword] = useState<string>('')
  const [newPasswordConfirmation, setNewPasswordConfirmation] =
    useState<string>('')
  const [doingReset2, setDoingReset2] = useState<boolean>(false)
  const [newPasswordIsValid, setNewPasswordIsValid] = useState<boolean>(false)
  const [passwordError, setPasswordError] = useState<string>('')

  const [validLen, setValidLen] = useState<boolean>(false)
  const [validUpperCase, setValidUppercase] = useState<boolean>(false)
  const [validLowercase, setValidLowercase] = useState<boolean>(false)
  const [validDigit, setValidDigit] = useState<boolean>(false)
  const [validSpecial, setValidSpecial] = useState<boolean>(false)
  const [displayPwHelper, setDisplayPwHelper] = useState<boolean>(false)

  const [pwIsVisible, setPwIsVisible] = useState<boolean>(false)
  const [activeIndex, setActiveIndex] = useState<number>(0)

  const history = useHistory()
  const location = useLocation()
  // keep `redirectTo` query string param alive when users goes back to signin page
  const redirectToQueryString = location.search?.includes('redirectTo=')
    ? location.search
    : ''

  useEffect(() => {
    if (newPassword.length > 0) {
      setValidDigit(new RegExp(/\d/).test(newPassword))
      setValidUppercase(new RegExp(/[A-Z]/).test(newPassword))
      setValidLowercase(new RegExp(/[a-z]/).test(newPassword))
      setValidSpecial(new RegExp(/\W/).test(newPassword))
      setValidLen(newPassword.length > 7 && newPassword.length < 31)
    } else {
      setValidDigit(false)
      setValidUppercase(false)
      setValidLowercase(false)
      setValidSpecial(false)
      setValidLen(false)

      setDisplayPwHelper(false)
    }
    if (
      !validDigit ||
      !validUpperCase ||
      !validLowercase ||
      !validSpecial ||
      !validLen
    ) {
      setDisplayPwHelper(true)
    }
  }, [newPassword])

  useEffect(() => {
    setFormStatus(null)
    // handleCheckNewPasswordValid();
    if (newPassword.length !== newPasswordConfirmation.length) {
      setNewPasswordIsValid(false)
    } else {
      setNewPasswordIsValid(true)
    }
  }, [newPassword, newPasswordConfirmation])

  useEffect(() => {
    ;/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,12}$/i.test(email)
      ? setEmailValidated(true)
      : setEmailValidated(false)
  }, [email])

  useEffect(() => {
    setVerificationCodeError(false)
    if (verificationCode.length == 6) {
      setResetCode(true)
    } else if (verificationCode.length > 6) {
      setVerificationCode(verificationCode.slice(0, 6))
    } else {
      setResetCode(false)
    }
  }, [verificationCode])

  const handleCheckNewPasswordValid = () => {
    if (
      !validDigit ||
      !validUpperCase ||
      !validLowercase ||
      !validSpecial ||
      !validLen
    ) {
      setDisplayPwHelper(true)
      // alert('true');
      // console.log('checking');
      return true
    }
    setDisplayPwHelper(false)
    return false
  }
  const abortResetPwdFlow = () => {
    setActiveIndex(0)
    setFormEmailStatus(null)
    setEmailSent(false)
    setVerificationCode('')
    setNewPassword('')
    setNewPasswordConfirmation('')
  }
  const handleValidateCode = (event: any) => {
    const value = event.target.value
    setVerificationCode(value)
    setFormEmailStatus(null)
    if (value.length == 6) {
      setResetCode(true)
      // setDoingReset2(true);
    } else {
      setResetCode(false)
    }
  }
  const handleGetVerificationCode = () => {
    setEmailSent(false)
    if (emailValidated) {
      setDoingReset(true)
      setVerificationCode('')
      Auth.forgotPassword(email)
        .then((res) => {
          // console.log(res);
          setEmailSent(true)
          setFormEmailStatus({
            type: 'success',
            message: 'Code has been sent! Check your inbox.',
          })
        })
        .catch((err) => {
          /* error Mock by Cognito
          {
              "code": "UserNotFoundException",
              "name": "UserNotFoundException",
              "message": "Username/client id combination not found."
          }
         */
          setFormEmailStatus({
            type: 'error',
            message: getCustomErrorCognitoMessage(err),
          })
        })
        .finally(() => {
          setDoingReset(false)
        })
    } else {
      // setFormError('Check the Email');
      setFormEmailStatus({
        type: 'error',
        message: 'Code has been sent! Check your inbox.',
      })
    }
  }

  const confirmReset = () => {
    if (newPassword !== newPasswordConfirmation) {
      setFormStatus({
        type: 'error',
        message: "ERROR: Passwords doesn't match!",
      })
      return
    }
    if (
      !validDigit ||
      !validUpperCase ||
      !validLowercase ||
      !validSpecial ||
      !validLen
    ) {
      // setFormStatus({'type':'error','message':'Passwords do not match!'});
      setNewPasswordIsValid(false)
      setDoingReset2(false)
      return
    }
    setNewPasswordIsValid(true)
    setDoingReset2(true)

    Auth.forgotPasswordSubmit(email, verificationCode, newPassword)
      .then((res) => {
        history.push(appRoutes.signin.makeUrl())
        dispatch(
          sendToast({
            title: 'Success',
            message: 'Password restored',
            color: 'positive',
          })
        )
      })
      .catch((err) => {
        // console.log('err resp',err.message, err);
        // dispatch(sendToast({title: 'Error', message: 'Login falied', color: 'negative'}));
        // setFormStatus({type:'error', message:err.message});
        switch (err.code) {
          case 'CodeMismatchException':
            const verificationCodeBackup = verificationCode
            abortResetPwdFlow()
            dispatch(
              sendToast({
                title: 'Error',
                message: err.message,
                color: 'negative',
              })
            )
            setVerificationCode(verificationCodeBackup)
            setEmailSent(true)
            setVerificationCodeError(true)
            break
          default:
            dispatch(
              sendToast({
                title: 'Error',
                message: err.message,
                color: 'negative',
              })
            )
            abortResetPwdFlow()
            break
        }
      })
      .finally(() => {
        setDoingReset2(false)
      })
  }
  const switchComponent = () => {
    setActiveIndex(1)
    setEmailSent(false)
  }

  const headlineStep = () => {
    switch (activeIndex) {
      case 0:
        // return LangSignIn.buttons.steps.step0;
        return 'Recover your password'
      case 1:
        return 'Recover your password'
    }
  }

  const descriptionStep = () => {
    switch (activeIndex) {
      /* steps: {
                    step0: 'Get the authentication code to be able to reset your password',
                        step1: 'Go next 1',
                }
                */
      case 0:
        return 'Get the verification code to be able to reset your password'
      case 1:
        return 'Create a new password following the validation rules'
    }
  }

  return (
    <>
      <AuthContainer
        header={
          <>
            <Typography className={'custom-class-theme'} tagName='h2'>
              {headlineStep()}
            </Typography>
            <Typography className={'text-secondary'}>
              {descriptionStep()}
            </Typography>
          </>
        }
        form={
          <>
            <>
              {
                // 1. email
                // 1.2 code review + resend link
                // Se codice quello giusto:
                // New password (with strong password check)
                // COnfirm password + Save new passowrd
              }
              {activeIndex === 0 && (
                <>
                  {/* Email + get the code button */}
                  {/* TODO: creare animazione per il "code sent" */}
                  <InputField
                    name={'email'}
                    disabled={!(verificationCode.length < 1)}
                    value={email}
                    type={'email'}
                    placeholder='Your email'
                    label='Insert your account email'
                    $hasMargin
                    onFocus={() => setFormError('')}
                    onChange={(e) => {
                      setEmail(e.target.value)
                      setFormError('')
                    }}
                    $status={{
                      error:
                        (formEmailStatus?.type === 'error' &&
                          formEmailStatus?.message) ||
                        undefined,
                      success:
                        (formEmailStatus?.type === 'success' &&
                          formEmailStatus?.message) ||
                        undefined,
                    }}
                    slotRight={
                      <Button
                        onClick={() => handleGetVerificationCode()}
                        disabled={!emailValidated}
                        $loading={doingReset}
                      >
                        {!emailSent ? 'Get the code' : 'Resend the code'}
                      </Button>
                    }
                  />

                  {/* Code review */}
                  <InputField
                    name={'code'}
                    type={'number'}
                    $fluid
                    $hasMargin
                    label={'Type the six-digit verification code'}
                    $status={{
                      error:
                        (formCodeStatus?.type === 'error' &&
                          formCodeStatus?.message) ||
                        undefined,
                    }}
                    onFocus={() => {
                      setFormCodeStatus(null)
                    }}
                    disabled={!emailSent}
                    placeholder={'000000'}
                    value={verificationCode}
                    onChange={(event) => handleValidateCode(event)}
                  />

                  <Button
                    $variant={'primary'}
                    $fluid
                    disabled={!resetCode}
                    // $loading={!!(verificationCode && emailSent)}
                    // handleReset()
                    onClick={() => switchComponent()}
                  >
                    {t('auth.signin.ctaResetPassword')}
                  </Button>
                </>
              )}

              {activeIndex === 1 && (
                <>
                  <InputField
                    name={'new-password'}
                    type={pwIsVisible ? 'text' : 'password'}
                    label={'New password'}
                    $fluid
                    $hasMargin
                    slotRight={
                      <Icon
                        name={pwIsVisible ? 'eye slash' : 'eye'}
                        link
                        onClick={() =>
                          setPwIsVisible((prevState) => !prevState)
                        }
                      />
                    }
                    $status={{ error: passwordError }}
                    placeholder={'Insert here a new password'}
                    onBlur={() => {
                      handleCheckNewPasswordValid()
                    }}
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    autoComplete='off'
                    onFocus={() => {
                      setPasswordError('')
                      handleCheckNewPasswordValid()
                    }}
                  />
                  <PasswordHelper
                    displayPwHelper={displayPwHelper}
                    validLen={validLen}
                    validUpperCase={validUpperCase}
                    validLowercase={validLowercase}
                    validDigit={validDigit}
                    validSpecial={validSpecial}
                    visible={displayPwHelper}
                  />

                  <InputField
                    name={'confirm-password'}
                    type={pwIsVisible ? 'text' : 'password'}
                    label={'Confirm new password'}
                    $fluid
                    $hasMargin
                    slotRight={
                      <Icon
                        name={pwIsVisible ? 'eye slash' : 'eye'}
                        link
                        onClick={() =>
                          setPwIsVisible((prevState) => !prevState)
                        }
                      />
                    }
                    $status={{ error: passwordError }}
                    autoComplete='off'
                    onFocus={() => {
                      setPasswordError('')
                    }}
                    placeholder={'Confirm the new password'}
                    onBlur={() => {}}
                    value={newPasswordConfirmation}
                    onChange={(e) => setNewPasswordConfirmation(e.target.value)}
                  />

                  <Message
                    style={{ marginTop: '40px' }}
                    success
                    content={'Password updated successfully'}
                  />
                  <Message
                    style={{ marginTop: '40px' }}
                    error
                    content={formError}
                  />
                  {formStatus?.message !== '' && (
                    <Typography className={`input-${formStatus?.type}`}>
                      {formStatus?.message}
                    </Typography>
                  )}

                  <Button
                    $variant={'primary'}
                    $fluid
                    $loading={doingReset2}
                    disabled={!newPasswordIsValid || doingReset2}
                    onClick={() => confirmReset()}
                  >
                    Create new password
                  </Button>

                  {/*
                                <ThemedButton fluid onClick={() => backComponent()} style={{
                                    padding: '0',
                                    height: '36px',
                                    background: '#22A7F0',
                                    fontStyle: 'normal',
                                    fontWeight: 600,
                                    fontSize: '14px',
                                    lineHeight: '21px',
                                    marginTop: '28px',
                                }}>
                                    <Icon name={'arrow left'}/>
                                    Back
                                </ThemedButton>
                                */}
                </>
              )}
            </>

            <Row>
              <Col className={'text-center my-1'}>
                <Typography
                  className={'text-link'}
                  onClick={() => {
                    abortResetPwdFlow()

                    history.push(appRoutes.signin.makeUrl())

                    // resetting url path manually handled in AuthContainer component
                    // and keeping existing query string in url (to handke redirections)
                    history.push(
                      appRoutes.signin.makeUrl() + redirectToQueryString
                    )
                  }}
                >
                  Go back to login page
                </Typography>
                <div
                  style={{
                    marginTop: '2rem',
                    fontSize: '.9rem',
                  }}
                >
                  <Trans
                    i18nKey={'auth.signin.lostPasswordHelp'}
                    components={{ a: <a /> }}
                  />
                </div>
              </Col>
            </Row>
          </>
        }
        links={[]}
      />
    </>
  )
}
export default LostPassword
