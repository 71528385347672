import styled, { css } from 'styled-components'
import { SideLogoProps } from './HeaderSearchComponent'

export const HeaderSearchWrapper = styled.div`
  width: 100%;
`
export interface TopHeaderMiddleProps {
  isReloadingAllBridgeFields: boolean
}
export const TopHeaderMiddle = styled.div<TopHeaderMiddleProps>`
  display: flex;
  align-items: center;
  > span {
    transition: 0.16s all ease-out;
    margin-right: 0.5rem;
    cursor: pointer;
    svg {
      fill: ${({ theme }) => theme.button.primary.backgroundColor};
      ${({ isReloadingAllBridgeFields }) =>
        isReloadingAllBridgeFields
          ? css`
              animation: rotate360 0.68s ease-in-out;
            `
          : null}
    }
    &:hover {
      transition: 0.16s all ease-in;
      svg {
        fill: ${({ theme }) => theme.button.primary.backgroundColorHover};
      }
    }
  }
`

export const TopHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  line-height: 19px;

  h4,
  h5 {
    margin-top: 0;
    margin-bottom: 0;
  }
`

export const SubHeader = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1.25rem;

  button {
    flex: 1;
  }
`

export const InputWrapper = styled.div`
  flex: 3;
  margin: 0 1.5rem 0 0;

  #field {
    margin: 0;
  }

  input {
    height: 1.9rem;
  }
`

export const SideLogoStyled = styled.div<SideLogoProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: ${({ logoPosition }) =>
    logoPosition === 'left' ? 'row' : 'row-reverse'};
  gap: 0.6rem;

  p {
    color: #191919;
  }

  img {
    width: 1.9rem;
  }
`

export const ClearInputButton = styled.div`
  display: flex;
  cursor: pointer;
  padding-right: 0.5rem;
  svg {
    width: 1rem;
    height: 1rem;
  }
`
