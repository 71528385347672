import { Icon } from 'semantic-ui-react'
import { Button } from '@components/Basic/ButtonNbe'
import React from 'react'
import {
  StyledNewFeatureChip,
  StyledBannerWrapper,
  StyledHeading,
  StyledParagraph,
  StepImage,
} from '@features/nbee/StepCompleted/styled'
import { useTranslation } from 'react-i18next'
import { usePostPerformanceBoosterByBridgeId } from '@app/api/postPerformanceBoosterByBridgeId'
import PerformanceBoosterFinishImage from '@assets/images/illustrations/grow.svg'
import FinishImage from '@assets/images/illustrations/nbee-completed.svg'

interface Props {
  existingPerformanceBoosterAvailable?: boolean
  handleCreatePerformanceBoosterAndRedirect?: () => void
  bridgeId: string
  existingPbId?: number
}

export const PerformanceBoosterBanner: React.FC<Props> = ({
  existingPerformanceBoosterAvailable,
  bridgeId,
  existingPbId,
}) => {
  const { t } = useTranslation()
  const vueAppBaseUr = process.env.VUE_APP_BASE_URL
  const {
    mutateAsync: createPerformanceBooster,
    isLoading: isCreatingPerformanceBooster,
    error: errorCreatingPerformanceBooster,
  } = usePostPerformanceBoosterByBridgeId()

  const handleCreatePerformanceBoosterAndRedirect = async () => {
    if (existingPerformanceBoosterAvailable) {
      window.location.href = `${vueAppBaseUr}/pb/${existingPbId}/edit`
    } else {
      await createPerformanceBooster(
        { bridgeId: bridgeId?.toString() || '' },
        {
          onSuccess: (data) => {
            window.location.href = `${vueAppBaseUr}/pb/${data?.data?.performanceBooster?.id}/edit`
          },
        }
      )
    }
  }
  return (
    <StyledBannerWrapper>
      <div style={{ minWidth: '385px' }}>
        <StyledNewFeatureChip>
          <Icon name='star' size={'tiny'} />
          <span
            style={{
              fontSize: '10px',
              fontWeight: 600,
              whiteSpace: 'nowrap',
            }}
          >
            {t('pb.bannerChip') || 'NEW FEATURE'}
          </span>
        </StyledNewFeatureChip>
        <StyledHeading>
          {t('pb.bannerTitle') || 'What’s Next? Boost Your Ad Performance!'}
        </StyledHeading>
        <StyledParagraph>
          {t('pb.bannerP1') ||
            'Enhance your ad performance with Performance Booster in a few simple steps.'}
        </StyledParagraph>
        <StyledParagraph>
          {t('pb.bannerP2') ||
            'LeadsBridge will automatically transfer your lead conversion events to Facebook, providing seamless ad optimization.'}
        </StyledParagraph>

        <Button
          $variant={'primary'}
          style={{}}
          $loading={isCreatingPerformanceBooster}
          onClick={handleCreatePerformanceBoosterAndRedirect}
        >
          {existingPerformanceBoosterAvailable
            ? 'Go to Performance Booster'
            : t('pb.bannerCta') || 'Add Performance Booster'}
        </Button>
      </div>
      <StepImage
        style={{ marginLeft: '16px', width: '282px' }}
        src={PerformanceBoosterFinishImage}
        alt={t('nbee.bridgeBuilder.step3Title')}
      />
    </StyledBannerWrapper>
  )
}
