import React from 'react'
import { Col, Hidden, Row } from 'react-grid-system'
import Typography from '@components/Basic/Typography'
import { Button, Icon } from 'semantic-ui-react'
import styled from 'styled-components'
interface ResourceItemProps {
  type: string
  head: string
  title: string
  text: string
  buttonLink: string
  buttonLabel: string
  icon?: string
  divisor?: boolean
}
export const IconStyled = styled(Icon)`
  margin-right: 0 !important;
`
export const Text = styled(Typography)`
  opacity: 0.6;
`
export const Title = styled(Typography)`
  font-size: 21px !important;
  margin-top: 1.2rem !important;
  a {
    color: white;
    &:hover {
      color: white;
      opacity: 0.8;
    }
  }
`
export const ButtonLink = styled(Button)`
  color: white;
  white-space: nowrap;
  &:hover {
    color: white;
    opacity: 0.8;
    transition: all 0.16s ease;
  }
`
export const Head = styled(Typography)`
  span {
    opacity: 0.4;
  }
`
export const ResourceItemStyled__Icon = styled('div')`
  background-color: rgba(255, 255, 255, 0.2);
  padding: 1rem;
  width: 70px;
  height: 70px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
`
export const ResourceItemStyled = styled('div')`
  color: white;

  &.divisor {
    padding-bottom: 3rem;
    border-bottom: 2px dashed rgb(255 255 255 / 12%);
    margin-bottom: 3rem;
    /* Avoid scroll bar */
    @media screen and (max-height: 695px) {
      margin-bottom: 2rem;
      padding-bottom: 0.5rem;
    }
  }

  /* Avoid scroll bar */
  h6 {
    @media screen and (max-height: 585px) and (max-width: 895px) {
      font-size: smaller;
      letter-spacing: 1.2px;
    }
  }
  h4 {
    font-weight: 400 !important;
    @media screen and (max-height: 695px) {
      font-size: small;
      letter-spacing: normal;
    }
    @media screen and (max-height: 585px) and (max-width: 895px) {
      font-size: smaller;
      line-height: 1.3;
    }
  }
`
const ResourceItem: React.FC<ResourceItemProps> = ({ ...props }) => {
  const head = props.head.split('/')

  const icon = () => {
    switch (props.type) {
      case 'blog':
        return 'bookmark outline'
      case 'whitepaper':
        return 'book'
      default:
        return 'bolt'
    }
  }

  return (
    <ResourceItemStyled className={props.divisor ? 'divisor' : ''}>
      <Row>
        <Hidden xs sm md>
          <Col xs={0} lg={2}>
            <ResourceItemStyled__Icon>
              <IconStyled name={icon()} />
            </ResourceItemStyled__Icon>
          </Col>
        </Hidden>
        <Col xs={12} sm={12} lg={10}>
          <Row align='center' nogutter={true}>
            <Col xs={6} md={7}>
              <Head tagName={'h6'}>
                {`${head[0]} /`}
                <span>{head[1]}</span>
              </Head>
            </Col>
            <Col xs={6} md={5} className={'text-right'}>
              <Button
                href={props.buttonLink}
                target='_blank'
                size='tiny'
                content={props.buttonLabel}
                icon='right arrow'
                labelPosition='right'
                inverted
                basic
              />
            </Col>
          </Row>
          <Title tagName={'h4'}>
            <a target='_blank' href={props.buttonLink} rel='noreferrer'>
              {props.title}
            </a>
          </Title>
        </Col>
      </Row>
    </ResourceItemStyled>
  )
}
export default ResourceItem
