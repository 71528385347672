import React from 'react'
import { InputToggle } from '@components/Form/InputToggle'
import { useField } from 'formik'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-bottom: 1rem;

  p {
    font-size: 0.9rem;
    margin: 0;
  }
`

interface Props {
  onStatusChange?: (newState: boolean) => void
  forcedDisabled?: boolean
}

export const FieldToggleStatus: React.VFC<Props> = ({
  onStatusChange,
  forcedDisabled,
}) => {
  const { t } = useTranslation()
  const [field, meta, helpers] = useField<boolean>(
    'settings.emailReceipt.active'
  )

  return (
    <Wrapper>
      <p>{t('nbee.emailReceipt.labelEnableFeature')}</p>
      <div>
        <InputToggle
          $variant={'primary'}
          $isChecked={field.value}
          onChange={() => {
            const newState = !field.value
            helpers.setValue(newState)
            if (onStatusChange) {
              onStatusChange(newState)
            }
          }}
          disabled={forcedDisabled}
        />
      </div>
    </Wrapper>
  )
}
