import React, { useState } from 'react'
import { AppConfigurationType, BridgeFormValues } from 'Nbee'
import { useFormikContext } from 'formik'
import { ApiAppSetting } from 'BackendApi'
import { InputSmartCreatableSelect } from '@components/Form/InputSmartSelect/Creatable'
import { getSettingFieldError } from '@features/nbee/SimpleBridgeBuilderForm/fields/IntegrationSettings/utils'
import { LabelWithDocTooltip } from '@components/Form/LabelWithDocTooltip'
import { useTriggerSettingsUpdate } from '@features/nbee/SimpleBridgeBuilderForm/fields/IntegrationSettings/useTriggerSettingsUpdate'
import { trackEvent } from '@app/dataTracking'
import { makeNbeeTrackingParams } from '@app/dataTracking/utils'

interface Props {
  isLoading: boolean
  fieldSchema: ApiAppSetting
  type: AppConfigurationType
  index: number
}

export const SettingFieldSmartCreatableSelect: React.FC<Props> = ({
  isLoading,
  type,
  fieldSchema,
  index,
}) => {
  // some formik helpers
  const {
    values: formValues,
    setFieldValue,
    setFieldTouched,
    touched,
    errors,
  } = useFormikContext<BridgeFormValues>()
  const { refetchSettings } = useTriggerSettingsUpdate(type)

  const [internalInputValue, setInternalInputValue] = useState<string[]>([])

  const settingFieldName = `${type}.settings`
  const currentSettingsValues = formValues[type].settings || []

  const fieldId = fieldSchema.id

  const currentFieldValue = currentSettingsValues.find((v) => v.key === fieldId)
  const initialValues = (fieldSchema?.data || []).map((o) => o.text)

  const defaultValue = currentFieldValue?.value as string[]

  const { isTouched, fieldStatus } = getSettingFieldError({
    type,
    touched,
    errors,
    index,
  })

  const updateFieldOnBlur = () => {
    const internalStateIsEmpty = !internalInputValue.length
    const wasEmptyIsStillEmpty = !currentFieldValue && internalStateIsEmpty
    const wasFilledStillHasSameValue =
      (defaultValue || []).join(',') === (internalInputValue || []).join(',')
    const skipUpdate = wasEmptyIsStillEmpty || wasFilledStillHasSameValue
    if (skipUpdate) {
      return
    }

    // if new value is empty, we need to remove the key from formikState
    if (internalStateIsEmpty) {
      // removing from formik state if no value is added
      const newSettings = currentSettingsValues.filter(
        (setting) => setting.key !== fieldId
      )
      setFieldValue(settingFieldName, newSettings)
      return
    }

    const newValue = {
      key: fieldId,
      value: internalInputValue,
    }

    trackEvent({
      eventName: 'IntegrationSettingSelected',
      step: 'Apps',
      feature: 'NBEE',
      params: {
        ...makeNbeeTrackingParams(formValues, type),
        custom: {
          fieldId: newValue.key,
          value: (internalInputValue || []).join(', '),
        },
      },
    })

    const newSettings = currentFieldValue
      ? currentSettingsValues.map((setting) =>
          setting.key === fieldId ? newValue : setting
        )
      : [...currentSettingsValues, newValue]

    setFieldValue(settingFieldName, newSettings)

    // we trigger the retrival of new setting only if is a setting with a child
    if (fieldSchema.hasChild) {
      refetchSettings()
    }
  }

  return (
    <div>
      <LabelWithDocTooltip
        label={fieldSchema.label}
        isRequired={fieldSchema.required}
        tooltip={fieldSchema.tooltip}
        docUrl={fieldSchema.docUrl}
      />
      <InputSmartCreatableSelect
        isLoading={isLoading}
        initialValues={initialValues}
        defaultValue={defaultValue}
        isClearable={false}
        isDisabled={formValues.ui?.isBridgeEnabled}
        onSelect={(newSettings) => {
          // saving internal state to update formik state onBlur
          setInternalInputValue(newSettings)
        }}
        placeholder={'type to add...'}
        onBlur={() => {
          setFieldTouched(`${settingFieldName}.${index}`, true)
          updateFieldOnBlur()
        }}
        $status={(isTouched && fieldStatus) || undefined}
      />
    </div>
  )
}
