import styled, { css } from 'styled-components'
import { LoaderProps } from './index'

const borderColor = ({ $inverted }: LoaderProps) =>
  $inverted ? '#fff' : '#767676'

const paddingTop = ({ $size }: LoaderProps) => {
  switch ($size) {
    case 'small':
      return '2.5rem'
    case 'large':
      return '4.21428571rem'

    case 'x-large':
      return '9rem'

    default:
      return '3.07142857rem'
  }
}

const getSize = ({ $size }: LoaderProps) => {
  switch ($size) {
    case 'small':
      return '1.71428571rem'
    case 'large':
      return '3.42857143rem'
    case 'x-large':
      return '8rem'

    default:
      return '2.28571429rem'
  }
}

const margin = ({ $size }: LoaderProps) => {
  switch ($size) {
    case 'small':
      return '-.85714286rem'
    case 'large':
      return '-1.71428571rem'
    case 'x-large':
      return ' -3.6rem'

    default:
      return '-1.14285714rem'
  }
}

const textSize = ({ $size }: LoaderProps) => {
  switch ($size) {
    case 'small':
      return '.8em'
    case 'large':
      return '.9em'
    case 'x-large':
      return '1.3em'
    default:
      return '1em'
  }
}

export const LoaderDimmer = styled.div<LoaderProps>`
  & {
    display: flex;
    background-color: rgba(255, 255, 255, 0.85);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    padding: 1em;
    animation-duration: 0.5s;
    transition: background-color 0.5s linear;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
`

export const LoaderStyled = styled.div<LoaderProps>`
  & {
    ${({ $active }) =>
      $active
        ? css`
            display: block;
          `
        : css`
            display: none;
          `}
    width: ${getSize};
    height: ${getSize};
    font-size: ${textSize};
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1000;
    transform: translateX(-50%) translateY(-50%);

    ${({ $text }) =>
      $text !== '' &&
      css`
        min-width: ${getSize};
        padding-top: ${paddingTop};
        width: auto !important;
        height: auto !important;
      `}

    &::before {
      position: absolute;
      content: '';
      top: 0;
      left: 50%;
      border-radius: 500rem;
      border: 0.2em solid rgba(0, 0, 0, 0.1);
      width: ${getSize};
      height: ${getSize};
      margin: 0 0 0 ${margin};
    }

    &::after {
      position: absolute;
      content: '';
      top: 0;
      left: 50%;
      animation: loader-spin 0.6s linear;
      animation-iteration-count: infinite;
      border-radius: 500rem;
      border-color: ${borderColor} transparent transparent;
      border-style: solid;
      border-width: 0.2em;
      box-shadow: 0 0 0 1px transparent;
      width: ${getSize};
      height: ${getSize};
      margin: 0 0 0 ${margin};
    }

    @keyframes loader-spin {
      from {
        transform: rotate(0);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }
`
