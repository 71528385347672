import { useGetPerformanceBoosterByBridgeId } from '@app/api/getPerformanceBoosterByBridgeId'
import { useEffect, useState } from 'react'
import { useCheckAppsCompatibility } from '@app/api/checkAppsCompatibility'
import { usePostPerformanceBoosterByBridgeId } from '@app/api/postPerformanceBoosterByBridgeId'

export const handlePerformanceBoosterRedirectUri = ({
  bridgeId,
  sourceId,
  destinationId,
}: {
  bridgeId?: string
  sourceId?: number
  destinationId?: number
}) => {
  const [performanceBoosterAvailable, setPerformanceBoosterAvailable] =
    useState<boolean>(false)
  const [
    existingPerformanceBoosterAvailable,
    setExistingPerformanceBoosterAvailable,
  ] = useState<boolean>(false)

  const {
    data: appCompatibilityCheck,
    isLoading: isCheckingAppsCompatibility,
    error: apiError,
  } = useCheckAppsCompatibility(sourceId, destinationId)

  const {
    data: performanceBooster,
    isLoading: isLoadingPerformanceBooster,
    error: errorGettingPerformanceBooster,
  } = useGetPerformanceBoosterByBridgeId(performanceBoosterAvailable, bridgeId)

  // Run compatibility check and set performance booster availability
  useEffect(() => {
    if (!isCheckingAppsCompatibility && appCompatibilityCheck) {
      const isAvailable =
        appCompatibilityCheck?.combination?.availableBridgeFeatures?.some(
          (feature) =>
            feature.moduleId === 'performance-booster' && feature.available
        )
      setPerformanceBoosterAvailable(isAvailable)
    }
  }, [appCompatibilityCheck])

  // Handle redirection logic
  useEffect(() => {
    if (!isLoadingPerformanceBooster && performanceBoosterAvailable) {
      if (performanceBooster?.data?.performanceBooster?.id) {
        setExistingPerformanceBoosterAvailable(true)
      }
    }
  }, [performanceBooster, isLoadingPerformanceBooster])

  return {
    performanceBoosterAvailable,
    existingPerformanceBoosterAvailable,
    errorGettingPerformanceBooster: apiError,
    existingPbId: performanceBooster?.data?.performanceBooster?.id,
  }
}
