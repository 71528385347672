// used in bbu url
export enum BbuPartnersName {
  facebook = 'fb',
  facebookConversionLeads = 'fb-cl',
  tiktok = 'tt',
  app = 'app',
}

// used in generic bbu callback url
export enum BbuParnersFullName {
  tiktok = 'tiktok',
}
