import React, { useEffect, useState } from 'react'
import { ApiAppSetting } from 'BackendApi'
import {
  AppConfigurationType,
  AppIntegrationSetting,
  BridgeFormValues,
} from 'Nbee'
import { useFormikContext } from 'formik'
import { getSettingFieldError } from '@features/nbee/SimpleBridgeBuilderForm/fields/IntegrationSettings/utils'
import { trackEvent } from '@app/dataTracking'
import { makeNbeeTrackingParams } from '@app/dataTracking/utils'
import { Loader } from '@components/Basic/Loader'
import { LabelWithDocTooltip } from '@components/Form/LabelWithDocTooltip'
import { InputFeedback } from '@components/Basic/InputFeedback'
import { FakeInput } from '@components/Basic/FakeInput'
import { TinyTextualButton } from "@components/Basic/TinyTextualButton";
import { useTranslation } from "react-i18next";

interface GooglePickerProps {
  isLoading: boolean
  fieldSchema: ApiAppSetting
  type: AppConfigurationType
  index: number
  apiHasError: boolean
}

export const GooglePicker: React.VFC<GooglePickerProps> = ({
  isLoading,
  type,
  fieldSchema,
  index,
  apiHasError,
}) => {
  const {
    values: formValues,
    setFieldValue,
    setFieldTouched,
    errors,
    touched,
  } = useFormikContext<BridgeFormValues>()

  const FILE_ID_PREFIX: string = 'L___'
  const { t } = useTranslation()

  const [accessToken, setAccessToken] = useState<string>('')
  const [fileName, setFileName] = useState<string>('')
  const [fileId, setFileId] = useState<string>('')
  const [fieldId, setFieldId] = useState<string>('')
  const [internalError, setInternalError] = useState<string | null>(null)

  const settingFieldName = `${type}.settings`
  const isBridgeEnabled = formValues.ui?.isBridgeEnabled

  const { isTouched, fieldStatus } = getSettingFieldError({
    type,
    touched,
    errors,
    index,
  })

  const updateFormSettings = (newValue: AppIntegrationSetting) => {
    setFieldValue(`${settingFieldName}.${index}`, newValue)
  }

  const injectGoogleScript = () => {
    if (window.gapi) {
      return
    }

    const script = document.createElement('script')
    script.src = 'https://apis.google.com/js/api.js'
    script.async = true
    script.onload = () => {
      window.gapi.load('client:picker', async () => {
        await gapi.client.load(
          'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'
        )
      })
    }
    script.id = 'google-api-script'
    document.body.appendChild(script)
  }

  const pickerCallback = async (data: any) => {
    if (data.action === google.picker.Action.CANCEL) {
      // nothing to do
    } else if (data.action === google.picker.Action.PICKED) {
      setFileId(FILE_ID_PREFIX + data.docs[0].id)
      setFileName(data.docs[0].name)
      const newValue: AppIntegrationSetting = {
        key: fieldId,
        value: FILE_ID_PREFIX + data.docs[0].id,
      }
      updateFormSettings(newValue)
      trackEvent({
        eventName: 'IntegrationSettingSelected',
        step: 'Apps',
        feature: 'NBEE',
        params: {
          ...makeNbeeTrackingParams(formValues, type),
          custom: {
            fieldId: fieldId,
            value: newValue.value + '',
          },
        },
      })
    }
  }

  const checkAccessToken = async (): Promise<boolean> => {
    try {
      const res = await gapi.client.drive.about.get({
        fields: '*',
        access_token: accessToken,
      })
      return true
    } catch (error) {
      return false
    }
  }

  const getMetaFileType = (
    fileType: string
  ): { viewId: google.picker.ViewId; mimeType: string } => {
    switch (fileType) {
      case google.picker.ViewId.FORMS.toString():
        return {
          viewId: google.picker.ViewId.FORMS,
          mimeType: 'application/vnd.google-apps.form',
        }
      default:
        return {
          viewId: google.picker.ViewId.SPREADSHEETS,
          mimeType: 'application/vnd.google-apps.spreadsheet',
        }
    }
  }

  const openPicker = async () => {
    const tokenValid: boolean = await checkAccessToken()
    if (!tokenValid) {
      const error: string = t('integrationAuthPopup.integrationUpdate')
      setInternalError(error)
      return
    }
    setInternalError(null)
    const metaFileType: { viewId: google.picker.ViewId; mimeType: string } =
      getMetaFileType(fieldSchema.format || 'spreadsheets')
    const view = new google.picker.DocsView(metaFileType.viewId)
    view.setMimeTypes(metaFileType.mimeType)
    view.setMode(google.picker.DocsViewMode.LIST)
    const picker = new google.picker.PickerBuilder()
      .enableFeature(google.picker.Feature.NAV_HIDDEN)
      .setDeveloperKey(process.env.REACT_GOOGLE_PICKER_API_KEY || '')
      .setAppId(process.env.REACT_GOOGLE_API_APP_ID || '')
      .setOAuthToken(accessToken)
      .addView(view)
      .addView(new google.picker.DocsUploadView())
      .setCallback(pickerCallback)
      .build()
    picker.setVisible(true)
  }

  const initData = (
    data: { id: number | string; text: string }[] | undefined
  ) => {
    if (data && data.length > 0) {
      for (const values of data) {
        if (values.id === 'accessToken') {
          setAccessToken(values.text)
        } else {
          setFileName(
            values.text === ''
              ? t('nbee.bridgeBuilder.chooseFileLong')
              : values.text
          )
          setFileId(values.id + '')
        }
      }
    }
  }

  useEffect(() => {
    injectGoogleScript()
    initData(fieldSchema.data)
    setFieldId(fieldSchema.id)
  }, [fieldSchema])

  const isRefreshingFromApi = Boolean(
    formValues.ui && formValues.ui[type]?.refresh?.includes(fieldSchema.id)
  )

  return (
    <div style={{ position: 'relative' }}>
      {/*  we show the loader when is hard refreshing from api, but if we received an error we can't show the loading status or user will be stuck  */}
      {isRefreshingFromApi && !apiHasError && <Loader $active $dimmer />}
      <LabelWithDocTooltip
        label={fieldSchema.label}
        isRequired={fieldSchema.required}
        tooltip={fieldSchema.tooltip}
        docUrl={fieldSchema.docUrl}
      />
      {isLoading && <Loader $active $dimmer $size='small' />}
      <FakeInput
        text={fileName}
        onClick={!isBridgeEnabled ? openPicker : undefined}
        style={{
          backgroundColor: isBridgeEnabled ? undefined : '#fff',
          border: '1px solid hsl(0, 0%, 80%)',
          cursor: 'pointer',
        }}
        onBlur={() => {
          setFieldTouched(`${settingFieldName}.${index}`, true)
        }}
      />
      {!isBridgeEnabled ? (
        <div style={{ textAlign: 'right' }}>
          <TinyTextualButton
            onClick={openPicker}
            disabled={isLoading}
          >
            {t('nbee.bridgeBuilder.chooseFile')}
          </TinyTextualButton>
        </div>
      ) : null}
      {!isBridgeEnabled ? (
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr auto',
            gap: '1rem',
          }}
        >
          {isTouched && fieldStatus ? (
            <InputFeedback $status={fieldStatus} />
          ) : internalError ? (
            <InputFeedback $status={{ error: internalError }} />
          ) : (
            <div />
          )}
        </div>
      ) : null}
    </div>
  )
}
