import AuthService from '@app/services/AuthService'
import { ApiUserCredentials } from 'BackendApi'
import { signInUserSessionCognito } from '@app/services/apiAuthClient/utils'

interface HandleSignupWithEmailSuccessProps {
  credentials?: ApiUserCredentials
  onError: (message: string) => void
}

export const handleSignupWithEmailSuccess = async ({
  credentials,
  onError,
}: HandleSignupWithEmailSuccessProps) => {
  if (
    !credentials ||
    !credentials.username ||
    !credentials.idToken ||
    !credentials.accessToken ||
    !credentials.refreshToken
  ) {
    onError('Received invalid credentials')
    return
  }

  signInUserSessionCognito({
    username: credentials.username,
    idToken: credentials.idToken,
    accessToken: credentials.accessToken,
    refreshToken: credentials.refreshToken,
  })

  try {
    await AuthService.setSessionCookie()
  } catch (error) {
    onError(error instanceof Error ? error.message : 'Unknown error')
  }
}
