export enum AppStoreStatus {
  NONE = 'None', // mai arrivato nello stepper
  PLATFORMAUTHENTICATION = 'PlatformAuthentication', // arrivato nello step di autenticazione piattaforma ma non autenticata
  PLATFORMAUTHENTICATED = 'PlatformAuthenticated', // autenticata piattaforma
  SPREADSHEETSELECTION = 'SpreadSheetSelection', // arrivato nella selezione del Google sheet
  SPREADSHEETSELECTED = 'SpreadSheetSelected', // selezionato il Google Sheet/Tab
  PLATFORMCONFIGURATION = 'PlatformConfiguration', // arrivato nella scelta della configurazione Bridge
  PLATFORMCONFIGURED = 'PlatformConfigured', // configurato il Bridge
  PUBLISH = 'Publish', // ultima schermata,
  STATUS_SEGMENTATION_SELECTION = 'SegmentationSelection',
  STATUS_SEGMENTATION_SELECTED = 'SegmentationSelected',
}
