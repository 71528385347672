import React, { useContext, useEffect } from 'react'
import { Button } from '@components/Basic/ButtonNbe'
import { useTranslation } from 'react-i18next'
import { usePostTestLead } from '@app/api/postTestLead'
import { useFormikContext } from 'formik'
import { BridgeFormValues } from 'Nbee'
import { InputFeedback } from '@components/Basic/InputFeedback'
import { parseApiError } from '@app/api/utils/error'
import { PanelPopupContext } from '@components/Panel'
import { trackEvent } from '@app/dataTracking'

type SendTestLeadFeedback = {
  status: 'success' | 'error'
  debugUri?: string
  errorMessage?: string
}

interface Props {
  bridgeId: number
  onSendTestLead: ({
    status,
    debugUri,
    errorMessage,
  }: SendTestLeadFeedback) => void
}

export const ButtonSendTestLead: React.VFC<Props> = ({
  bridgeId,
  onSendTestLead,
}) => {
  const { t } = useTranslation()
  const { values, setSubmitting } = useFormikContext<BridgeFormValues>()
  const fieldMappingTestingValues = values.ui?.fieldMappingTesting || []
  const panelContext = useContext(PanelPopupContext)

  // test lead can be submitted only when at least one field has a value
  const canSubmit =
    !!fieldMappingTestingValues.length &&
    fieldMappingTestingValues.some((field) => field.value)

  const {
    mutate: sendTestLead,
    isLoading: isSendingTestLead,
    error: sendTestLeadApiError,
    data: sendTestResult,
  } = usePostTestLead()

  const debugUri = sendTestResult?.data?.debugUri

  const submitTestLead = () => {
    if (!canSubmit) {
      return
    }

    const testLead = fieldMappingTestingValues
      .filter((field) => {
        return field.value && field.value.trim() !== ''
      }) // filter out fields with empty values from the array
      .map((field) => ({
        fieldId: field.id,
        lastValue: field.value,
      }))

    sendTestLead({
      bridgeId: bridgeId,
      requestBody: {
        testLead,
        debug: true,
      },
    })
  }

  useEffect(() => {
    setSubmitting(false)
    // api can returns 200 with `result: false` if test lead was not successful
    const returnIsError = sendTestResult && sendTestResult.data.result === false
    const hasDebugError =
      sendTestResult && sendTestResult.data.debugError !== ''
    const debugErrorMsg = hasDebugError ? sendTestResult?.data.debugError : ''

    if (returnIsError) {
      onSendTestLead({
        status: 'error',
        debugUri,
        errorMessage: debugErrorMsg,
      })
      return
    }

    if (sendTestResult?.data.result) {
      trackEvent({
        eventName: 'TestSuccess',
        step: 'Test',
        feature: 'NBEE',
      })
      onSendTestLead({ status: 'success', debugUri })
    }
  }, [sendTestResult])

  // opening up a popup to tell user about auto-mapping (only first time)
  useEffect(() => {
    if (!panelContext.sendPopup) {
      return
    }

    panelContext.sendPopup(undefined)
    if (panelContext?.sendPopup && sendTestLeadApiError) {
      trackEvent({
        eventName: 'TestFailure',
        step: 'Test',
        feature: 'NBEE',
        sendEventToIntercom: true,
      })
      panelContext.sendPopup({
        content: (
          <div>
            <InputFeedback
              $status={{
                error: parseApiError(sendTestLeadApiError).message,
              }}
            />
          </div>
        ),
        dismissable: true,
        position: 'floating',
      })
    }
  }, [sendTestLeadApiError])

  return (
    <div>
      <Button
        type={'button'}
        $variant={'primary'}
        disabled={!canSubmit || isSendingTestLead}
        $loading={isSendingTestLead}
        onClick={submitTestLead}
      >
        {t('nbee.fieldsMapping.runTest')}
      </Button>
    </div>
  )
}
