import { Icon } from 'semantic-ui-react'
import React from 'react'
import {
  StyledPercentage,
  StyledTipHeading,
  StyledTipParagraph,
  StyledTipWrapper,
} from '@features/nbee/StepCompleted/styled'
import { useTranslation } from 'react-i18next'

export const PerformanceBoosterTip: React.FC = () => {
  const { t } = useTranslation()
  return (
    <StyledTipWrapper>
      <StyledTipHeading>
        <Icon
          name='lightbulb'
          style={{
            color: '#41A7DC',
            fontSize: '12px',
            margin: 0,
            position: 'relative',
            top: '-4px',
            padding: 0,
            display: 'block',
          }}
        />
        <div style={{ fontWeight: 600, lineHeight: '12px' }}>
          {t('pb.tipTitle') || 'Tip'}
        </div>
      </StyledTipHeading>
      <StyledTipParagraph
        style={{
          marginTop: '12px',
        }}
      >
        {t('pb.tipP1') || 'Add performance booster to'}
      </StyledTipParagraph>

      <StyledTipParagraph>
        {t('pb.tipP2') || 'your bridge to see an average'}
      </StyledTipParagraph>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <StyledPercentage>{t('pb.tipPercentage') || '44%'}</StyledPercentage>
        <StyledTipParagraph
          style={{
            marginTop: '6px',
            fontWeight: 600,
          }}
        >
          {t('pb.tipIncrease') || 'increase in conversion rate'}
        </StyledTipParagraph>
      </div>
    </StyledTipWrapper>
  )
}
