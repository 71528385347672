import styled, { css } from 'styled-components'
import { Props } from './index'

export const Header = styled.header`
  width: 100%;
  min-height: 35px;
  padding: 0.5rem 2rem;
  margin-bottom: 2rem;
  background-color: #3fb1f3;
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 10;
`

export const TableHeader = styled.div<Pick<Props, 'isHeaderStuck'>>`
  padding: 0.8rem 0.1rem;
  font-size: 0.8rem;
  color: #191919;
  position: sticky;
  top: 35px;
  width: 100vw;
  background-color: #fff;
  z-index: 10;

  ${({ isHeaderStuck }) =>
    isHeaderStuck
      ? css`
          box-shadow: 0px 1px 10px 0px rgba(25, 25, 25, 0.1);
        `
      : null}
`

export const ElementWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr 3fr;
  max-width: 600px;
  margin: 0 auto;

  div {
    padding-left: 0.75rem;
    font-weight: 600;
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 1rem;
`

export const Body = styled.div`
  margin-top: 4rem;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .custom-tooltip {
    width: 25rem;
    box-shadow: 0px 4px 10px 0px #808080;
    border-radius: 5px;
  }
`

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0.9rem;

  h2 {
    margin-top: 1.5rem;
    font-size: 1.3rem;
  }
`

export const BodyInner = styled.div`
  width: 100%;
  padding-bottom: 80px;
`

export const WarningMessage = styled.div`
  margin-top: 2rem;
  color: #cc2029;
  font-weight: 600;
`

export const UserFeedback = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;

  p {
    margin: 0 1rem 0 0;
  }

  svg {
    margin-right: 1rem;
    cursor: pointer;
  }
`

export const Paragraph = styled.p`
  font-size: 0.8rem;
  color: #363636;
  margin-bottom: 1.5rem;
  padding: 0 1rem;
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;
  button {
    width: 25%;
  }
`

export const FooterWrapper = styled.div`
  min-height: 60px;
  padding: 1.5rem 0;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(255 255 255 / 60%);
  backdrop-filter: blur(5px);
  z-index: ${({ theme }) => theme.zIndex.panelFooter};
`
