import React, { useEffect, useState } from 'react'
import { Button } from '@components/Basic/ButtonNbe'
import { trackEvent } from '@app/dataTracking'
import { makeNbeeTrackingParams } from '@app/dataTracking/utils'
import { useTranslation } from 'react-i18next'
import { makeSupportUrl } from '@features/nbee/SimpleBridgeBuilderForm/appsCompatibility/utils'
import { useFormikContext } from 'formik'
import { BridgeFormValues } from 'Nbee'
import all from 'public/locales/en-US.json'
import { Translate } from '@components/Translate'
import { InputFeedback } from '@components/Basic/InputFeedback'

interface Props {
  appsInWaitingList: string
  appsCount: number
  appId: number
  isInStep1: boolean
}

export const WaitingListInfoMessage: React.FC<Props> = ({
  appsInWaitingList,
  appsCount,
  appId,
  isInStep1,
}) => {
  const { t } = useTranslation()
  const { values } = useFormikContext<BridgeFormValues>()
  const [updateRequest, setUpdateRequest] = useState(false)

  // we need to force a custom type cast since we are checking dynamic `customMessageKey` existance
  const customMessageKey = (
    all.nbee.checkCompatibility as Record<string, string>
  )[`waitingListTextCustomMessage${appId}`] as string | undefined

  useEffect(() => {
    trackEvent({
      eventName: 'WaitingListSelected',
      feature: 'NBEE',
      step: isInStep1 ? 'Apps' : 'BridgeChooser',
      params: {
        ...makeNbeeTrackingParams(values),
      },
    })
  }, [appId])

  return customMessageKey ? (
    <div>
      <Translate i18nKey={customMessageKey} />
      <a
        href={makeSupportUrl(appsInWaitingList)}
        target={'_blank'}
        rel={'noopener noreferrer'}
        style={{ display: 'block', marginTop: '1rem' }}
      >
        <Button type={'button'} $variant={'action'}>
          {t('nbee.checkCompatibility.waitingListCta')}
        </Button>
      </a>
    </div>
  ) : (
    <div>
      {t('nbee.checkCompatibility.waitingListText', {
        appsInWaitingList,
        count: appsCount,
      })}
      <div
        style={{
          display: 'flex',
          marginTop: '1rem',
          alignItems: 'center',
          gap: '1rem',
        }}
      >
        <Button
          type={'button'}
          $variant={'primary'}
          disabled={updateRequest}
          onClick={() => {
            setUpdateRequest(true)
            trackEvent({
              eventName: 'InterestedInWaitingList',
              feature: 'NBEE',
              step: isInStep1 ? 'Apps' : 'BridgeChooser',
              params: {
                ...makeNbeeTrackingParams(values),
              },
            })
          }}
        >
          {t('nbee.checkCompatibility.waitingListTextSubscribeCta')}
        </Button>

        {updateRequest && (
          <InputFeedback
            $status={{
              success: t(
                'nbee.checkCompatibility.waitingListTextSubscribeResponse'
              ),
            }}
          />
        )}
      </div>
    </div>
  )
}
