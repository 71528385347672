import styled, { css } from 'styled-components'

export const AlertStyled = styled.div<{ isVisible: boolean }>`
  position: fixed;
  bottom: 0;
  background-color: white;
  width: 100%;

  border: 1px solid rgb(29 29 29 / 10%);
  box-shadow: rgb(0 0 0 / 2%) 0px -4px 6px -1px,
    rgb(0 0 0 / 4%) 0px -2px 4px -1px;
  transition: 0.16s all cubic-bezier(0.17, 0.67, 0.83, 0.67);
  z-index: ${({ theme }) => theme.zIndex.overallNotifications};
  transform: translateY(0);
  ${({ isVisible }) =>
    isVisible
      ? css`
          opacity: 1;
        `
      : css`
          opacity: 0;
          transform: translateY(100%);
        `}
`

export const AlertProgressBarWrapper = styled.div<{ progress?: number }>`
  width: 100%;
  height: 5px;
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  ${({ progress }) =>
    progress
      ? css`
          background-color: ${({ theme }) =>
            theme.button.primary.backgroundColor};
          animation: linear fillProgressBar;
          animation-duration: ${progress + 'ms'};
        `
      : null}
`
export const AlertProgressBarStyled = styled.div`
  width: 100%;
  height: 5px;
  position: relative;
  background-color: whitesmoke;
`

export const AlertWrapper = styled.div`
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem 2em;
`

export const AlertHeader = styled.div``
export const AlertButtonClose = styled.div`
  background-color: #fff;
  padding: 0;
  border: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 0.8rem;

  svg {
    width: 1.2rem;
    height: 1.2rem;
    margin-right: 0.2rem;
  }

  &:hover {
    color: ${({ theme }) => theme.color.link};
  }
`

export const AlertBody = styled.div`
  display: flex;
  gap: 10px;
  align-items: flex-start;
  justify-content: space-between;
`
export const AlertBodyContent = styled.div`
  font-weight: 600;
`
export const AlertImageWrapper = styled.div`
  img {
    width: 100px;
  }
`

export const AlertFooter = styled.div`
  width: 100%;
  margin-bottom: 1rem;
  display: flex;
  gap: 5px;
`
