import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const Wrapper = styled.div``

const ErrorMessage = styled.div`
  color: #db3c3c;
  font-weight: 600;
`

export const PopupContentNoSelectOptions: React.VFC = () => {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <ErrorMessage>{t('nbee.fieldsMapping.noOptionsError')}</ErrorMessage>
    </Wrapper>
  )
}
