import React from 'react'
import { StepBarItemStyled } from './styled'
import { StepBarStep } from '@components/StepBar/index'

export interface StepBarItemProps extends StepBarStep {
  index: number
  isActive?: boolean
}
export const StepBarItem: React.FC<StepBarItemProps> = ({
  index,
  onStepClick,
  label,
  title,
  subLabel,
  isActive,
}) => {
  const onClickHandler = () => {
    if (onStepClick) {
      onStepClick(index)
    }
  }

  return (
    <StepBarItemStyled className={isActive ? 'active' : undefined}>
      <div
        className={`step-bar-circle ${onStepClick ? ' is-clickable' : ''}`}
        onClick={onClickHandler}
      >
        <span title={title}>{index + 1}</span>
      </div>
      {isActive && (
        <span onClick={onClickHandler} className={`step-bar-label`}>
          {label}
        </span>
      )}
      <div className='step-bar-optional'>{subLabel}</div>
      <div className='step-bar-left' />
      <div className='step-bar-right' />
    </StepBarItemStyled>
  )
}
